import React , { useState } from 'react';
import { useMediaQuery, Typography } from '@mui/material';

import Footer from '../src/Bottombar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TransactionDetails from './TransactionDetails';
import TopBar from './TobBar';
import NotificationDropdown from "../src/Notification Area/NotificationPage"; // Import the NotificationDropdown component
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink
import WalletPopup from '../src/Wallet Screen/WalletPopup';
const TransactionFiles = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [walletPopupOpen, setWalletPopupOpen] = useState(false);

  const handleOpenWalletPopup = () => {
    setWalletPopupOpen(true);
  };

  const handleCloseWalletPopup = () => {
    setWalletPopupOpen(false);
  };
    return (
        <div>
        <TopBar 
        onWalletClick={handleOpenWalletPopup}
        hideIcons={isSmallScreen} />
        <WalletPopup open={walletPopupOpen} onClose={handleCloseWalletPopup}/>
        <TransactionDetails />
        {!isSmallScreen && <Footer />}
        {isSmallScreen && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              position: "fixed",
              bottom: 0,
              width: "100%",
              bgcolor: "#002855",
              padding: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: 3,
              }}
            >
              <IconButton color="inherit" >
                <AccountBalanceWalletIcon sx={{ color: "white" }} />
              </IconButton>
              <Typography variant="body2" sx={{ color: "white", marginTop: 0.1 }}>
                Wallet
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: 3,
              }}
            >
              <IconButton
                color="inherit"
                component={RouterLink}
                to="/TransactionFile"
              >
                <CompareArrowsIcon sx={{ color: "white" }} />
              </IconButton>
              <Typography variant="body2" sx={{ color: "white", marginTop: 0.1 }}>
                Transactions
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton color="inherit" onClick={handleOpen}>
                <NotificationsIcon sx={{ color: "white" }} />
              </IconButton>
              <Typography variant="body2" sx={{ color: "white", marginTop: 0.1 }}>
                Notifications
              </Typography>
              {/* Include the NotificationDropdown component */}
              <NotificationDropdown
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
            </Box>
          </Box>
        )}
      </div>
    );
};

export default TransactionFiles;
