import React, { useState, useEffect } from "react";
import {
  Avatar,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import AccountInfo from "./AccountInfo";
import BecomeMemeber from "./BecomeMember"; // Assuming you have a BecomeMember component

const UserProfile = () => {
  const [currentView, setCurrentView] = useState("accountInfo");

  const handleTextClick = (view) => {
    setCurrentView(view);
  };



  const accountInfoStyle = {
    ml: {xs:1, sm:5},
    mt: {xs:4, sm:5},
    mr: 8,
    fontSize:{xs:"1rem", sm:"1.2rem"},
    cursor: "pointer",
    backgroundColor: currentView === "accountInfo" ? "#f0f0f0" : "transparent",
    position: "relative",
    padding: {xs:"12px", sm:"20px"},
    width:{xs:"90px"},
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      height: "100%",
      width: "2px",
      backgroundColor: currentView === "accountInfo" ? "#ffa500" : "transparent",
      transform: "translateY(-50%)",
    },
  };

  const becomeMerchantStyle = {
    ml: {xs:1, sm:5},
    mt: {xs:4, sm:2},
    mr: {xs: 1, sm:8},
    fontSize:{xs:"1rem", sm:"1.2rem"},

    cursor: "pointer",
    backgroundColor: currentView === "becomeMerchant" ? "#e0e0e0" : "transparent", // Different background color
    position: "relative",
    padding: {xs:"12px", sm:"20px"},

    width:{xs:"160px"},
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      height: "100%",
      width: "2px",
      backgroundColor: currentView === "becomeMerchant" ? "#ffa500" : "transparent", // Different line color
      transform: "translateY(-50%)",
    },
  };

  return (
    <Box sx={{ padding: "24px" }}>
      <ToastContainer />

      <Grid container spacing={3}>
        <Grid>
          <Box
          sx={{
            display: {xs:"flex", sm:"block"},
            flexDirection: { xs: "row", sm: "column" }, // Flex row for small screens
            gap: {xs:1},
          }}
          >
          <Typography
            variant="h5"
            gutterBottom
            sx={accountInfoStyle}
            onClick={() => handleTextClick("accountInfo")}
          >
            Account Info
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={becomeMerchantStyle}
            onClick={() => handleTextClick("becomeMerchant")}
          >
            Become A Merchant
          </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={9}>
          {/* Conditional Rendering based on currentView */}
          {currentView === "accountInfo" && <AccountInfo />}
          {currentView === "becomeMerchant" && <BecomeMemeber />} {/* Replace with your actual component */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfile;
