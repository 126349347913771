
import LoginForm from "./LoginForm";
import { CssBaseline, Box, Container } from '@mui/material';

const LogIn=()=>{
    return (
        <>
        <CssBaseline />
        <Box
          sx={{
            minHeight: '100vh', 
            display: 'flex', 
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
        >
          
          <Container 
            maxWidth="sm" 
            sx={{
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <LoginForm />
          </Container>
         
        </Box>
      </>
    )
}

export default LogIn;