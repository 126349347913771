import React from "react";
// import Footer from "../Home/Footer";

import {
  Box,
  Typography,
  Button,
  Container,
  Stack,
  Grid,
  Card,
  CardMedia,
  Link,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
} from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import javaLogo from "../images/java.png";
import pythonLogo from "../images/python.png";
import nodeLogo from "../images/node-js.png";
import shield from "../images/developer3.png";
import whatsapp from "../images/whatsapp.jpg"



const cards = [
  {
    logo: pythonLogo,
    title: "Python",
    description:
      "Python has been used to interact with blockchain technologies through libraries like Web3.py, enabling the implementation of smart contracts for automated and secure transaction processes within the escrow API.",
  },
  {
    logo: nodeLogo,
    title: "NodeJS",
    description:
      "The asynchronous nature of Node.js enables flexible payment options, accommodating various transaction methods and ensuring user-centric experiences in managing escrow payments.",
  },
  {
    logo: javaLogo,
    title: "Java",
    description:
      " By utilizing Java’s extensive libraries, Moconnect can efficiently manage wallet transactions, facilitating P2P trading (USDT) and ensuring users can easily transfer and manage their funds.",
  },
];

// Pricing Table Data
function createData(product, usage, cost) {
  return { product, usage, cost };
}

const rows = [
  createData("Python", 1000, "Price"),
  createData("NodeJS", 500, "Price"),
  createData("Java", "Unlimited", "Price"),
];

export default function Developer() {
  return (
    <>
      <main>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ mb: 6, mt: 9 }}
        >
          Developers Tools
        </Typography>
        <Box
          sx={{
            backgroundColor: "#002855",
            color: "white",
            ml: 14, 
            mr: 13,
           
            mt: 10,
            px: { xs: 4, sm: 9, md: 14 },
            py: { xs: 3, sm: 14 },
            "@media (max-width: 600px)": {
              ml: 3,  // Reduced margin-left for small screens
              mr: 3,  // Reduced margin-right for small screens
            },
          }}
        >
          <Container
            
          >
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} alignContent="center">
                <div>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: { xs: 30, sm: 40 },
                      // fontWeight: 700,
                      lineHeight: 78 / 70,
                      letterSpacing: -0.2,
                      color: "white",
                      pb: 2,
                      
                    }}
                  >
                    Take your business to the next level with our escrow API
                  </Typography>
                  <Typography
                    sx={{
                      opacity: 0.8,
                      pb: 2,
                      fontSize: 12,
                    }}
                  >
                   By incorporating Moconnect Pay escrow services, developers can offer their 
                   users a trustworthy transaction environment, which is essential for 
                   attracting and retaining customers, especially in a competitive market.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(255, 165, 0, 0.8)",
                      width:"200px",
                      color: "white",
                      fontWeight: 700,
                      textTransform: "none",
                      boxShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
                      "&:hover": {
                        backgroundColor: "#FFA500",
                        transform: "translateX(2px)",
                        transition: "0.2s",
                      },
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    GET API
                  </Button>
                </div>
              </Grid>
              <Grid
  item
  xs={12}
  md={6}
  sx={{
    display: { xs: "block", sm: "flex" },  // Display block on small screens, flex on larger
    width: "80%",
    justifyContent: "center",  // Center the image on small screens
    m: 0, // Remove margin
    p: 0, // Remove padding
  }}
>
  <Box
    sx={{
      m: "auto",
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${shield})`,
      "@media (max-width: 600px)": {
        width: { xs: 200, sm: 520 },  // Smaller image size on small screens
        height: { xs: 200, sm: 520 },
      },
      overflow: "hidden", // Hide overflow
      border: "none", // Remove border if any
    }}
  ></Box>
</Grid>

            </Grid>
          </Container>
        </Box>
        {/*Hero Section
                <Box sx={{
                    backgroundColor: '#002855',
                }}>
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt: { xs: 14, sm: 20 },
                        pb: { xs: 8, sm: 12 },
                    }}>
                        <Stack spacing={2} sx={{ width: { xs: '100%', sm: '80%' } }}>
                            <Typography
                                variant="h1"
                                sx={{
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    fontSize: { xs: 30, sm: 40, md: 60 },
                                    fontWeight: 600,
                                    lineHeight: 78 / 70,
                                    letterSpacing: -0.2,
                                    color: "white"
                                }}>
                                Take your business to the next level with our escrow API
                            </Typography>
                            <Typography
                                textAlign='center'
                                color='white'
                                sx={{
                                    alignSelf: 'center',
                                    opacity: 0.8,
                                    pb: 2,
                                    fontSize: { xs: 12, sm: 14 }
                                }}
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </Typography>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FFA500',
                                    color: 'black',
                                    textTransform: 'capitalize',
                                    // fontWeight: 'bold',
                                    alignSelf: 'center',
                                    lineHeight: 78 / 70,
                                    letterSpacing: -0.2,
                                    "&:hover": {
                                        backgroundColor: "#ffff",
                                        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
                                        transform: 'translateX(2px)',
                                        transition: '0.2s'
                                    }
                                }}
                                endIcon={<ArrowForwardIcon />}
                            >
                                get started
                            </Button>
                        </Stack>
                    </Container>
                </Box>
*/}
        {/* API Reference Cards*/}
        <Container
          sx={{
            pt: { xs: 4, sm: 12, md: 4 },
            pb: { xs: 8, sm: 16, md: 6 },
            position: "relative",
            display: "flex",
            flexDirection: "column",
            // alignItems: 'center',
            gap: { xs: 3, sm: 4 },
          }}
        >
          <Box>
            <Typography
              component="h2"
              variant="h4"
              sx={{
                fontSize: 36,
                fontWeight: "bold",
                lineHeight: 1.5,
                ml: 1,
                "@media (max-width: 600px)": {
                  ml:1,
                  fontSize: 26
      }
              }}
            >
              Start building today with Moconnect Escrow API
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 400,
                fontSize: 14,
                ml: 1
              }}
            >
             Unlock enhanced user experiences by leveraging automated workflows, 
             flexible payment options, and a focus on business-to-business (B2B) 
             interactions. 
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {cards.map((cardItem, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    cursor: "pointer",
                    bgcolor: "#002855",
                    boxShadow: 2,
                    "&:hover": {
                      bgcolor: "#ffff",
                      color: "black",
                    },
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    p: 4,
                    color: "white",
                  }}
                >
                  <CardMedia>
                    <img
                      width="50"
                      height="50"
                      src={cardItem.logo}
                      alt="Logo"
                    />
                  </CardMedia>
                  <Stack spacing={2}>
                    <Typography
                      component="h2"
                      variant="h5"
                      sx={{
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    >
                      {cardItem.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 13,
                      }}
                    >
                      {cardItem.description}
                    </Typography>
                  </Stack>
                  <Divider
                    component={Card}
                    sx={{
                      my: 1,
                      borderColor: "white",
                    }}
                  />
                  <Link
                    href="#"
                    variant="body2"
                    fontWeight="bold"
                    underline="hover"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      color: "#FFA500",
                      "& > svg": { transition: "0.2s" },
                      "&:hover > svg": { transform: "translateX(2px)" },
                    }}
                  >
                    <span>API Reference</span>
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      underline="hover"
                      sx={{
                        mt: "1px",
                        ml: "2px",
                      }}
                    />
                  </Link>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Pricing Section*/}
        <Box
          sx={{
            pt: { xs: 4, sm: 12, md: 4 },
            pb: { xs: 8, sm: 16, md: 8 },
            backgroundColor: "#002855",

            ml: 12,
            mr: 12,

            px: { xs: 2, sm: 9, md: 14 },
            py: { xs: 2, sm: 14 },
            "@media (max-width: 600px)": {
              ml: 3,  // Reduced margin-left for small screens
              mr: 3,  // Reduced margin-right for small screens
            },
          }}
        >
          <Container>
            <Stack
              spacing={1}
              sx={{
                width: "100%",
                textAlign: "center",
                color: "white",
                mb: 4,
               
              }}
            >
              <Typography component="h2" variant="h4"
              sx={{
               "@media (max-width: 600px)": {
                ml: 3,  // Reduced margin-left for small screens
                textAlign:"center"
              },
            }}
              >
                Pricing that scales to fit your needs
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: "80%",
                  alignSelf: "center",
                  "@media (max-width: 600px)": {
                ml: 1,  // Reduced margin-left for small screens
                textAlign:"left"}
                }}
              >
                 Whether you are a startup or an established enterprise, our tiered pricing 
                 model allows you to select a plan that best suits your transaction volume
                  and specific requirements. As your business grows, our scalable solutions can easily adapt to meet increasing demands, ensuring you only pay for what you need.
              </Typography>
            </Stack>

            {/*Table*/}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: "#FFA500",
                  }}
                >
                  <TableRow>
                    <TableCell align="center">Product</TableCell>
                    <TableCell align="center">Usage (Requests)</TableCell>
                    <TableCell align="center">Monthly cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.product}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={{ background: "#FFA500" }}
                      >
                        {row.product}
                      </TableCell>
                      <TableCell align="center">{row.usage}</TableCell>
                      <TableCell align="center">{row.cost}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>

        {/*Community Section*/}
        <Box>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              pt: { xs: 14, sm: 20, md: 10 },
              pb: { xs: 8, sm: 12, md: 10 },
            }}
          >
            <Box>
              <img width="100" height="100" src={whatsapp} alt="chatLogo" />
            </Box>
            <Stack spacing={2}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontSize: 36,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Join our WhatsApp community
              </Typography>
              <Typography
                variant="body2"
                textAlign="center"
                color="text.secondary"
                sx={{
                  alignSelf: "center",
                  width: { sm: "100%", md: "80%" },
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                Meet a diverse network, no matter where you are on your
                developer journey. Discover and connect with others who are
                using our Escrow API.
              </Typography>
              <Link
                href="#"
                variant="body2"
                fontWeight="bold"
                underline="always"
                color="#FFA500"
                sx={{
                  display: "inline-flex",
                  alignSelf: "center",
                  "& > svg": { transition: "0.2s" },
                  "&:hover > svg": { transform: "translateX(2px)" },
                }}
              >
                <span>Connect now</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  underline="hover"
                  sx={{
                    mt: "1px",
                    ml: "2px",
                  }}
                />
              </Link>
            </Stack>
          </Container>
        </Box>
      </main>
    </>
  );
}
