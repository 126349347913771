import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Card,
  Link,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios"; // Add axios for making API requests
import { useNavigate } from "react-router-dom";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';


const TransactionForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const [conditions, setConditions] = useState([]);
  const [showConditionInput, setShowConditionInput] = useState(false);
  const [newCondition, setNewCondition] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [amount, setAmount] = useState("");
  const [task, setTask] = useState("");

  const publicAddress = "0x1234bce6f65ad064cadbe";

  const handleAddCondition = () => {
    if (newCondition.trim()) {
      setConditions([...conditions, newCondition]);
      setNewCondition("");
      setShowConditionInput(false);
    }
  };

  const handleDeleteCondition = (index) => {
    const updatedConditions = conditions.filter((_, i) => i !== index);
    setConditions(updatedConditions);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const handleSubmit = async () => {
    // Create form data
    const formData = new FormData();
    formData.append("payer_address", publicAddress);
    formData.append(
      "payee_address",
      "0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65"
    ); // Replace with actual payee address
    formData.append("amount", amount);
    formData.append("job_name", task);
    conditions.forEach((condition, index) => {
      formData.append(conditions, condition);
    });
    if (selectedFile) {
      formData.append("condition_image_1", selectedFile); // Change the key if uploading multiple files
    }

    try {
      const response = await axios.post(
        `${apiUrl}/create_transaction`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Handle success response
      alert("Transaction created successfully!");
    } catch (error) {
      // Handle error response
      alert("Transaction created successfully");
    }
  };

  const [selectedOption, setSelectedOption] = useState("phoneNumber"); // New state for selected button

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    // Only allow digits and restrict input to 9 characters
    const numericValue = value.replace(/\D/g, "").slice(0, 9);
    setPhoneNumber(numericValue);
  };
  return (
    <Box sx={{ p: 2, backgroundColor: "white", minHeight: "100vh" }}>
      <Box sx={{ display: "flex", mt: 10 }}>
        <ArrowBackIcon sx={{ ml: { xs: 1, sm: 6 }, mr: 2 }} />
        <Link
          component={RouterLink}
          to="/Wallet"
          underline="none" // Start with no underline
          sx={{
            cursor: "pointer",
            fontSize: "1rem",
            color: "black",
            textDecoration: "none",
            "&:hover": {
              color: "#002855", // Change text color to blue on hover
              textDecoration: "underline", // Underline text on hover
            },
          }}
        >
          Back
        </Link>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "flex-start",
          maxWidth: 800,
          mx: "auto",
          mt: 3,
          gap: 2,
        }}
      >
        <Card
          sx={{
            flex: 1,
            p: 2,
            boxShadow: 3,
            borderRadius: 2,
            width: { xs: "100%", md: "100%" },
            maxWidth: "90%",
            mb: { xs: 2, md: 0 },
            marginLeft: "1.5%",
            "@media (min-width: 768px) and (max-width: 1024px)": {
              marginBottom: "70px", // Adjust width to fit iPad mini
            },
          }}
        >
          <Typography variant="h5" sx={{ mb: 3 }}>
            Create Transaction
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {/* Buttons for Phone Number and Wallet */}
            <Box sx={{ display: "flex", gap: 2 }}>
              {/* Phone Number Checkbox */}
              {/* Radio Group for Phone Number and Wallet */}
              <RadioGroup value={selectedOption} onChange={handleChange} row>
                <FormControlLabel
                  control={
                    <Radio
                      value="phoneNumber"
                      sx={{
                        color: "#002855",
                        "&.Mui-checked": {
                          color: "#002855",
                        },
                        "&:hover": {
                          color: "#FFA500",
                        },
                      }}
                    />
                  }
                  label="Phone Number"
                />
                {/* Wallet Checkbox */}
               
                <FormControlLabel
                  control={
                    <Radio
                      value="wallet"
                      sx={{
                        color: "#002855",
                        "&.Mui-checked": {
                          color: "#002855",
                        },
                        "&:hover": {
                          color: "#FFA500",
                        },
                      }}
                    />
                  }
                  label="Wallet"
                />
              </RadioGroup>
            </Box>

            {/* Content for each button */}
            {selectedOption === "phoneNumber" ? (
              <Box>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  Receiver's Phone Number
                </Typography>
               

                <TextField
                  variant="outlined"
                  fullWidth
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="Phone Number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+254</InputAdornment>
                    ),
                    sx: {
                      "& fieldset": {
                        borderWidth: "2px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#002855",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#002855",
                      },
                    },
                  }}
                />
              </Box>
            ) : (
              <Box>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  Receiver's Wallet Address
                </Typography>
                <Typography 
  variant="body2" 
  sx={{ 
    marginBottom: 1, 
    color: 'green', 
    display: 'flex', 
    alignItems: 'center',
    fontSize:12
  }}
>
  <PriorityHighIcon sx={{ fontSize: 13, marginRight: '2px' }} />
  Use Moconnect Pay wallet only
</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Wallet Address"
                />
              </Box>
            )}

            <Typography
              variant="body2"
              sx={{ color: "#FFA500", fontStyle: "italic" }}
            >
              Available bal: 100,000.05
            </Typography>

            <TextField
              label="Amount in KES"
              variant="outlined"
              fullWidth
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />

            <Typography variant="body2" sx={{ color: "grey" }}>
              An action that needs to be completed for the transaction..eg
              Delivery
            </Typography>

            <TextField
              label="Task"
              variant="outlined"
              fullWidth
              value={task}
              onChange={(e) => setTask(e.target.value)}
            />

            {conditions.length > 0 && (
              <Box sx={{ marginTop: 2 }}>
                <Typography
                  variant="body2"
                  sx={{ color: "grey", marginBottom: 1 }}
                >
                  Conditions:
                </Typography>
                {conditions.map((condition, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#f0f0f0",
                      padding: 1,
                      borderRadius: 1,
                      marginBottom: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                      {condition}
                    </Typography>
                    <Button
                      size="small"
                      sx={{ color: "red" }}
                      onClick={() => handleDeleteCondition(index)}
                    >
                      Delete
                    </Button>
                  </Box>
                ))}
              </Box>
            )}

            {showConditionInput && (
              <>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  A rule that must be met for the task to be considered done
                  (optional)
                </Typography>
                <TextField
                  label="Add Condition"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={newCondition}
                  onChange={(e) => setNewCondition(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <Typography variant="body2" sx={{ color: "grey" }}>
                  You can optionally upload images to clearly explain your needs
                  and enhance communication. (jpg, png, doc, pdf)
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<UploadFileIcon />}
                  sx={{ backgroundColor: "#002855", color: "white", mb: 2 }}
                >
                  Upload
                  <input type="file" hidden onChange={handleFileUpload} />
                </Button>

                {selectedFile && (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      mt: 2,
                    }}
                  >
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Uploaded file"
                      style={{ width: "30%", height: "auto", borderRadius: 8 }}
                    />
                    <IconButton
                      onClick={handleDeleteFile}
                      sx={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        backgroundColor: "white",
                        color: "red",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                sx={{
                  minWidth: 20,
                  padding: 0,
                  borderRadius: "50%",
                  width: 20,
                  height: 20,
                  border: "2px solid grey",
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                onClick={() => setShowConditionInput(!showConditionInput)}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "grey",
                    fontSize: 14,
                  }}
                >
                  +
                </Typography>
              </Button>

              <Typography
                variant="body2"
                sx={{
                  color: "grey",
                }}
              >
                Add condition
              </Typography>
            </Box>

            {showConditionInput && (
              <Box sx={{ marginTop: 2, display: "flex", gap: 2 }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#002855", color: "white" }}
                  onClick={handleAddCondition}
                >
                  Add
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "grey",
                    color: "grey",
                    "&:hover": {
                      borderColor: "darkgrey",
                    },
                  }}
                  onClick={() => setShowConditionInput(false)}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </Card>

        <Card
          sx={{
            flex: 1,
            p: 2,
            boxShadow: 3,
            borderRadius: 2,
            width: { xs: "88%", md: "60%" },
            marginLeft: "3%",
            marginBottom: { xs: "70px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 1,
            }}
          >
            <Typography variant="body1">Withdraw funds from wallet</Typography>
          </Box>
          <Typography variant="body1" sx={{ color: "black" }}>
            KES 0.05
          </Typography>

          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#002855",
              color: "white",
              marginTop: 1,
              "&:hover": {
                backgroundColor: "#FF8500",
              },
            }}
            onClick={handleSubmit}
          >
            Create Transaction
          </Button>
        </Card>
      </Box>
    </Box>
  );
};

export default TransactionForm;
