import React , { useState }from 'react';
import { useMediaQuery, Typography } from '@mui/material';
import Footer from '../src/Bottombar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PaymentPage from "../src/PaidP2P";
import WalletPopup from '../src/Wallet Screen/WalletPopup';
import TopBar from '../src/TobBar';

const ConfirmPayment = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const [walletPopupOpen, setWalletPopupOpen] = useState(false);

    const handleOpenWalletPopup = () => {
      setWalletPopupOpen(true);
    };
  
    const handleCloseWalletPopup = () => {
      setWalletPopupOpen(false);
    };
     
    return (
        <div>
            <TopBar
            onWalletClick={handleOpenWalletPopup}
            hideIcons={isSmallScreen} />
            <WalletPopup open={walletPopupOpen} onClose={handleCloseWalletPopup}/>
            <PaymentPage/>
            {!isSmallScreen && <Footer />}
            {isSmallScreen && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        bgcolor: '#002855',
                   
                        boxShadow: 1, // Optional: Add shadow to elevate the icons
                    }}
                >
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <IconButton color="inherit">
                            <AccountBalanceWalletIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'white', marginTop: 0.1 }}>
                            Wallet
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <IconButton color="inherit">
                            <CompareArrowsIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'white', marginTop: 0.1 }}>
                            Transactions
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <IconButton color="inherit">
                            <NotificationsIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'white', marginTop: 0.1 }}>
                            Notifications
                        </Typography>
                    </Box>
                </Box>
            )}
        </div>
    );
};

export default ConfirmPayment;
