import React, { useEffect, useState } from 'react';
import { JsonRpcProvider, Contract, parseEther, formatEther, Wallet, isHexString, parseUnits } from 'ethers';

import { Button, TextField, Typography, Container, Grid, CircularProgress } from '@mui/material';


const CreateWalletApp = () => {
    
    const [provider, setProvider] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [walletContract, setWalletContract] = useState(null);
    const [amount, setAmount] = useState('');
    const [balance, setBalance] = useState('0');
    const [recipientAddress, setRecipientAddress] = useState('');
    const [senderPrivateKey, setSenderPrivateKey] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [error, setError] = useState('');
    const [tokenContract, setTokenContract] = useState(null);
    const [factoryContract, setFactoryContract] = useState(null);
    

    // Contract addresses as deployed
    const factoryAddress = '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0';
    const tokenAddress = '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9';

    useEffect(() => {
        const initProvider = async () => {
            try {
                const localProvider = new JsonRpcProvider('http://localhost:8545');
                setProvider(localProvider);
                console.log("Provider initialized");
                
                // Initialize token contract
                const tokenABI = [
                    "function transfer(address to, uint256 amount) public returns (bool)",
                    "function balanceOf(address account) public view returns (uint256)"
                ];
                const tokenContractInstance = new Contract(tokenAddress, tokenABI, localProvider);
                setTokenContract(tokenContractInstance);

                // Initialize factory contract
                const factoryABI = [
                    "function userWallets(address) view returns (address)",
                    "function createWallet(address user) external returns (address)",
                    "event WalletCreated(address indexed user, address wallet)"
                ];
                const factoryContractInstance = new Contract(factoryAddress, factoryABI, localProvider);
                setFactoryContract(factoryContractInstance);

                // Check for existing wallet
                await checkExistingWallet(localProvider, factoryContractInstance);
            } catch (error) {
                console.error("Error initializing provider:", error);
                setError("Failed to initialize provider");
            }
        };

        initProvider();
    }, []);

    const checkExistingWallet = async (provider, factory) => {
        try {
            const signer = await provider.getSigner();
            const signerAddress = await signer.getAddress();
            
            const existingWalletAddress = await factory.userWallets(signerAddress);
            
            if (existingWalletAddress !== '0x0000000000000000000000000000000000000000') {
                setWalletAddress(existingWalletAddress);
                initializeWalletContract(existingWalletAddress, signer);
            }
        } catch (error) {
            console.error("Error checking existing wallet:", error);
        }
    };

    const initializeWalletContract = (address, signer) => {
        const walletABI = [
            "function deposit(address token, uint256 amount) external",
            "function transfer(address token, address to, uint256 amount) external",
            "function balanceOf(address token) external view returns (uint256)"
        ];
        const walletInstance = new Contract(address, walletABI, signer);
        setWalletContract(walletInstance);
        updateBalance(walletInstance);
    };

    const createWallet = async () => {
        if (!provider || !factoryContract) {
            setError("Provider or Factory contract is not initialized");
            return;
        }

        setIsCreating(true);
        setError('');
        try {
            const signer = await provider.getSigner();
            const signerAddress = await signer.getAddress();

            console.log("Calling createWallet function...");
            const tx = await factoryContract.connect(signer).createWallet(signerAddress);
            console.log("Transaction hash:", tx.hash);

            console.log("Waiting for transaction confirmation...");
            const receipt = await tx.wait();
            console.log("Transaction receipt:", receipt);

            const event = receipt.logs.find(log => log.topics[0] === factoryContract.interface.getEvent("WalletCreated").topicHash);
            if (event) {
                const parsedLog = factoryContract.interface.parseLog(event);
                const createdWalletAddress = parsedLog.args.wallet;
                console.log("Created wallet address:", createdWalletAddress);
                setWalletAddress(createdWalletAddress);
                initializeWalletContract(createdWalletAddress, signer);
            } else {
                throw new Error("WalletCreated event not found in transaction logs.");
            }
        } catch (error) {
            console.error("Error creating wallet:", error);
            setError(`Failed to create wallet: ${error.message}`);
        } finally {
            setIsCreating(false);
        }
    };

    const updateBalance = async (contract) => {
        try {
            const balance = await contract.balanceOf(tokenAddress);
            setBalance(formatEther(balance));
        } catch (error) {
            console.error("Error fetching balance:", error);
            setError("Failed to fetch balance. Ensure the contract is correctly deployed.");
        }
    };
    const depositToWallet = async () => {
        try {
            if (!senderPrivateKey || !amount) {
                setError("Please provide sender's private key and amount");
                return;
            }
    
            // Validate private key
            let formattedPrivateKey = senderPrivateKey;
            if (!isHexString(senderPrivateKey) || senderPrivateKey.length !== 66) {
                if (senderPrivateKey.startsWith('0x')) {
                    formattedPrivateKey = senderPrivateKey.slice(2);
                }
                if (formattedPrivateKey.length !== 64) {
                    throw new Error("Invalid private key format.");
                }
                formattedPrivateKey = '0x' + formattedPrivateKey;
            }
    
            const senderWallet = new Wallet(formattedPrivateKey, provider);
            console.log(`Wallet Address: ${senderWallet.address}`);
            const tokenWithSigner = tokenContract.connect(senderWallet);
    
            // Check if amount is a positive number
            if (!amount || isNaN(amount) || Number(amount) <= 0) {
                throw new Error("Amount must be a valid positive number.");
            }
    
            const parsedAmount = parseUnits(amount.toString(), 18);
            if (parsedAmount <= 0n) {
                throw new Error("Amount must be greater than zero.");
            }

            
    
            // Check balance before transferring
            const balance = await tokenContract.balanceOf(senderWallet.address);
            console.log(`Current Balance: ${balance.toString()}, Required Amount: ${parsedAmount}`);
            if (balance < parsedAmount) {
                throw new Error("Insufficient balance.");
            }
    
            // Attempt the transfer
            const tx = await tokenWithSigner.transfer(walletAddress, parsedAmount);
            await tx.wait();
    
            console.log(`Deposited ${amount} tokens to ${walletAddress}`);
            updateBalance(walletContract);
    
            setAmount('');
            setSenderPrivateKey('');
        } catch (error) {
            console.error("Error depositing tokens:", error);
            setError(`Failed to deposit tokens: ${error.message}`);
        }
    };
    
    
    
    const transferTokens = async () => {
        try {
            if (!walletContract || !recipientAddress || !amount) {
                setError("Please ensure wallet is created, recipient address is provided, and amount is set");
                return;
            }
            const tx = await walletContract.transfer(tokenAddress, recipientAddress, parseEther(amount));
            await tx.wait();
            console.log(`Transferred ${amount} tokens to ${recipientAddress}`);
            updateBalance(walletContract);
            setAmount('');
            setRecipientAddress('');
        } catch (error) {
            console.error("Error transferring tokens:", error);
            setError(`Failed to transfer tokens: ${error.message}`);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Wallet App
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={createWallet}
                        disabled={isCreating || walletAddress !== ''}
                    >
                        {isCreating ? <CircularProgress size={24} /> : (walletAddress ? "Wallet Created" : "Create New Wallet")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Your Wallet Address: {walletAddress || "Not created yet"}</Typography>
                    <Typography variant="h6">Balance: {balance} Tokens</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Sender's Private Key (for deposit)"
                        value={senderPrivateKey}
                        onChange={(e) => setSenderPrivateKey(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        type="password"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Amount"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={depositToWallet} sx={{ marginRight: 2 }}>
                        Deposit to Your Wallet
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Recipient Address"
                        value={recipientAddress}
                        onChange={(e) => setRecipientAddress(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="secondary" onClick={transferTokens}>
                        Transfer Tokens
                    </Button>
                </Grid>
                {error && (
                    <Grid item xs={12}>
                        <Typography color="error">{error}</Typography>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default CreateWalletApp;