import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  MobileStepper,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const PaymentPageSell = () => {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [activeStep, setActiveStep] = React.useState(0);
  const [isPaid, setIsPaid] = React.useState(false);

  // Function to handle copying text to clipboard
  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  const handleNext = () => {
    if (activeStep === 0 && !isPaid) {
      alert("Please confirm you have release the usdt before proceeding to the next step.");
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePaidClick = () => {
    setIsPaid(true);
    handleNext(); // Move to the next step once paid
  };

  // Function to handle the cancel order button click
  const handleCancelOrderClick = () => {
    navigate("/P2P"); // Navigate to the P2P Trading page
  };

  // Content based on the current step
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mb: 2 }}>
            {/* Step 1: Pending your Payment */}
            <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
              Pending your Payment
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              The order will be canceled if you don’t pay within{" "}
              <Typography component="span" color="green">
                14:14
              </Typography>
            </Typography>

            {/* Payment Method */}
            <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
              1. Pay with M-PESA Safaricom
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography variant="body1">
                <strong>You pay</strong>:{" "}
                <Typography component="span">200.00 KES</Typography>
              </Typography>

              {/* Aligned Texts with Copy Icon */}
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Payment method:
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="body2" color="textSecondary">
                    M-PESA Safaricom
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Account name:
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="body2" color="textSecondary">
                    HAPPYUT MORAA
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Phone number:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mr: 1 }}
                  >
                    0715664796
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleCopyClick("0715664796")}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            {/* Payment Confirmation */}
            <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
              2. Select I have sent to notify seller
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Only select 'I have paid' after you've completed the payment.
              Otherwise, your account may be temporarily suspended.
            </Typography>

            {/* Action Buttons */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ flexGrow: 1, mr: 1 }}
                onClick={handlePaidClick}
              >
                I have released my usdt
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ flexGrow: 1 }}
                onClick={handleCancelOrderClick} // Use the new function here
              >
                Cancel order
              </Button>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ mb: 2 }}>
            {/* Step 2: Pending Seller's Release */}
            <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
              Pending for seller's release
            </Typography>

            {/* Payment Information */}
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1">
                <strong>You released</strong>:
                <Typography component="span" sx={{ ml: 1 }}>
                  8 USD
                </Typography>
              </Typography>

              {/* Order Information Grid */}
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Payment method:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    M-PESA Safaricom
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Account name:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    HAPPYUT MORAA
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Phone number:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    0715664796
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Order Details */}
            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
              Order details
            </Typography>

            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Order ID:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  24083025911477
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Order time:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  08/29/2024, 21:59:12
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Unit price (USDT):
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  129.59 KES
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Quantity:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  1.54 USDT
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Order amount:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  200.00 KES
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Remark:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  24083025911477
                </Typography>
              </Grid>
            </Grid>

            {/* Contact Support Button */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => alert("Contact Support clicked")}
              >
                Need Help?
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => alert("Remind Seller clicked")}
              >
                Remind Seller
              </Button>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ mb: 2 }}>
            {/* Step 3: Order Fulfilled - Same as Step 2 */}
            <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
              Order Fulfilled
            </Typography>

            {/* Order Details */}
            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
              Order details
            </Typography>

            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Order ID:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  24083025911477
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Order time:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  08/29/2024, 21:59:12
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Unit price (USDT):
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  129.59 KES
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Quantity:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  1.54 USDT
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Order amount:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  200.00 KES
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Payment Method:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="textSecondary">
                  M-Pesa Safaricom
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => alert("Remind Seller clicked")}
              >
                View Assets
              </Button>
            </Box>
          </Box>
        );
      default:
        return <Typography variant="body2">Unknown step</Typography>;
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 400, margin: "0 auto", mt: 17 }}>
      {/* Stepper for navigating through the steps */}
      <MobileStepper
        variant="progress"
        steps={3} // Update the number of steps to 3
        position="static"
        activeStep={activeStep}
        sx={{ maxWidth: 400, flexGrow: 1, mb: 2 }}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === 2}>
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />

      {/* Main content box with border */}
      <Box
        sx={{
          width: "100%",
          padding: 2,
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: 2,
          mb: 20.5,
        }}
      >
        {renderStepContent(activeStep)}
      </Box>
    </Box>
  );
};

export default PaymentPageSell;
