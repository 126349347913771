// FAQDialog.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Snackbar,
  Typography,
  SnackbarContent,
} from "@mui/material";

// Simulate a rich text editor with a TextArea for simplicity
const RichTextEditor = (props) => {
  return (
    <TextField
      multiline
      rows={4}
      variant="outlined"
      fullWidth
      placeholder="Please explain your issue in as much detail as possible"
      {...props}
    />
  );
};

const FAQDialog = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  const [description, setDescription] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formVisible, setFormVisible] = useState(true); // State to control form visibility

  // Function to validate email format using regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle email input change and validation
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value)); // Set error if email is invalid
  };

  // Check if all fields are filled to enable the submit button
  const isSubmitDisabled = !email || !subject || !description;

  const handleSubmit = () => {
    if (!emailError) {
      console.log("Form submitted", { email, subject, description });
      setSnackbarOpen(true); // Open the Snackbar for success message
      setFormVisible(false); // Hide the form
      // Optionally, clear the form after submission
      setEmail("");
      setSubject("");
      setDescription("");
    }
  };
  // Close the Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {formVisible && ( // Render the form only if formVisible is true
        <Dialog
          open={open}
          // onClose removed because we don't want the dialog to close on backdrop click
          BackdropProps={{
            onClick: () => {}, // Disable closing when the backdrop is clicked
          }}
          PaperProps={{
            sx: {
              position: "absolute", // Make the dialog position absolute
              bottom: 90, // Adjust the bottom position to bring it near the chat icon
              right: 20, // Align it horizontally to the right, near the icon
              width: {
                xs: "76%", // 90% width on extra small screens
                sm: "400px", // 400px width on small screens and above
              },
              padding: "16px",
              textAlign: "center",
            },
          }}
          aria-labelledby="faq-dialog-title"
        >
          <DialogTitle
            sx={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}
          >
            How can we assist you?
          </DialogTitle>
          <DialogContent>
            {/* Email Field */}
            <TextField
              fullWidth
              variant="outlined"
              label="Please enter your email address"
              margin="normal"
              value={email}
              onChange={handleEmailChange} // Validate email on change
              error={emailError} // Highlight email input if there's an error
              helperText={
                emailError ? "Please enter a valid email address" : ""
              } // Show error message
            />

            {/* Subject Field */}
            <TextField
              fullWidth
              variant="outlined"
              label="Subject"
              margin="normal"
              value={subject}
              onChange={(e) => setSubject(e.target.value)} // Update subject state
            />

            {/* Rich Text Editor for Description */}
            <Typography
              sx={{
                marginBottom: "5px",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              Description
            </Typography>
            <RichTextEditor
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            {/* Buttons */}
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitDisabled} // Disable button if not all fields are filled
                sx={{
                  backgroundColor: isSubmitDisabled ? "#ccc" : "#002855",
                  color: "white",
                  "&:hover": {
                    backgroundColor: isSubmitDisabled ? "#ccc" : "#002855",
                    color:"#ffa500"
                  },
                }}
              >
                Submit
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {/* Snackbar for success message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Auto close after 6 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{
            vertical: window.innerWidth < 600 ? "top" : "bottom", // Show at top on small screens
            horizontal: "center", // Center horizontally
          }}
      >
        <SnackbarContent
          sx={{
            backgroundColor: "green", // Change Snackbar background color
            color: "white", // Change text color
          }}
          message="Your message has been successfully sent!"
        />
      </Snackbar>
    </>
  );
};

export default FAQDialog;
