import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const WalletComponent = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState(0);
    const [recipient, setRecipient] = useState('');
    const [amount, setAmount] = useState('');

    // Function to fetch the wallet balance
    const fetchBalance = async () => {
        setLoading(true);
        try {
            const accessToken = localStorage.getItem("accessToken");
            console.log("Token being sent for balance fetch:", accessToken);

            if (!accessToken) {
                throw new Error("Token is missing. Please log in again.");
            }

            const response = await axios.get(`${apiUrl}/api.get_balance`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            setBalance(response.data.balance || 0); // Update balance state
        } catch (error) {
            if (error.response) {
                console.error("Error fetching balance:", error.response.data);
            } else {
                console.error("Error fetching balance:", error.message);
            }
            toast.error("Failed to fetch balance.");
        } finally {
            setLoading(false); // Set loading to false after fetching balance
        }
    };

    // UseEffect to fetch the balance when the component mounts
    useEffect(() => {
        fetchBalance(); // Fetch balance on component mount
    }, []); // Empty dependency array to run only once on mount

    const sendFunds = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const response = await axios.post(`${apiUrl}/send_funds`, { recipient, amount }, {
                headers: { Authorization: `Bearer ${accessToken}` }
            });
            alert(`Transaction Hash: ${response.data.tx_hash}`);
        } catch (error) {
            console.error("Error sending funds:", error);
            toast.error("Failed to send funds.");
        }
    };

    if (loading) {
        return <CircularProgress />; // Show loading spinner while fetching balance
    }

    return (
        <div>
            <Typography variant="h5">Wallet Balance: {balance}</Typography>
            <TextField
                label="Recipient Address"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                fullWidth
            />
            <TextField
                label="Amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
            />
            <Button onClick={sendFunds} variant="contained" color="primary" disabled={!recipient || !amount}>
                Send Funds
            </Button>
        </div>
    );
};

export default WalletComponent;
