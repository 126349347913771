import React, { useState } from "react";

import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Icon to delete selected payment methods

import AddIcon from "@mui/icons-material/Add";
import PaymentMethod from "./PaymentMethod";
import PaymentDetails from "./PaymentDialogDetails";

const PostAd = () => {
  const [open, setOpen] = useState(false);   //to control the visibility of a dialog

  const handleOpen = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    console.log("Opening Dialog");
    setOpen(true);
  };
  const handleClose = (e) => {
    if (e) e.stopPropagation(); // Prevent event bubbling if event exists
    console.log("Closing Dialog");
    setOpen(false);
  };

  console.log("Dialog open state:", open); // This should reflect the correct open/close state

  const [value, setValue] = useState(129.23);
  //Checkbox Message
  const [checkedBuy, setCheckedBuy] = useState(false);  //boolean state for checkbox selection

  //They ensure only one option can be checked at a time by setting the other to false when one is checked.
  const handleCheckboxChangeBuy = (event) => {
    // If Buy is checked, uncheck Sell
    setCheckedBuy(event.target.checked);
    if (event.target.checked) {
      setCheckedSell(false);
    }
  };

  const [checkedSell, setCheckedSell] = useState(false);

  const handleCheckboxChangeSell = (event) => {
    // If Sell is checked, uncheck Buy
    setCheckedSell(event.target.checked);
    if (event.target.checked) {
      setCheckedBuy(false);
    }
  };

  // Add this new state to track form values
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (fieldName, value) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  // Handle increment based on whether Shift key is pressed
  const handleIncrement = (event) => {
    setValue((prevValue) => {
      const currentValue = parseFloat(prevValue); // Parse as float
      if (event.shiftKey) {
        // Increment by 0.01 when Shift is pressed
        return parseFloat((currentValue + 0.01).toFixed(2));
      } else {
        // Increment by 1 when Shift is not pressed
        return parseFloat((currentValue + 1).toFixed(2));
      }
    });
  };

  // Handle decrement based on whether Shift is pressed
  const handleDecrement = (event) => {
    setValue((prevValue) => {
      const currentValue = parseFloat(prevValue); // Parse as float
      if (event.shiftKey) {
        // Decrement by 0.01 when Shift is pressed
        return parseFloat((currentValue - 0.01).toFixed(2));
      } else {
        // Decrement by 1 when Shift is not pressed
        return parseFloat((currentValue - 1).toFixed(2));
      }
    });
  };

  const [timeLimit, setTimeLimit] = React.useState(15); // default value 15 min

  const handleChange = (event) => {
    setTimeLimit(event.target.value);
  };

  const [selectedPayments, setSelectedPayments] = useState([]); // Store selected payments
  // Callback to update selected payments when confirmed in PaymentMethod component
  const handleConfirmPayments = (payments) => {
    setSelectedPayments(payments);
    handleClose(); // Close dialog after confirmation
  };

  // Function to handle deletion of a payment method
  const handleDeletePayment = (payment) => {
    setSelectedPayments(selectedPayments.filter((item) => item !== payment));
  };

  const [openAddDetailsDialog, setOpenAddDetailsDialog] = useState(false);
  const [selectedPaymentMethodForDetails, setSelectedPaymentMethodForDetails] =
    useState(null);

  const handleOpenAddDetailsDialog = (payment) => {
    setOpenAddDetailsDialog(true);
    setSelectedPaymentMethodForDetails(payment);
  };

  const handleCloseAddDetailsDialog = () => {
    setOpenAddDetailsDialog(false);
  };

  const handleSavePaymentDetails = () => {
    const updatedPayments = selectedPayments.map((payment) => {
      if (
        typeof payment === "object" &&
        payment.name === selectedPaymentMethodForDetails
      ) {
        return {
          ...payment, // Spread existing properties
          details: formValues,
        };
      }
      return payment;
    });
  
    setSelectedPayments([...updatedPayments]); // Use spread operator here
    handleCloseAddDetailsDialog();
  };

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              marginBottom: "10px",

              fontSize: "15px",
            }}
          >
            Add Type
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={checkedBuy}
                onChange={handleCheckboxChangeBuy}
                sx={{
                  color: "#002855", // Initial color
                  "&.Mui-checked": {
                    color: "#002855", // Ensure it stays #002855 when checked
                  },
                }}
              />
            }
            label={<Typography>Buy</Typography>}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={checkedSell}
                onChange={handleCheckboxChangeSell}
                sx={{
                  color: "#002855", // Initial color
                  "&.Mui-checked": {
                    color: "#002855", // Ensure it stays #002855 when checked
                  },
                }}
              />
            }
            label={<Typography>Sell</Typography>}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "10px",

              fontSize: "15px",
            }}
          >
            Fixed Unit Price
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              padding: "5px 10px",

              width: {xs:"150px", sm:"550px"}, // Adjust the width here
              justifyContent: "space-between",
              backgroundColor: "white",
              marginTop: "1px",
            }}
          >
            <Button
              onClick={handleDecrement}
              sx={{
                minWidth: "30px",
                padding: "0",
                fontSize: "20px",
                color: "#555",
                backgroundColor: "#E0E0E0", // Light grey background
                borderRadius: "20%", // Optional: To make the button round
                border: "1px solid #ccc", // Reduced border
                "&:hover": {
                  backgroundColor: "#d6d6d6", // Darker grey on hover
                },
              }}
            >
              -
            </Button>
            {/* Editable Input Field */}
            <TextField
              value={value} // Now directly shows the string value during input
              onChange={handleInputChange}
              variant="standard"
              sx={{
                width: "100px",
                fontWeight: "bold",
                "& .MuiInputBase-root": {
                  border: "none", // Remove border
                },
                "& .MuiInput-underline:before": {
                  borderBottom: "none", // Remove the underline before focus
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottom: "none", // Remove the underline on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none", // Remove the underline after focus
                },
                "& input": {
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#555",
                },
              }}
              inputProps={{ inputMode: "decimal", pattern: "[0-9.]*" }} // Allow decimals and numbers
            />

            <Button
              onClick={handleIncrement}
              sx={{
                minWidth: "30px",
                padding: "0",
                fontSize: "20px",
                color: "#555",
                backgroundColor: "#E0E0E0", // Light grey background
                borderRadius: "20%", // Optional: To make the button round
                border: "1px solid #ccc", // Reduced border
                "&:hover": {
                  backgroundColor: "#d6d6d6", // Darker grey on hover
                },
              }}
            >
              +
            </Button>
          </Box>

          <Typography
            sx={{
              marginTop: "10px",
              color: "grey",
              fontSize: "11px",
            }}
          >
            The unit price should be between 103.20 - 300.80 ksh
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "10px",

              fontSize: "15px",
              marginTop: "10px",
            }}
          >
            Available Quantity
          </Typography>
          <TextField
            value="1000.23 ksh" // You can set the default phone number here
            variant="outlined"
            InputProps={{
              readOnly: true, // Makes the field non-editable
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
              width: {xs:"180px", sm:"550px"},
            }}
          />
        </Grid>

        <Box sx={{ display: "flex", gap: 7, width: "80%", marginTop: "14px" }}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "10px",
                color: "black",
                fontSize: "15px",

                marginTop: "10px",
                marginLeft: "12px",
              }}
            >
              Min Order Limit
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                padding: "5px 10px",
                width: "100%",
                justifyContent: "space-between",
                backgroundColor: "white",
                marginTop: "1px",
                marginLeft: "12px",
              }}
            >
              <Button
                onClick={handleDecrement}
                sx={{
                  minWidth: "30px",
                  padding: "0",
                  fontSize: "20px",
                  color: "#555",
                  backgroundColor: "#E0E0E0",
                  borderRadius: "20%",
                  border: "1px solid #ccc",
                  "&:hover": {
                    backgroundColor: "#d6d6d6",
                  },
                }}
              >
                -
              </Button>
              <TextField
                value={value}
                onChange={handleInputChange}
                variant="standard"
                sx={{
                  width: "100px",
                  fontWeight: "bold",
                  "& .MuiInputBase-root": {
                    border: "none",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottom: "none",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#555",
                  },
                }}
                inputProps={{ inputMode: "decimal", pattern: "[0-9.]*" }}
              />
              <Button
                onClick={handleIncrement}
                sx={{
                  minWidth: "30px",
                  padding: "0",
                  fontSize: "20px",
                  color: "#555",
                  backgroundColor: "#E0E0E0",
                  borderRadius: "20%",
                  border: "1px solid #ccc",
                  "&:hover": {
                    backgroundColor: "#d6d6d6",
                  },
                }}
              >
                +
              </Button>
            </Box>

            <Typography
              sx={{
                marginTop: "10px",
                color: "grey",
                fontSize: "11px",
                marginLeft: "12px",
              }}
            >
              The min order limit should be between 103.20 - 300.80 ksh
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "10px",
                fontSize: "15px",

                marginTop: "10px",
              }}
            >
              Maximum Order Limit
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                padding: "5px 10px",
                width: "100%",
                justifyContent: "space-between",
                backgroundColor: "white",
                marginTop: "1px",
              }}
            >
              <Button
                onClick={handleDecrement}
                sx={{
                  minWidth: "30px",
                  padding: "0",
                  fontSize: "20px",
                  color: "#555",
                  backgroundColor: "#E0E0E0",
                  borderRadius: "20%",
                  border: "1px solid #ccc",
                  "&:hover": {
                    backgroundColor: "#d6d6d6",
                  },
                }}
              >
                -
              </Button>
              <TextField
                value={value}
                onChange={handleInputChange}
                variant="standard"
                sx={{
                  width: "100px",
                  fontWeight: "bold",
                  "& .MuiInputBase-root": {
                    border: "none",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottom: "none",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& input": {
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#555",
                  },
                }}
                inputProps={{ inputMode: "decimal", pattern: "[0-9.]*" }}
              />
              <Button
                onClick={handleIncrement}
                sx={{
                  minWidth: "30px",
                  padding: "0",
                  fontSize: "20px",
                  color: "#555",
                  backgroundColor: "#E0E0E0",
                  borderRadius: "20%",
                  border: "1px solid #ccc",
                  "&:hover": {
                    backgroundColor: "#d6d6d6",
                  },
                }}
              >
                +
              </Button>
            </Box>

            <Typography
              sx={{
                marginTop: "10px",
                color: "grey",
                fontSize: "11px",
              }}
            >
              The max order limit should be between 103.20 - 300.80 ksh
            </Typography>
          </Grid>
        </Box>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "10px",
              fontSize: "15px",

              marginTop: "10px",
            }}
          >
            Payment Method
          </Typography>

          <Button
            variant="contained"
            startIcon={<AddIcon />} // Adding the + icon
            sx={{
              backgroundColor: "#002855", // Yellow background
              color: "white", // Dark text color
              fontWeight: "bold", // Bold text
              textTransform: "none", // To prevent uppercase transformation
              borderRadius: "8px", // Slightly rounded corners
              padding: "6px 16px", // Adjust padding for desired size
              "&:hover": {
                backgroundColor: "#002855", // Slightly darker yellow on hover
                color: "#ffa500",
              },
            }}
            onClick={handleOpen} // Opens the dialog on click
          >
            Add
            {/* Payment Dialog Component */}
            <div onClick={(e) => e.stopPropagation()}>
              <PaymentMethod
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirmPayments} // Ensure this function is passed
              />
            </div>
          </Button>

          <Box sx={{ padding: "5px" }}>
            <Grid container spacing={2}>
              {/* Example of displaying selected payments */}
              {selectedPayments.map((payment, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="body1">{payment}</Typography>
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => handleOpenAddDetailsDialog(
                        typeof payment === "string" ? payment : payment.name
                      )}
                      sx={{
                        marginRight: "10px",
                        color: "#002855",
                        borderColor: "#002855", // Outline color
                        "&:hover": {
                          borderColor: "#002855", // Slightly darker yellow on hover
                          color: "#ffa500",
                        },
                      }}
                    >
                      Add Details
                    </Button>

                    
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        color: "#002855",
                        borderColor: "#002855", // Outline color
                        "&:hover": {
                          borderColor: "#002855", // Slightly darker yellow on hover
                          color: "#ffa500",
                        },
                      }}
                      onClick={() => handleDeletePayment(payment)}
                      startIcon={<CloseIcon />}
                    >
                      Delete
                    </Button>
                  </Box>

                  <Box>
               
                  {payment.details && (
  <Box sx={{ mt: 1, ml: 2 }}>
    {/* Map over payment.details */}
    {Object.entries(payment.details).map(([key, value]) => (
      <Typography key={key} variant="body2" color="textSecondary">
        {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
      </Typography>
    ))}
  </Box>
)}

              </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Dialog
            open={openAddDetailsDialog}
            onClose={handleCloseAddDetailsDialog}
          >
            <DialogTitle>
              Add Details for {selectedPaymentMethodForDetails}
            </DialogTitle>
            <DialogContent>
              {selectedPaymentMethodForDetails ===
                "Mpesa Pochi la Biashara" && (
                <TextField
                  label="Phone Number"
                  fullWidth
                  value={formValues.phoneNumber || ""}
                  onChange={(e) =>
                    handleInputChange("phoneNumber", e.target.value)
                  }
                  sx={{ mt: 2 }}
                />
              )}

              {selectedPaymentMethodForDetails ===
                "M-PESA Kenya (Safaricom)" && (
                <TextField
                  label="Phone Number"
                  fullWidth
                  value={formValues.mpesaNumber || ""}
                  onChange={(e) =>
                    handleInputChange("mpesaNumber", e.target.value)
                  }
                  sx={{ mt: 2 }}
                />
              )}

              {selectedPaymentMethodForDetails === "Equity Bank" && (
                <>
                  <TextField
                    label="Paybill"
                    fullWidth
                    value={formValues.paybill || ""}
                    onChange={(e) =>
                      handleInputChange("paybill", e.target.value)
                    }
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <TextField
                    label="Account Number"
                    fullWidth
                    value={formValues.accountNumber || ""}
                    onChange={(e) =>
                      handleInputChange("accountNumber", e.target.value)
                    }
                  />
                </>
              )}

              {selectedPaymentMethodForDetails === "Standard Charter Bank" && (
                <>
                  <TextField
                    label="Paybill"
                    fullWidth
                    value={formValues.paybill || ""}
                    onChange={(e) =>
                      handleInputChange("paybill", e.target.value)
                    }
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <TextField
                    label="Account Number"
                    fullWidth
                    value={formValues.accountNumber || ""}
                    onChange={(e) =>
                      handleInputChange("accountNumber", e.target.value)
                    }
                  />
                </>
              )}

              {selectedPaymentMethodForDetails === "KCB Bank" && (
                <>
                  <TextField
                    label="Paybill"
                    fullWidth
                    value={formValues.paybill || ""}
                    onChange={(e) =>
                      handleInputChange("paybill", e.target.value)
                    }
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <TextField
                    label="Account Number"
                    fullWidth
                    value={formValues.accountNumber || ""}
                    onChange={(e) =>
                      handleInputChange("accountNumber", e.target.value)
                    }
                  />
                </>
              )}

              {selectedPaymentMethodForDetails === "Airtime Mobile Top-Up" && (
                <>
                  <TextField
                    label="Paybill"
                    fullWidth
                    value={formValues.paybill || ""}
                    onChange={(e) =>
                      handleInputChange("paybill", e.target.value)
                    }
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <TextField
                    label="Account Number"
                    fullWidth
                    value={formValues.accountNumber || ""}
                    onChange={(e) =>
                      handleInputChange("accountNumber", e.target.value)
                    }
                  />
                </>
              )}

              {selectedPaymentMethodForDetails === "M-PESA Paybill" && (
                <>
                  <TextField
                    label="Paybill"
                    fullWidth
                    value={formValues.paybill || ""}
                    onChange={(e) =>
                      handleInputChange("paybill", e.target.value)
                    }
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <TextField
                    label="Account Number"
                    fullWidth
                    value={formValues.accountNumber || ""}
                    onChange={(e) =>
                      handleInputChange("accountNumber", e.target.value)
                    }
                  />
                </>
              )}

              {/* Add similar blocks for other payment methods */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddDetailsDialog}>Cancel</Button>
              <Button onClick={handleSavePaymentDetails} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Typography
            sx={{
              marginTop: "10px",
              color: "grey",
              fontSize: "11px",
            }}
          >
            Select up to 5 methods
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            id="payment-time-label"
            sx={{
              marginBottom: "10px",
              fontSize: "15px",

              marginTop: "10px",
            }}
          >
            Payment Time Limit
          </Typography>
          <FormControl
            sx={{
              width: 130, // Adjust the width to match the design
              backgroundColor: "white", // Light background similar to the image
              borderRadius: "4px", // Rounded corners
              border: "1px solid #ccc", // Border to match the design
            }}
          >
            <Select
              value={timeLimit}
              onChange={handleChange}
              displayEmpty
              sx={{
                color: "#000", // Text color
                borderRadius: "inherit", // Make sure it follows the same rounded corners
              }}
            >
              <MenuItem value={15}>10 min</MenuItem>
              <MenuItem value={30}>15 min</MenuItem>
              <MenuItem value={45}>30 min</MenuItem>
              <MenuItem value={60}>60 min</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PostAd;
