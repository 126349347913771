import React from 'react';
import { Box, Container, Typography, Link, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#002f5f', // Dark blue background
        color: 'white',
        padding: '10px 0',
        position: 'fixed', // Keep the footer fixed at the bottom
        bottom: 0,         // Align it to the bottom
        left: 0,           // Ensure it stretches across the screen
        right: 0,          // Ensure it stretches across the screen
        width: '100%',     // Full width of the screen
        zIndex: 1000,      // Ensure it stays above other content
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0,
          paddingLeft: '20px', // Add padding to the left to push the text more to the left
        }}
      >
        <Typography variant="body2" sx={{ ml: 2 }}> {/* Remove any left margin */}
          © 2024 Moconnect. All Rights Reserved.{' '}
          <Link href="#" sx={{ color: 'white', textDecoration: 'underline' }}>
            Terms & Conditions
          </Link>
        </Typography>
        <Box>
          <IconButton sx={{ color: 'white' }} aria-label="facebook">
          <InstagramIcon />
          </IconButton>
          <IconButton sx={{ color: 'white' }} aria-label="linkedin">
            <LinkedInIcon />
          </IconButton>
          <IconButton sx={{ color: 'white' }} aria-label="twitter">
            <TwitterIcon />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;