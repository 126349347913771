import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

function PaymentPopup() {
  const [open, setOpen] = useState(false);
  
  const transaction = {
    amount: '20 ETH',
    task: 'Deliver Products for me',
    condition: 'Some condition text or an image URL'
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleToggle}>
        Show Transaction Details
      </Button>
      <Dialog open={open} onClose={handleToggle}>
        <DialogTitle>Transaction Details</DialogTitle>
        <DialogContent>
          <Typography variant="body1"><strong>Amount:</strong> {transaction.amount}</Typography>
          <Typography variant="body1"><strong>Task:</strong> {transaction.task}</Typography>
          
          <Typography variant="body1"><strong>Conditions:</strong></Typography>
          {typeof transaction.condition === 'string' ? (
            <Typography variant="body2">{transaction.condition}</Typography>
          ) : (
            <img src={transaction.condition} alt="Condition" style={{ maxWidth: '100%' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PaymentPopup;
