import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  Divider,
  InputBase,
  TextField,
  Tabs,
  Tab,
  Avatar,
  IconButton,
  Badge,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";
import DialogCondition from "./DialogCondition";
import FAQDialog from "../HelpForm";
import ChatIcon from '@mui/icons-material/Chat';

// Define keyframes for the pop animation
const popInOut = keyframes`
  0%, 100% {
    transform: scale(1);  // Normal size
  }
  50% {
    transform: scale(1.1); // Slightly larger size
  }
`;

const MyOrders = () => {
  const [open, setOpen] = useState(false); //to control the visibility of a dialog

  const handleOpen = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    console.log("Opening Dialog");
    setOpen(true);
  };
  const handleClose = (e) => {
    if (e) e.stopPropagation(); // Prevent event bubbling if event exists
    console.log("Closing Dialog");
    setOpen(false);
  };

  console.log("Dialog open state:", open); // This should reflect the correct open/close state

  const theme = useTheme(); // Access the theme for breakpoints
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [activeSection, setActiveSection] = useState("completed");
  const [startDate, setStartDate] = useState("2024-02-26");
  const [endDate, setEndDate] = useState("2024-08-26");

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };
  const navigate = useNavigate();
  const handleViewOrder = () => {
    navigate("/ViewOrderfinal");
  };
  const [activeButton, setActiveButton] = useState(0); // Manage the "Pending" and "Completed" tabs
  const [statusFilter, setStatusFilter] = useState(0); // Manage the "All", "Fulfilled", "Canceled" filters

  const handleButtonChange = (event, newValue) => {
    setActiveButton(newValue);
  };

  const handleStatusChange = (event, newValue) => {
    setStatusFilter(newValue);
  };

  const [activePending, setActivePending] = useState(0);

  const handlePendingChange = (event, newValue) => {
    setActivePending(newValue);
  };

  const [openFAQ, setOpenFAQ] = useState(false);

  const handleToggle = () => {
    setOpenFAQ((prevOpen) => !prevOpen);
  };

  const handleCloseFAQ = () => {
    setOpenFAQ(false);
  };

  const mockPendingOrders = [
    {
      orderId: "240822001835827",
      orderType: "Sell",
      orderQuantity: "1.53 USDT",
      orderAmount: "200.00 KES",
      unitPrice: "129.99 KES",
      counterparty: "Slakes12",
      orderStatus: "In progress",
      DateTime: "08/21/2024, 19:18:36",
    },
    {
      orderId: "240811044913566",
      orderType: "Escrow",
      orderQuantity: "1.14 USDT",
      orderAmount: "150.00 KES",
      unitPrice: "131.50 KES",
      counterparty: "Slakes12",
      orderStatus: "In progress",
      DateTime: "08/10/2024, 23:49:14",
    },
  ];

  const mockCompletedOrders = [
    {
      orderId: "240824220051708",
      orderType: "Buy",
      orderQuantity: "1.53 USDT",
      orderAmount: "200.00 KES",
      unitPrice: "129.99 KES",
      counterparty: "bla***@gmail.com",
      orderStatus: "Cancelled",
      DateTime: "08/24/2024, 17:00:52",
    },
    {
      orderId: "240822001835827",
      orderType: "Sell",
      orderQuantity: "1.53 USDT",
      orderAmount: "200.00 KES",
      unitPrice: "129.99 KES",
      counterparty: "Slakes12",
      orderStatus: "Fulfilled",
      DateTime: "08/21/2024, 19:18:36",
    },
    {
      orderId: "240811044913566",
      orderType: "Escrow",
      orderQuantity: "1.14 USDT",
      orderAmount: "150.00 KES",
      unitPrice: "131.50 KES",
      counterparty: "Slakes12",
      orderStatus: "Fulfilled",
      DateTime: "08/10/2024, 23:49:14",
    },
  ];

  const getStatusStyles = (orderStatus) => {
    switch (orderStatus) {
      case "Cancelled":
        return "red";
      case "In progress":
        return "orange";
      case "Fulfilled":
        return "green";
      default:
        return "green";
    }
  };

  const TypeColor = (orderType) => {
    switch (orderType) {
      case "Buy":
        return "green";
      case "Sell":
        return "orange";
      case "Escrow":
        return "red";
      default:
        return "black";
    }
  };

  return (
    <Box sx={{ padding: 2, marginBottom: 17.6 }}>
      {!isSmallScreen && (
        <>
          <Box sx={{ marginTop: 8 }}>
            <Typography variant="h6">My Orders</Typography>
          </Box>
          {/* Toggle Buttons */}
          <Box sx={{ display: "flex", marginBottom: 2, marginTop: 4 }}>
            <Badge
              badgeContent={2} // Replace 4 with your dynamic value
              color="error"
              sx={{
                marginRight: 1, // Adjust the margin to bring the badge closer
                "& .MuiBadge-badge": {
                  top: 4, // Adjust the vertical position of the badge
                  right: 20, // Adjust the horizontal position of the badge
                },
              }}
            >
              <Button
                onClick={() => handleSectionChange("pending")}
                variant={activeSection === "pending" ? "contained" : "outlined"}
                sx={{
                  marginRight: 2,
                  color: activeSection === "pending" ? "white" : "#002855", // Button background color             // Text color before hover
                  borderColor: "#002855", // Blue border color
                  backgroundColor:
                    activeSection === "pending" ? "#002855" : "transparent", // Button background color
                  "&:hover": {
                    color: "#FFA500", // Change text color on hover
                    backgroundColor: "#002855", // Blue background color on hover
                    borderColor: "#002855", // Keep border blue on hover
                  },
                }}
              >
                Pending
              </Button>
            </Badge>

            <Button
              onClick={() => handleSectionChange("completed")}
              variant={activeSection === "completed" ? "contained" : "outlined"}
              sx={{
                color: activeSection === "completed" ? "white" : "#002855", // Button background color             // Text color before hover
                borderColor: "#002855", // Blue border color
                backgroundColor:
                  activeSection === "completed" ? "#002855" : "transparent", // Button background color
                "&:hover": {
                  color: "#FFA500", // Change text color on hover
                  backgroundColor: "#002855", // Blue background color on hover
                  borderColor: "#002855", // Keep border blue on hover
                },
              }}
            >
              Completed
            </Button>
          </Box>
          {/* Completed Orders Section */}
          {activeSection === "completed" && (
            <>
              {/* Filters Section */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Select
                  defaultValue="All statuses"
                  sx={{ marginRight: 2, width: "130px", height: "40px" }}
                >
                  <MenuItem value="All statuses">All status</MenuItem>
                  <MenuItem value="Fulfilled">Fulfilled</MenuItem>
                  <MenuItem value="Cancelled">Cancelled</MenuItem>
                </Select>
                <Select
                  defaultValue="All orders"
                  sx={{ marginRight: 2, width: "130px", height: "40px" }}
                >
                  <MenuItem value="All orders">All orders</MenuItem>
                  <MenuItem value="Buy">Buy</MenuItem>
                  <MenuItem value="Sell">Sell</MenuItem>
                  <MenuItem value="Escrow">Escrow</MenuItem>
                </Select>
                {/* Date Range Picker with Border */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    padding: "2px",
                    borderRadius: "4px",
                    marginRight: 2,
                  }}
                >
                  <TextField
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    variant="standard"
                    sx={{ marginRight: 1 }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <Typography
                    sx={{ marginRight: 1, fontSize: 16, marginTop: "-4px" }}
                  >
                    →
                  </Typography>
                  <TextField
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    variant="standard"
                    sx={{ marginRight: 1 }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <InputBase
                  placeholder="Search username, order ID"
                  sx={{
                    marginRight: 2,
                    border: "1px solid #ccc",
                    padding: "0 8px",
                  }}
                />
                <Button variant="text"
                sx={{
                  color:"#002855"
                }}
                >Reset</Button>
              </Box>

              {/* Table Headers */}
              <Grid
                container
                sx={{
                  paddingY: 1,
                  backgroundColor: "white",
                  fontWeight: "bold",
                }}
              >
                <Grid item xs={2.0}>
                  <Typography>Order ID</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography>Order type</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Date & Time</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Order amount</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Counterparty</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography>Order status</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>View Order</Typography>
                </Grid>
              </Grid>
              <Divider />

              {/* Completed Orders List */}
              {mockCompletedOrders.map((order, index) => (
                <Box key={index}>
                  <Grid container alignItems="center" sx={{ paddingY: 4 }}>
                    <Grid item xs={2.0}>
                      <Typography sx={{ color: "#002855" }}>
                        {order.orderId}
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography
                        sx={{
                          color: TypeColor(order.orderType),
                        }}
                      >
                        {order.orderType}
                      </Typography>
                    </Grid>
                    <Grid item xs={2.0}>
                      <Typography>{order.DateTime}</Typography>
                    </Grid>
                    <Grid item xs={2.0}>
                      <Typography>{order.orderAmount}</Typography>
                    </Grid>
                    <Grid item xs={2.0}>
                      <Typography>{order.counterparty}</Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Typography
                        sx={{
                          backgroundColor: getStatusStyles(order.orderStatus),
                          color: "white",
                          borderRadius: "10px",
                          padding: "5px",
                          width: "100px", // Set the width to a fixed value
                          textAlign: "center",
                        }}
                      >
                        {order.orderStatus}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#002855",
                          color:"white",
                          "&:hover": {
                            color: "#FFA500",
                            backgroundColor: "#002855",
                           
                          },
                        }}
                        onClick={handleViewOrder}
                      >
                        Details
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider />
                </Box>
              ))}
            </>
          )}

          {/* Pending Orders Section */}
          {activeSection === "pending" && (
            <>
              {/* Filters Section */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Select
                  defaultValue="All statuses"
                  sx={{ marginRight: 2, width: "130px", height: "40px" }}
                >
                  <MenuItem value="All statuses">All status</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Processing">Processing</MenuItem>
                </Select>
                <Select
                  defaultValue="All orders"
                  sx={{ marginRight: 2, width: "130px", height: "40px" }}
                >
                  <MenuItem value="All orders">All orders</MenuItem>
                  <MenuItem value="Buy">Buy</MenuItem>
                  <MenuItem value="Sell">Sell</MenuItem>
                  <MenuItem value="Escrow">Escrow</MenuItem>
                </Select>
                {/* Date Range Picker with Border */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    padding: "2px",
                    borderRadius: "4px",
                    marginRight: 2,
                  }}
                >
                  <TextField
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    variant="standard"
                    sx={{ marginRight: 1 }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <Typography
                    sx={{ marginRight: 1, fontSize: 16, marginTop: "-4px" }}
                  >
                    →
                  </Typography>
                  <TextField
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    variant="standard"
                    sx={{ marginRight: 1 }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <InputBase
                  placeholder="Search username, order ID"
                  sx={{
                    marginRight: 2,
                    border: "1px solid #ccc",
                    padding: "0 8px",
                  }}
                />
                <Button variant="text"
                sx={{
                  color:"#002855"
                }}
                >Reset</Button>
              </Box>

              {/* Table Headers */}
              <Grid
                container
                sx={{
                  paddingY: 1,
                  backgroundColor: "white",
                  fontWeight: "bold",
                }}
              >
                <Grid item xs={2.0}>
                  <Typography>Order ID</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Order type</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Date & Time</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Order amount</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Counterparty</Typography>
                </Grid>
                <Grid item xs={2.0}>
                  <Typography>Order status</Typography>
                </Grid>
              </Grid>
              <Divider />

              {/* Pending Orders List */}
              {mockPendingOrders.map((order, index) => (
                <Box key={index}>
                  <Grid container alignItems="center" sx={{ paddingY: 4 }}>
                    <Grid item xs={2.0}>
                      <Typography sx={{ color: "#002855" }}>
                        {order.orderId}
                      </Typography>
                    </Grid>

                    {/* Order Type with "Action Required" button for Escrow */}
                    <Grid item xs={2.0}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: TypeColor(order.orderType),
                          }}
                        >
                          {order.orderType}
                        </Typography>

                        {/* Conditionally render Action Required button for Escrow */}
                        {order.orderType === "Escrow" && (
                          <Box
                            sx={{
                              position: "relative",
                              display: "inline-block",
                              animation: `${popInOut} 1s infinite ease-in-out`, // Apply animation to the container
                            }}
                          >
                            {/* Red notification badge */}
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-1px",
                                right: "-4px",
                                backgroundColor: "red",
                                borderRadius: "50%",
                                width: "12px",
                                height: "12px",
                                zIndex: 2, // Ensure the badge is in front
                              }}
                            />

                            {/* Button */}
                            <Button
                              variant="outlined"
                              sx={{
                                borderColor: "#002855",
                                color: "white",
                                backgroundColor: "#002855",
                                padding: "1px 3px",
                                borderRadius: "20px",
                                fontWeight: "bold",
                                textTransform: "none",
                                zIndex: 1, // Set button behind the notification
                                "&:hover": {
                                  backgroundColor: "#002855",
                                  borderColor: "#002855",
                                  color: "#ffa500",
                                },
                              }}
                              onClick={handleOpen} // Opens the dialog on click
                            >
                              Action Required
                              {/* Payment Dialog Component */}
                              <div onClick={(e) => e.stopPropagation()}>
                                <DialogCondition
                                  open={open}
                                  onClose={handleClose}
                                />
                              </div>
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={2.0}>
                      <Typography>{order.DateTime}</Typography>
                    </Grid>
                    <Grid item xs={2.0}>
                      <Typography>{order.orderAmount}</Typography>
                    </Grid>
                    <Grid item xs={2.0}>
                      <Typography>{order.counterparty}</Typography>
                    </Grid>
                    <Grid item xs={2.0}>
                      <Typography
                        sx={{
                          backgroundColor: getStatusStyles(order.orderStatus),
                          color: "white",
                          borderRadius: "10px",
                          padding: "5px",
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        {order.orderStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Box>
              ))}
            </>
          )}
        </>
      )}

      {/* For Small Screens */}
      {isSmallScreen && (
        <Box
          sx={{
            marginTop: 8,
            padding: "1rem",
            [theme.breakpoints.down("sm")]: {
              marginTop: 8, // Less margin for small screens
              padding: "0.5rem", // Less padding for small screens
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginLeft: 2,
            }}
          >
            My Orders
          </Typography>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          {activeTab === 0 && (
            <>
              {/* Tabs for Pending Orders */}
              <Tabs
                value={activePending}
                onChange={handlePendingChange}
                aria-label="pending order tabs"
                TabIndicatorProps={{ style: { display: "none" } }} // Disable the blue underline
              >
                <Tab
                  label="All"
                  sx={{
                    fontWeight: statusFilter === 0 ? "normal" : "normal",
                    color: statusFilter === 0 ? "black" : "black", // Red for "All"
                  }}
                />
                <Tab
                  label="In Dispute"
                  sx={{
                    fontWeight: statusFilter === 0 ? "normal" : "normal",
                    color: statusFilter === 0 ? "black" : "black", // Red for "All"
                  }}
                />
                <Tab
                  label="In Progress"
                  sx={{
                    fontWeight: statusFilter === 0 ? "normal" : "normal",
                    color: statusFilter === 0 ? "black" : "black", // Red for "All"
                  }}
                />
              </Tabs>

              {/* Add relevant content for Pending orders on small screens */}
              {activePending === 0 && (
                <>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "#ffa500" }}>Sell</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "red", marginBottom: 8 }}
                      >
                        In Dispute
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "red" }}>Escrow</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-block",
                          animation: `${popInOut} 1s infinite ease-in-out`, // Apply animation to the container
                        }}
                      >
                        {/* Red notification badge */}
                        <Box
                          sx={{
                            position: "absolute",
                            top: "-1px",
                            right: "-4px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                            zIndex: 2, // Ensure the badge is in front
                          }}
                        />

                        {/* Button */}
                        <Button
                          variant="outlined"
                          sx={{
                            borderColor: "#002855",
                            color: "white",
                            backgroundColor: "#002855",
                            padding: "1px 3px",
                            borderRadius: "20px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            textTransform: "none",
                            zIndex: 1, // Set button behind the notification
                            "&:hover": {
                              backgroundColor: "#002855",
                              borderColor: "#002855",
                              color: "#ffa500",
                            },
                          }}
                          onClick={handleOpen} // Opens the dialog on click
                        >
                          Action Required
                          {/* Payment Dialog Component */}
                          <div onClick={(e) => e.stopPropagation()}>
                            <DialogCondition
                              open={open}
                              onClose={handleClose}
                            />
                          </div>
                        </Button>
                      </Box>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "#ffa500", marginBottom: 8 }}
                      >
                        In Progress
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  {/* Additional pending transactions can be added here... */}
                </>
              )}

              {activePending === 1 && (
                <>
                  {/* Example Transaction Card for "In Dispute" Pending Orders */}
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "#ffa500" }}>Sell</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "red", marginBottom: 8 }}
                      >
                        In Dispute
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  {/* Additional in dispute transactions can be added here... */}
                </>
              )}

              {activePending === 2 && (
                <>
                  {/* Example Transaction Card for "In Progress" Pending Orders */}
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "green" }}>Buy</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "#ffa500", marginBottom: 8 }}
                      >
                        In Progress
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "red" }}>Escrow</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-block",
                          animation: `${popInOut} 1s infinite ease-in-out`, // Apply animation to the container
                        }}
                      >
                        {/* Red notification badge */}
                        <Box
                          sx={{
                            position: "absolute",
                            top: "-1px",
                            right: "-4px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                            zIndex: 2, // Ensure the badge is in front
                          }}
                        />

                        {/* Button */}
                        <Button
                          variant="outlined"
                          sx={{
                            borderColor: "#002855",
                            color: "white",
                            backgroundColor: "#002855",
                            padding: "1px 3px",
                            borderRadius: "20px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            textTransform: "none",
                            zIndex: 1, // Set button behind the notification
                            "&:hover": {
                              backgroundColor: "#002855",
                              borderColor: "#002855",
                              color: "#ffa500",
                            },
                          }}
                          onClick={handleOpen} // Opens the dialog on click
                        >
                          Action Required
                          {/* Payment Dialog Component */}
                          <div onClick={(e) => e.stopPropagation()}>
                            <DialogCondition
                              open={open}
                              onClose={handleClose}
                            />
                          </div>
                        </Button>
                      </Box>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "#ffa500", marginBottom: 8 }}
                      >
                        In Progress
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  {/* Additional in-progress transactions can be added here... */}
                </>
              )}
            </>
          )}
          {activeTab === 1 && (
            <>
              {/* Status Filters (All, Fulfilled, Canceled) */}
              <Tabs
                value={statusFilter}
                onChange={handleStatusChange}
                TabIndicatorProps={{ style: { display: "none" } }} // Disable the blue underline
              >
                <Tab
                  label="All"
                  sx={{
                    color: statusFilter === 0 ? "red" : "black", // Red for "All"
                    fontWeight: statusFilter === 0 ? "bold" : "normal",
                  }}
                />
                <Tab
                  label="Fulfilled"
                  sx={{
                    color: statusFilter === 1 ? "yellow" : "black", // Yellow for "Fulfilled"
                    fontWeight: statusFilter === 1 ? "bold" : "normal",
                  }}
                />
                <Tab
                  label="Canceled"
                  sx={{
                    color: statusFilter === 2 ? "blue" : "black", // Blue for "Canceled"
                    fontWeight: statusFilter === 2 ? "bold" : "normal",
                  }}
                />
              </Tabs>

              {/* Conditionally Render Content Based on the Selected Status */}
              {statusFilter === 0 && (
                <>
                  {/* Example Transaction Card for "All" */}
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "orange" }}>Sell</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "green", marginBottom: 8 }}
                      >
                        Fulfilled
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "green" }}>Buy</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "red", marginBottom: 8 }}
                      >
                        Cancelled
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "red" }}>Escrow</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "green", marginBottom: 8 }}
                      >
                        Fulfilled
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
              {statusFilter === 1 && (
                <>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "red" }}>Escrow</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "green", marginBottom: 8 }}
                      >
                        Fulfilled
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "orange" }}>Sell</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "green", marginBottom: 8 }}
                      >
                        Fulfilled
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
              {statusFilter === 2 && (
                <>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "0px 4px 12px rgba(0, 0, 1, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        <span style={{ color: "green" }}>Buy</span>{" "}
                        <span style={{ color: "black" }}>USDT</span>
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        09/03/2024, 11:45
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Unit price: 127.3 KES
                      </Typography>
                      <Typography variant="body2" sx={{ color: "black" }}>
                        Quantity: 1.2 USDT
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt="Profile Picture"
                          src="/ALL.jpeg" // Add the actual path to your profile image
                          sx={{ width: 20, height: 20, marginRight: 1 }} // Adjust the size and spacing as needed
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "grey", fontWeight: "bold" }}
                        >
                          MA1NA
                        </Typography>
                      </Box>
                    </Box>

                    <Box textAlign="right">
                      <Typography
                        variant="body2"
                        sx={{ color: "red", marginBottom: 8 }}
                      >
                        Cancelled
                      </Typography>

                      <Typography variant="body2" sx={{ color: "black" }}>
                        152.76 KES
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}

      {/* Help Icon positioned absolutely */}
      <IconButton
        sx={{
          position: "fixed",
          bottom: 100,
          right: 50,
          backgroundColor: "orange",
          color: "white",
          zIndex: 1500, // Ensures the icon is visible above the dialog backdrop
          "&:hover": {
            backgroundColor: "orange",
          },
          "@media (max-width: 600px)": {
            bottom: "13%",
            right: "5%",
          },
        }}
        onClick={handleToggle}

      >
        <ChatIcon />
      </IconButton>
       {/* Render the FAQDialog and pass open state and handleClose */}
       <FAQDialog open={openFAQ} handleClose={handleCloseFAQ} />
    </Box>
  );
};

export default MyOrders;
