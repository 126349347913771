import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Button,
  Typography,
  IconButton,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MetaMaskLogo from '../images/metamask.jpeg'; // Make sure to replace with the actual path
import CoinbaseLogo from '../images/coinbase.jpeg'; // Make sure to replace with the actual path
import WalletConnectLogo from '../images/walletconnect.jpeg'; // Make sure to replace with the actual path
import { formatEther } from "ethers/utils";

const WalletPopup = ({ open, onClose, onUpdateWalletInfo }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then(result => {
          const account = result[0];
          accountChangedHandler(account);
        })
        .catch(err => {
          setErrorMessage(err.message);
        });
    } else {
      setErrorMessage("Please install MetaMask");
    }
  };

  const accountChangedHandler = (newAccount) => {
    getUserBalance(newAccount.toString());
  };

  const getUserBalance = (address) => {
    window.ethereum.request({ method: 'eth_getBalance', params: [address, 'latest'] })
      .then(balance => {
        const formattedBalance = formatEther(balance);
        // Pass the wallet info to the parent component
        onUpdateWalletInfo(address, formattedBalance);
      })
      .catch(err => setErrorMessage(err.message));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          backgroundColor: '#002855',
          color: 'white',
        }}
      >
        Connect to Moconnect
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ backgroundColor: '#002855' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: "white" }}>
            You need a wallet to use the escrow system for easy, secure, and fast transactions.
          </Typography>

          <Button
            onClick={connectWalletHandler}
            variant="outlined"
            fullWidth
            startIcon={<img src={MetaMaskLogo} alt="MetaMask" style={{ width: 24, height: 24 }} />}
            sx={{
              justifyContent: 'flex-start',
              color: 'white',
              '&:hover': {
                borderColor: '#FFA500',
                backgroundColor: '#002855',
                color: 'white',
              },
            }}
          >
            MetaMask
          </Button>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<img src={CoinbaseLogo} alt="Coinbase Wallet" style={{ width: 24, height: 24 }} />}
            sx={{
              justifyContent: 'flex-start',
              color: 'white',
              '&:hover': {
                borderColor: '#FFA500',
                backgroundColor: '#002855',
                color: 'white',
              },
            }}
          >
            Coinbase Wallet
          </Button>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<img src={WalletConnectLogo} alt="WalletConnect" style={{ width: 24, height: 24 }} />}
            sx={{
              justifyContent: 'flex-start',
              color: 'white',
              '&:hover': {
                borderColor: '#FFA500',
                backgroundColor: '#002855',
                color: 'white',
              },
            }}
          >
            WalletConnect
          </Button>
          <Typography variant="body2" sx={{ mt: 2, color: '#FFA500' }}>
            How Moconnect Escrow Works?
          </Typography>
          <Divider sx={{ width: '100%', my: 2, backgroundColor: 'white' }} />
          <Typography variant="body2" sx={{ color: 'white' }}>OR</Typography>
          <Typography
            variant="outlined"
            fullWidth
            sx={{
              justifyContent: 'flex-start',
              color: '#FFA500',
              borderColor: 'white',
              '&:hover': {
                borderColor: '#FFA500',
                backgroundColor: '#002855',
                color: 'white',
              },
            }}
          >
            Don't have a wallet?
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WalletPopup;
