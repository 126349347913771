import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const refreshToken = useCallback(async () => {
    if (isTokenRefreshing) return;
    setIsTokenRefreshing(true);
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) throw new Error('No refresh token found');

      const response = await axios.post(`${apiUrl}/refresh`, { token: refreshToken });
      const newAccessToken = response.data.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing token:', error.response?.data || error.message);
      throw error;
    } finally {
      setIsTokenRefreshing(false);
    }
  }, [apiUrl, isTokenRefreshing]);

  const fetchUser = useCallback(async () => {
    try {
      let token = localStorage.getItem('accessToken');
      if (!token) throw new Error('No token found');

      const response = await axios.get(`${apiUrl}/user`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setUser(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        try {
          const newToken = await refreshToken();
          const response = await axios.get(`${apiUrl}/user`, {
            headers: { Authorization: `Bearer ${newToken}` },
          });
          setUser(response.data);
        } catch (refreshError) {
          console.error('Error fetching user data:', refreshError.response?.data || refreshError.message);
          handleLogout();
        }
      } else {
        console.error('Error fetching user data:', error.response?.data || error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [apiUrl, refreshToken]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setUser(null);
    window.location.href = '/login';
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const login = useCallback(async (credentials) => {
    try {
      const response = await axios.post(`${apiUrl}/login`, credentials);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      await fetchUser();
      return true;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      return false;
    }
  }, [apiUrl, fetchUser]);

  const contextValue = {
    user,
    loading,
    login,
    logout: handleLogout,
    refreshUser: fetchUser,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);