
import { CssBaseline, Box, Container } from '@mui/material';
import SignUpForm from "../Signup page /SignUpForm";


const SignUp=()=>{
    return (
        <>
        <CssBaseline />
        <Box
          sx={{
            minHeight: '100vh', 
            display: 'flex', 
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
        >
        
          <Container 
            maxWidth="sm" 
            sx={{
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <SignUpForm />
          </Container>
          
        </Box>
      </>
    )
}

export default SignUp;