import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Wallet from "../src/Wallet Screen/Wallet";
import Send from "./SendPage";
import LogIn from "./LoginPage/Login";
import SignUp from "../src/Signup page /SignUp";
import WalletProfile from "./Profile/WalletProfile";
import Transaction from "./Transaction";
import TransactionFile from "./TransactionFiles";
import PopupWallet from "../src/Wallet Screen/PopupWallet";
import P2P from "./P2P";
import OrdersP2P from "../src/Orders Moconnect/OrdersP2P";
import PaymentPopup from "./PopupPayment";

import ConfirmPayment from "./ConfirmPayment";
import ConfirmPaymentSell from "./ConfirmPaymentSell";
import ViewOrder from "./Orders Moconnect/View Order";
import ForgotPassword from "./LoginPage/ForgotPassword";
import FinalNotification from "./Notification Area/FinalNotification";
import Home from "../src/home/homepage"
import { UserProvider,  useUser } from '../src/LoginPage/UserContext';
import Developer from "../src/home/Developer";
import Partners from "./home/partner";
import Community from "./home/community";
import UserCentric from "./home/usercentric";
import ViewOrdersfinal from "./Orders Moconnect/ViewOrdersfinal";
import WalletComponent from "./CustomWallet";
import CreateWallet from "./CreateWallet";



import DisputePage from "./Orders Moconnect/Dispute Page";

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUser();
  
  if (loading) {
    return <div>Loading...</div>; // Or a loading spinner
  }
  
  if (!user) {
    return <Navigate to="/LogIn" replace />;
  }
  
  return children;
};

function AppRoutes() {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/LogIn" element={<LogIn />} />
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/" element={<Home />} />
      <Route path="/Developer" element={<Developer />} />
      <Route path="/Partners" element={<Partners />} />
      <Route path="/Community" element={<Community />} />
      <Route path="/UserCentric" element={<UserCentric />} />

      {/* Protected routes */}
      <Route path="/CreateWallet" element={<ProtectedRoute><CreateWallet /></ProtectedRoute>} />
      <Route path="/WalletComponent" element={<ProtectedRoute><WalletComponent /></ProtectedRoute>} />
      <Route path="/Wallet" element={<ProtectedRoute><Wallet /></ProtectedRoute>} />
      <Route path="/SendPage" element={<ProtectedRoute><Send /></ProtectedRoute>} />
      <Route path="/WalletProfile" element={<ProtectedRoute><WalletProfile /></ProtectedRoute>} />
      <Route path="/Transaction" element={<ProtectedRoute><Transaction /></ProtectedRoute>} />
      <Route path="/TransactionFile" element={<ProtectedRoute><TransactionFile /></ProtectedRoute>} />
      <Route path="/PopupWallet" element={<ProtectedRoute><PopupWallet /></ProtectedRoute>} />
      <Route path="/P2P" element={<ProtectedRoute><P2P /></ProtectedRoute>} />
      <Route path="/OrdersP2P" element={<ProtectedRoute><OrdersP2P /></ProtectedRoute>} />
      <Route path="/ConfirmPayment" element={<ProtectedRoute><ConfirmPayment /></ProtectedRoute>} />
      <Route path="/ConfirmPaymentSell" element={<ProtectedRoute><ConfirmPaymentSell /></ProtectedRoute>} />
      <Route path="/PaymentPopup" element={<ProtectedRoute><PaymentPopup /></ProtectedRoute>} />
      <Route path="/ViewOrder" element={<ProtectedRoute><ViewOrder /></ProtectedRoute>} />
      <Route path="/FinalNotification" element={<ProtectedRoute><FinalNotification /></ProtectedRoute>} />
      <Route path="/ViewOrderfinal" element={<ProtectedRoute><ViewOrdersfinal /></ProtectedRoute>} />
      <Route path="/DisputePage" element={<ProtectedRoute><DisputePage/></ProtectedRoute>}/>
    </Routes>
  );
}

function App() {
  return (
    <UserProvider>
      <Router>
        <ToastContainer />
        <AppRoutes />
      </Router>
    </UserProvider>
  );
}

export default App;