import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
const ViewOrder = () => {
    const navigate = useNavigate();
    const handleHome= () => {
        navigate("/");
      };
  return (
    <Box sx={{ p: 2, maxWidth: '600px', marginTop: 10, marginLeft: 50,marginBottom: 6.7 }}>
      <Box display="flex" alignItems="center" sx={{ mt: 2 }}>

      {/* Icon with circular border and background */}
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          borderRadius: '50%', 
          borderColor: 'black', 
          color: 'black', 
          width: 20, 
          height: 20,
          mr: 1 // Margin right for spacing between icon and text
        }}
      >
        <CheckCircleIcon fontSize="small" sx={{
          color: "green"
        }} />
      </Box>
      
      {/* Order Fulfilled Text */}
      <Typography  variant="h6" sx={{
        fontWeight: "bold",

      }}>Order Fulfilled</Typography>
    </Box>
      

      <Typography variant="body2" gutterBottom sx={{
        marginTop: 3,
        fontSize: 18,
      }}>
        Order details
      </Typography>

      {/* Order Information */}
      <Box display="flex" justifyContent="space-between">
        <Typography>Order ID</Typography>
        <Box display="flex" alignItems="center">
          <Typography>2408292349588850</Typography>
          <ContentCopyIcon fontSize="small" sx={{ ml: 1, cursor: 'pointer' }} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Order time</Typography>
        <Typography>08/29/2024, 18:49:59</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Unit price (USDT)</Typography>
        <Typography>128.05 KES</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Quantity</Typography>
        <Typography>6.83 USDT</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Order amount</Typography>
        <Typography>874.58 KES</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Payer's name</Typography>
        <Typography>FRANCIS MAINA GITUKU</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Payment method</Typography>
        <Typography>M-PESA Safaricom</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Payment information</Typography>
        <Typography>0704909172</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Order completion time</Typography>
        <Typography>08/29/2024, 18:57:46</Typography>
      </Box>

      {/* Divider */}
      <Divider sx={{ my: 2 }} />

      {/* Rate Your Experience */}
      <Typography variant="h6" gutterBottom>
        Rate your experience
      </Typography>
      <Typography variant="body1" color="green">
        😊 Positive
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        08/29/2024
      </Typography>

      {/* Follow Link (Placeholder) */}
      <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
        ⭐ Follow
      </Typography>

      {/* View Asset Button */}
      <Button 
  variant="contained" 
  
  sx={{ 
    mt: 2, 
    backgroundColor: '#002f5f', 
    color: 'white',
    width: '150px', // Set your desired width here
    ml: 34,
    '&:hover': {
      backgroundColor: 'white',  // Optional: Darken the button on hover
      color: "black"
    }
  }} 
  onClick={handleHome}
>
  View Asset
</Button>

    </Box>
  );
};

export default ViewOrder;
