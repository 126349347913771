import React, { useState } from "react";
import { useMediaQuery,Badge } from "@mui/material";
import Footer from "../Bottombar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink
import NotificationDropdown from "../Notification Area/NotificationPage"; // Import the NotificationDropdown component
import WalletPopup from "../Wallet Screen/WalletPopup";
import {Link, Box} from "@mui/material";
import MyOrders from "./MyOrders";
import TopBar from "../TobBar";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const OrdersP2P = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [walletPopupOpen, setWalletPopupOpen] = useState(false);

  const handleOpenWalletPopup = () => {
    setWalletPopupOpen(true);
  };

  const handleCloseWalletPopup = () => {
    setWalletPopupOpen(false);
  };
  return (
    <div>
      <TopBar
      onWalletClick={handleOpenWalletPopup}
      hideIcons={isSmallScreen} />
      <WalletPopup open={walletPopupOpen} onClose={handleCloseWalletPopup}/>
      <MyOrders/>
      {!isSmallScreen && <Footer 
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
      />}
      {isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            position: "fixed",
            bottom: 0,
            width: "100%",
            bgcolor: "white",
            padding: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
              "&:hover": {
                "& .icon": { color: "#4169E1" },
                "& .text": { color: "#4169E1" },
              },
            }}
          >
            <Link href="/PopupWallet">
              <IconButton color="inherit">
                <AccountBalanceWalletIcon sx={{ color: "black" }} />
              </IconButton>
              <Typography variant="body2" sx={{ color: "black", marginTop: 0.1 }}>
                Wallet
              </Typography>
            </Link>
          </Box>
          
          <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: 3,
                  "&:hover": {
                    "& .icon": { color: "#4169E1" },
                    "& .text": { color: "#4169E1" },
                  },
                }}
              >
                <IconButton
                  color="inherit"
                  component={RouterLink}
                  to="/OrdersP2P"
                >
                 <SwapHorizIcon className="icon" sx={{ color: "black" }} />
                </IconButton>
                <Typography
                  variant="body2"
                  className="text"
                  sx={{ color: "black", marginTop: 0.1 }}
                >
                  My Orders
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "&:hover": {
                    "& .icon": { color: "#4169E1" },
                    "& .text": { color: "#4169E1" },
                  },
                }}
              >
                 <Link href="/FinalNotification">
                <IconButton color="inherit" >
                  <Badge badgeContent={4} color="primary">
                    <NotificationsIcon
                      className="icon"
                      sx={{ color: "black" }}
                    />
                  </Badge>
                </IconButton>
                </Link>
                <Typography
                  variant="body2"
                  className="text"
                  sx={{ color: "black", marginTop: 0.1 }}
                >
                  Notifications
                </Typography>
                <NotificationDropdown
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                />
              </Box>
        </Box>
      )}
    </div>
  );
};

export default OrdersP2P;
