import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import TopBar from "../TobBar";
import Walletbalance from "../Wallet Screen/WalletBalance";
import Footer from "../Bottombar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink
import NotificationDropdown from "../Notification Area/NotificationPage"; // Import the NotificationDropdown component
import WalletPopup from "../Wallet Screen/WalletPopup";
import { Link, Box, Badge } from "@mui/material";
import { formatEther } from 'ethers';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const Wallet = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [walletPopupOpen, setWalletPopupOpen] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenWalletPopup = () => {
    setWalletPopupOpen(true);
  };

  const handleCloseWalletPopup = () => {
    setWalletPopupOpen(false);
  };

  // Handler to update account and balance after wallet connection
  const updateWalletInfo = (account, balance) => {
    setDefaultAccount(account);
    setUserBalance(balance);
  };

  return (
    <div>
      <TopBar onWalletClick={handleOpenWalletPopup} hideIcons={isSmallScreen} />
      <WalletPopup
        open={walletPopupOpen}
        onClose={handleCloseWalletPopup}
        onUpdateWalletInfo={updateWalletInfo}
      />
      <Walletbalance
        defaultAccount={defaultAccount}
        userBalance={userBalance}
      />
      {!isSmallScreen && <Footer 
      sx={{
        marginTop: "auto", // Push footer to the bottom
      }}
      />}
      {isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            position: "fixed",
            bottom: 0,
            width: "100%",
            bgcolor: "white",
            padding: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
              "&:hover": {
                "& .icon": { color: "#4169E1" },
                "& .text": { color: "#4169E1" },
              },
            }}
          >
            <Link href="/PopupWallet">
              <IconButton color="inherit">
                <AccountBalanceWalletIcon sx={{ color: "rgba(0, 0, 0, 0.65)" }} />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0, 0, 0, 0.65)", marginTop: 0.1 }}
              >
                Wallet
              </Typography>
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
              "&:hover": {
                "& .icon": { color: "#4169E1" },
                "& .text": { color: "#4169E1" },
              },
            }}
          >
            <IconButton color="inherit" component={RouterLink} to="/OrdersP2P">
              <SwapHorizIcon className="icon" sx={{ color: "rgba(0, 0, 0, 0.65)" }} />
            </IconButton>
            <Typography
              variant="body2"
              className="text"
              sx={{ color: "rgba(0, 0, 0, 0.65)", marginTop: 0.1 }}
            >
              My Orders
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "&:hover": {
                "& .icon": { color: "#4169E1" },
                "& .text": { color: "#4169E1" },
              },
            }}
          >
            <Link href="/FinalNotification">
              <IconButton color="inherit">
                <Badge badgeContent={4} color="primary">
                  <NotificationsIcon className="icon" sx={{ color: "rgba(0, 0, 0, 0.65)" }} />
                </Badge>
              </IconButton>
            </Link>
            <Typography
              variant="body2"
              className="text"
              sx={{ color: "rgba(0, 0, 0, 0.65)", marginTop: 0.1 }}
            >
              Notifications
            </Typography>
            <NotificationDropdown
              anchorEl={anchorEl}
              handleClose={handleClose}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Wallet;
