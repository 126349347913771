import React from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputBase,
  Chip,
} from "@mui/material";
import { Search, FilterList} from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const transactions = [
  {
    id: "BnASDA313",
    name: "Peter Maturwe",
    date: "July 20, 2023 15:06",
    amount: "Ksh 1,000",
    status: "Completed",
  },
  {
    id: "BnASDA313",
    name: "Peter Maturwe",
    date: "July 20, 2023 15:06",
    amount: "Ksh 4,000",
    status: "In progress",
  },
  {
    id: "BnASDA313",
    name: "Peter Maturwe",
    date: "May 22, 2021 15:06",
    amount: "Ksh 40,000",
    status: "Completed",
  },
  {
    id: "BnASDA313",
    name: "Peter Maturwe",
    date: "June 20, 2024 05:06",
    amount: "Ksh 500",
    status: "In dispute",
  },
];

const statusColor = (status) => {
  switch (status) {
    case "Completed":
      return "green";
    case "In progress":
      return "orange";
    case "In dispute":
      return "red";
    default:
      return "black";
  }
};

const TransactionDetails = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h5" component="div" gutterBottom mt={10} mb={3}>
        Transactions
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <InputBase
          placeholder="Search anything"
          startAdornment={<Search />}
          sx={{
            border: 1,
            borderColor: "grey.300",
            borderRadius: 1,
            p: "2px 10px",
            bgcolor: "white",
            width: "300px",
          }}
        />
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" mr={2}>
            <IconButton>
              <FilterList />
            </IconButton>
            <Typography variant="body2">Filter</Typography>
          </Box>
         
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>
                  <Chip
                    label={row.status}
                    sx={{
                      backgroundColor: statusColor(row.status),
                      color: "white",
                      width: "100px", // Set the width to a fixed value
                      textAlign: "center", // Center the text within the Chip
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
        <Pagination count={10} variant="outlined" color="primary" />
      </Box>

      {/* Help Icon positioned absolutely */}
      <IconButton
        sx={{
          position: "fixed",
          bottom: 100,
          right: 50,
          backgroundColor: "orange",
          color: "white",
          "&:hover": {
            backgroundColor: "orange",
          },
          "@media (max-width: 600px)": {
            bottom: "13%",
            right: "5%",
          },
        }}
        onClick={() => {
          alert("Help clicked!");
        }}
      >
        <HelpOutlineIcon />
      </IconButton>
      {/* Add a margin bottom to push the footer down */}
      <Box mb={15} /> {/* Adjust the mb value to suit your design needs */}
    </Container>
  );
};

export default TransactionDetails;
