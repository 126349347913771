import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  List,
  ListItem,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the close icon

const PaymentMethod = ({ open, onClose , onConfirm }) => {
  
    const [selectedPayments, setSelectedPayments] = useState([]);

    const paymentMethods = [
      { label: 'Mpesa Pochi la Biashara' },
      { label: 'M-PESA Kenya (Safaricom)'},
      { label: 'Equity Bank'},
      { label: 'Standard Charter Bank'},
      { label: 'KCB Bank'},
      { label: 'Airtime Mobile Top-Up'},
      { label: 'M-PESA Paybill'},
    ];
  
    const handlePaymentChange = (method) => {
      setSelectedPayments((prev) =>
        prev.includes(method)
          ? prev.filter((item) => item !== method)
          : [...prev, method]
      );
    };
  
    const handleConfirm = (e) => {
        e.stopPropagation(); // Prevent event bubbling
        onConfirm(selectedPayments); // Pass selected payments to the parent component
        onClose();
      };

    const handleCloseClick = (e) => {
        e.stopPropagation(); // Prevent event bubbling
        console.log('Close button clicked');
        onClose();
      };

    // Make sure we're actually receiving the open prop
  console.log('Dialog open state:', open);

    

  return (
    <Dialog 
    open={open} 
    onClose={(e) => {
      e.stopPropagation(); // Prevent event bubbling
      onClose();
    }}
    maxWidth="sm"
    fullWidth
    onClick={(e) => e.stopPropagation()} // Prevent clicks inside dialog from bubbling
  >
      <DialogTitle>
        Select Payment Method
        {/* Close icon */}
        <IconButton
          aria-label="close"
          onClick={handleCloseClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <TextField
          placeholder="Enter a payment method"
          variant="outlined"
          fullWidth
          margin="dense"
        />
        <Typography variant="subtitle2" color="error" style={{ marginTop: '8px' }}>
          Select Payment Method (Up to 5 methods)
        </Typography>
        <List>
          {paymentMethods.map((method, index) => (
            <ListItem key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPayments.includes(method.label)}
                    sx={{
                      color: "#002855", // Initial color
                      "&.Mui-checked": {
                        color: "#002855", // Ensure it stays #002855 when checked
                      },
                    }}
                    onChange={(e) => {
                        e.stopPropagation();
                        handlePaymentChange(method.label);
                      }}
                    disabled={selectedPayments.length >= 5 && !selectedPayments.includes(method.label)}
                  />
                }
                label={
                  <div>
                    {method.label}{' '}
                    {method.recommended && (
                      <Typography component="span" style={{ color: '#ff9900' }}>
                        Recommended
                      </Typography>
                    )}
                  </div>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleConfirm} variant="contained"  sx={{ backgroundColor: '#002855', color:"white" ,
           "&:hover": {
            backgroundColor: "#002855", // Slightly darker yellow on hover
            color: "#ffa500",
          },
        }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentMethod;
