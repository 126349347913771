import React, { useRef, useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa"; // For the upward arrow icon
import Navbar from "./navbar";
import Solutions from "./solutions";
import Community from "./community";
import About from "./about";
import Partners from "./partner";
import FooterHome from "./footerhome";
import Developer from "./Developer";
import TopBlack from "./Topblack";
import UserCentric from "./usercentric";

function Home() {
  const solutionsRef = useRef(null);
  const communityRef = useRef(null);
  const developersRef = useRef(null);
  const partnersRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Add event listener for scroll event
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Navbar
        scrollToSection={(id) => {
          switch (id) {
            case "#solutions":
              scrollToSection(solutionsRef);
              break;
            case "#community":
              scrollToSection(communityRef);
              break;
            case "#partners":
              scrollToSection(partnersRef);
              break;
            case "#developers":
              scrollToSection(developersRef);
              break;
            default:
              window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }}
      />

      <TopBlack />
      <Solutions />
      <section id="solutions" ref={solutionsRef}>
        <UserCentric />
      </section>

      <section id="community" ref={communityRef}>
        <Community />
      </section>

      <About />

      <section id="developers" ref={developersRef}>
        <Developer />
      </section>

      <section id="partners" ref={partnersRef}>
        <Partners />
      </section>
      <FooterHome />

      {/* Scroll to top button */}
      {isVisible && (
        <button
          onClick={scrollToTop}
          style={styles.scrollToTop}
        >
          <FaArrowUp />
        </button>
      )}
    </>
  );
}

const styles = {
  scrollToTop: {
    position: "fixed",
    bottom: "40px",
    right: "40px",
    backgroundColor: "#002855",
    color: "white",
    border: "5px",
    borderColor:"#FFA500",
    borderRadius: "10%",
    padding: "10px",
    cursor: "pointer",
    fontSize: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
   
  },
};

export default Home;
