import {
  Box,
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Rating,
  Avatar,
  Card,
  CardContent,
} from "@mui/material";
import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChatIcon from "@mui/icons-material/Chat";


const testimonials = [
    {
      name: "Peter Maturwe",
      image: "https://i.pravatar.cc/300", // Replace this with the actual image URL
      review:
        "Using Moconnect has transformed my business transactions! The escrow payments provide me with the confidence that my funds are secure, and the wallet transactions make managing my finances a breeze. I love the P2P trading (USDT) feature, which allows me to buy and sell cryptocurrencies easily. It’s clear that MoConnect prioritizes security and trust, making it an essential tool for my business....",
      rating: 4,
    },
    {
      name: "Mary Wambui",
      image: "https://i.pravatar.cc/301", // Replace this with the actual image URL
      review:
        "As a freelancer, I appreciate how Moconnect facilitates secure payments through escrow payments. Knowing that my funds are held safely until both parties fulfill their obligations gives me peace of mind. The platform's smart contracts automate agreements, which means fewer disputes and more efficient transactions. I highly recommend Moconnect for anyone seeking a reliable payment solution!",
      rating: 5,
    },
    {
      name: "Helder Rwanda",
      image: "https://i.pravatar.cc/302", // Replace this with the actual image URL
      review:
        "Moconnect has been a game changer for my small business. The ability to conduct multi-party transactions with escrow payments ensures everyone feels secure, while the P2P trading (USDT) feature allows for quick and easy currency exchanges. I trust MoConnect to handle my financial needs, knowing they use smart contracts to maintain transparency and efficiency.",
      rating: 5,
    },
  ];
  
  const faqData = [
    {
      question: "What payment channels are available on Moconnect Pay?",
      answer:
        "You can receive payments on Moconnect Pay both locally and internationally via different methods including mobile money, bank transfer, and card.",
    },
    {
      question: "Why do I have a Escrow Balance in my Moconnect Pay?",
      answer:
        "Your wallet helps you receive payments globally and see the samount of money you have.So the Escrow Balance is the amount of money that you have released and yet to be given to the Seller",
    },
    {
      question: "How do I use Public Address?",
      answer:
        "You can create Public Address within your Moconnect Pay.Simply Connect your Wallet and automatically you get the Address. Public Address make it easy for you to exchange your money.",
    },
  ];
  
function About() {
  return (
    <Box>
      

      <Box
        sx={{
          padding: "40px",
          maxWidth: "1200px",
          margin: "auto",
          display: "flex",
          mt: 10,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* Left Section: FAQ Heading and Button */}
        <Box
          sx={{
            flex: 1,
            paddingRight: { md: "40px" },
            marginBottom: { xs: "20px", md: 0 },
          }}
        >
          <Typography variant="h5" color="#002855" gutterBottom>
            Here to help
          </Typography>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Discover responses to commonly asked questions about our product and
            services.
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <ChatIcon fontSize="small" /> Need more assistance?
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#002855",
              color: "white",
              marginTop: "20px",
              padding: "10px 20px",
              borderRadius: "30px",
            }}
            endIcon={<ExpandMoreIcon />}
          >
            Contact us
          </Button>
        </Box>

        {/* Right Section: FAQ Accordion */}
        <Box sx={{ flex: 2 }}>
          {faqData.map((faq, index) => (
            <Accordion key={index} defaultExpanded sx={{}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6" fontWeight="bold">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>

      <Box
        style={{ padding: "20px", display: "flex", justifyContent: "center" }}
      >
        <Box style={{ maxWidth: "1200px", width: "100%" }}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ mb: 6, mt: 9 }} 
          >
            Testimonials
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  style={{
                    backgroundColor: index === 1 ? "#1e2351" : "#777777",
                    color: "#ffffff",
                    position: "relative",
                    padding: "15px", // Increase padding
                    minHeight: "220px", // Ensure larger height
                    minWidth:"140px"
                  }}
                >
                  <CardContent >
                    <Rating value={testimonial.rating} readOnly />
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ color: "#ffffff" }}
                    >
                      {testimonial.review}
                    </Typography>
                    <Grid container alignItems="center" sx={{mt:4}}>
                      <Avatar alt={testimonial.name} src={testimonial.image} />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "10px", color: "#ffffff" }}
                      >
                        {testimonial.name}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
