import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Box, Badge, Avatar } from "@mui/material";
import NotificationDropdown from "../src/Notification Area/NotificationPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useUser } from "./LoginPage/UserContext";

const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const TopBar = ({ hideIcons, onWalletClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useUser(); //accesing the user data
  const navigate = useNavigate();
  const [firstLetter, setFirstLetter] = useState(""); // State to store the first letter
  const [bgColor, setBgColor] = useState("#FFFFFF"); // Default background color  const [currentPage, setCurrentPage] = useState("dashboard");



  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Check if the user is logged in or a guest
    if (!user) {
      // Redirect to login page if no user is found (i.e., session expired or guest)
      navigate("/Login");
    } else {
      // Set the first letter of the user's first name
      const letter = user.first_name.charAt(0).toUpperCase();
      setFirstLetter(letter);
      console.log("First letter of user's first name:", letter);

      // Generate a color based on the user's first name
      const color = stringToColor(user.first_name);
      setBgColor(color);
    }
  }, [user, navigate]); // This effect runs when `user` or `navigate` changes

  const [isActiveWallet, setIsActiveWallet] = useState(false);

  const onWalletAddressClick = () => {
    setIsActiveWallet(true); // Set wallet as active on click
  };

  const [isActiveOrders, setIsActiveOrders] = useState(false);

  const onOrdersClick = () => {
    setIsActiveOrders(true); // Set wallet as active on click
  };

  return (
    <AppBar
      position="fixed"
      sx={{ bgcolor: "white", width: "100%", overflowX: "auto" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
        }}
      >
        <Box variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          <RouterLink
            to="/Wallet"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <img
              src="/Moconnectblock_pay.jpeg"
              alt="logo"
              style={{ height: 70, cursor: "pointer", marginRight: "8px" }}
            />
            <Typography
              sx={{
                fontSize: { xs: "18px", sm: "18px", md: "20px" },
                textDecoration: "none",
                color: "black",
              }}
            >
              <span style={{ color: "#002855" }}>Moconnect</span>
              <span style={{ color: "#ffa500" }}> Pay</span>
            </Typography>
          </RouterLink>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
              "&:hover": {
                "& .text": { color: "#002855" },
              },
              "@media (max-width: 600px)": {
                marginRight: 0.4,
              },
            }}
          >
            <IconButton
              color="inherit"
              component={RouterLink}
              to="/WalletProfile"
            >
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: bgColor, // Set the background color to match
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {firstLetter || "U"} {/* Display first letter or default */}
              </Avatar>
            </IconButton>
            <Typography
              variant="body2"
              className="text"
              sx={{ color: "rgba(0, 0, 0, 0.65)" }}
            >
              Hi, {user ? user.first_name : "Guest"}
            </Typography>
          </Box>

          {!hideIcons && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: 3,
                  "&:hover": {
                    "& .icon": { color: "#002855" },
                    "& .text": { color: "#002855" },
                  },
                }}
              >
                <IconButton
                  color="inherit"
                   component={RouterLink}
                  to="/Wallet"
                  onClick={onWalletAddressClick}
                >
                  <AccountBalanceWalletIcon
                    className="icon"
                    sx={{
                      color: isActiveWallet ? "#002855" : "rgba(0, 0, 0, 0.65)",
                    }}
                  />
                </IconButton>
                <Typography
                  variant="body2"
                  className="text"
                  sx={{
                    color: isActiveWallet ? "#002855" : "rgba(0, 0, 0, 0.65)",
                    marginTop: 0.1,
                  }}
                >
                  Wallet
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: 3,
                  "&:hover": {
                    "& .icon": { color: "#002855" },
                    "& .text": { color: "#002855" },
                  },
                }}
              >
                <IconButton
                  color="inherit"
                  component={RouterLink}
                  to="/OrdersP2P"
                  onClick={onOrdersClick}
                >
                  <SwapHorizIcon
                    className="icon"
                    sx={{ color: isActiveOrders ? "#002855" : "rgba(0, 0, 0, 0.65)", }}
                  />
                </IconButton>
                <Typography
                  variant="body2"
                  className="text"
                  sx={{ color: isActiveOrders ? "#002855" : "rgba(0, 0, 0, 0.65)", marginTop: 0.1 }}
                >
                  My Orders
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "&:hover": {
                    "& .icon": { color: "#002855" },
                    "& .text": { color: "#002855" },
                  },
                }}
              >
                <IconButton color="inherit" onClick={handleOpen}>
                  <Badge badgeContent={4} color="primary">
                    <NotificationsIcon
                      className="icon"
                      sx={{ color: "rgba(0, 0, 0, 0.65)" }}
                    />
                  </Badge>
                </IconButton>
                <Typography
                  variant="body2"
                  className="text"
                  sx={{ color: "rgba(0, 0, 0, 0.65)", marginTop: 0.1 }}
                >
                  Notifications
                </Typography>
                <NotificationDropdown
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
