import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Stack,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  Link,
} from "@mui/material";
import Logos from "../images/logo.jpg";    //"MoConnect - Secure Escrow Payments, Wallet Transactions, P2P USDT Trading, and Smart Contract Solutions for B2B"
import { Menu } from "@mui/icons-material";
import logos1 from "../images/Moconnectblock_pay.jpeg"; //"MoConnect - Secure Escrow Payments, Wallet Transactions, P2P USDT Trading, and Smart Contract Solutions for B2B"

const Navbar = ({ scrollToSection }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Update nav styling based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Navbar styles change based on scroll state
  const navbarStyles = {
    backgroundColor: isScrolled ? "white" : "#002855",
    color: isScrolled ? "black" : "white",
    position: "fixed", // Fixed to the top
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1000, // On top of content
    justifyContent: "space-between",
    padding: { xs: "0 5px", md: "0 32px" },
    boxShadow: isScrolled ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none", // Shadow only on scroll
    transition: "background-color 0.3s ease-in-out", // Smooth background transition
  };

  // Button styles change based on scroll state
  const buttonStyles = {
    textTransform: "capitalize",
    color: isScrolled ? "#000000" : "#FFFFFF",
    fontFamily: "Poppins",
    backgroundColor: isScrolled ? "white" : "#002855",
    "&:hover": {
    
      color: "#FFA500",
    },

  };

  
  

  const menuItems = (
    <Box sx={{ width: 250 }}>
      <List>
        {[
        
          { text: "Solutions", link: "#solutions" },
          { text: "Community", link: "#community" },
         
          { text: "Partners", link: "#partners" },
          { text: "Developers", link: "#developers" },
        ].map(({ text, link }) => (
          <ListItem button onClick={() => scrollToSection(link)} key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Stack direction="row" spacing={2} sx={{ padding: "16px" }}>
        <Link href="./Signup">
          <Button
            color="primary"
            variant="contained"
            size="small"
            sx={{
              fontFamily: "Poppins",
              backgroundColor: "white",
              color: "black",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          >
            Signup
          </Button>
        </Link>

        <Link href="./Login">
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              fontFamily: "Poppins",
              backgroundColor: "white",
              color: "black",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
              textTransform: "capitalize",
              fontSize: "17px",
            }}
          >
            Login
          </Button>
        </Link>
      </Stack>
    </Box>
  );

  return (
    <AppBar position="fixed" sx={navbarStyles}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center",
           
         }}>
          <Link href="./">
            <img
              src={isScrolled ? logos1 : Logos}
              width="60" // Set a fixed width
              height="60" // Set a fixed height
              alt="Moconnect - Secure Escrow Payments, Wallet Transactions, P2P USDT Trading, and Smart Contract Solutions for B2B"
              
            
            />
          </Link>
          <Typography
            sx={{
              fontSize: { xs: "15px", sm: "18px", md: "20px" },
              textDecoration: "none",
              color: isScrolled ? "black" : "white", // Change on scroll
              display: "flex",
              gap: isMobile ? 0.8 : 2, // Reduce gap between text elements for small screens
            }}
          >
            <span>Moconnect</span>
            <span style={{ color: "#ffa500" }}> Pay</span>
          </Typography>

          {!isMobile && (
            <Stack direction="row" spacing={7} sx={{ marginLeft: 15}}>
              <Button
                color="inherit"
                sx={buttonStyles}
                onClick={() => scrollToSection("#solutions")}
              >
                Solutions
              </Button>
              
                <Button color="inherit" sx={buttonStyles}
                  onClick={() => scrollToSection("#developers")}
                >
                  Developers
                </Button>

                <Button
                color="inherit"
                sx={buttonStyles}
                onClick={() => scrollToSection("#community")}
              >
                Community
              </Button>
              
             

              <Button
                color="inherit"
                sx={buttonStyles}
                onClick={() => scrollToSection("#partners")}
              >
                Partners
              </Button>
            </Stack>
          )}
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ color: "#FFA500" }}
            >
              <Menu />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              {menuItems}
            </Drawer>
          </>
        ) : (
          <Stack direction="row" spacing={2}>
            <Link href="./Signup">
              <Button
                variant="contained"
                size="small"
                sx={{
                  borderRadius: "15px",
                  fontFamily: "Poppins",
                  backgroundColor: "#FFA500",
                  color: "white",
                  padding: "7px",
                  width: "130px",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                Signup
              </Button>
            </Link>

            <Link href="./Login">
              <Button
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: "20px",
                  fontFamily: "Poppins",
                  padding: "7px",
                  width: "130px",
                  color: "#FFA500",
                  borderColor: "#FFA500",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#002855",
                  },
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                Login
              </Button>
            </Link>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
