import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { Popover, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Use MoreHorizIcon for horizontal dots
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate and Link

import ChatIcon from '@mui/icons-material/Chat';
import BuyDialog from "./BuyDialog";
import SellDialog from "./SellDialog";
// Import the flag images
// import flagKES from "../src/images/Kenya.jpeg";
// import flagAED from "../src/images/Uae.jpeg";
// import flagALL from "../src/images/ALL.jpeg";
// import flagZAR from "../src/images/ZAL.jpeg";
// import flagZMW from "../src/images/ZMW.jpeg";
import axios from "axios";
import FAQDialog from "./HelpForm";

const P2PTrading = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [adsData, setAdsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  //filtering ads data

  const [filteredAdsData, setFilteredAdsData] = useState([]);


  useEffect(() => {
    const fetchAdsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("http://127.0.0.1:5000/api/ads");
        setAdsData(response.data);
        // Set initial filtered data to 'Buy' ads
        setFilteredAdsData(
          response.data.filter((ad) => ad.buy_sell.toLowerCase() === "buy")
        );
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching ads data:", error);
        setError("Failed to fetch ads data");
        setIsLoading(false);
      }
    };

    fetchAdsData();
    const interval = setInterval(fetchAdsData, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  const [openBuyDialog, setOpenBuyDialog] = useState(false);
  const [openSellDialog, setOpenSellDialog] = useState(false); // Manage state for SellDialog
  const [selectedAd, setSelectedAd] = useState(null);


  const fetchAdDetails = async (adId) => {
    try {
      const response = await fetch(`http://127.0.0.1:5000/api/ads/${adId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const text = await response.text(); // Read the response as text
  
      try {
        const data = JSON.parse(text); // Try to parse the text as JSON
        return data;
      } catch (error) {
        console.error('Error parsing JSON:', error);
        console.log('Response text:', text);
        throw new Error('Failed to parse response as JSON');
      }
    } catch (error) {
      console.error('Error fetching ad details:', error);
      return null;
    }
  };
  

  

  const handleOpenBuyDialog = async (ad) => {
    console.log('Opening Buy Dialog for Ad:', ad); // Debug log
    const adDetails = await fetchAdDetails(ad.id);
    if (adDetails) {
      setSelectedAd(adDetails);
      setOpenBuyDialog(true);
      console.log('Buy Dialog Open State:', openBuyDialog); // Debug log
    }
  };
  

  const handleCloseBuyDialog = () => {
    setOpenBuyDialog(false);
    setSelectedAd(null);
  };

  const handleOpenSellDialog = async (ad) => {
    console.log('Opening Sell Dialog for Ad:', ad); // Debug log
    const adDetails = await fetchAdDetails(ad.id);
    if (adDetails) {
      setSelectedAd(adDetails);
      setOpenSellDialog(true);
      console.log('Sell Dialog Open State:', openSellDialog); // Debug log
    }
  };
  const handleCloseSellDialog = () => {
    setOpenSellDialog(false);
    setSelectedAd(null);
  };

  const navigate = useNavigate(); // Initialize useNavigate
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    verifiedMerchantsOnly: false,
    makersYouFollow: false,
    makersYouTradedWith: false,
    eligibleAds: false,
    verificationNotRequired: false,
  });

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      [event.target.name]: event.target.checked,
    });
  };
  const handleProfileNavigation = (profileName) => {
    // Navigate to the wallet profile page
    navigate(`/WalletProfile`);
  };

  const isFilterOpen = Boolean(filterAnchorEl);

  const [activeSection, setActiveSection] = useState("buy");
  const [selectedCurrency, setSelectedCurrency] = useState("KES");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    "All payment methods"
  );
  const [selectedSort, setSelectedSort] = useState("Sort by");

  const filtersRef = useRef(null);
  const tableHeadersRef = useRef(null);

  const handleScrollToSection = (section) => {
    setActiveSection(section);
    const filteredAds = adsData.filter(
      (ad) => ad.buy_sell.toLowerCase() === section
    );
    setFilteredAdsData(filteredAds);
    setOpenBuyDialog(false);
    setOpenSellDialog(false);
    setSelectedAd(null);
    filtersRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleSortChange = (event) => {
    setSelectedSort(event.target.value);
  };

  const [openFAQ, setOpenFAQ] = useState(false);

  const handleToggle = () => {
    setOpenFAQ((prevOpen) => !prevOpen);
  };

  const handleCloseFAQ = () => {
    setOpenFAQ(false);
  };
  return (
    <Box sx={{ padding: 4, marginBottom: 10 }}>
      <Box sx={{ marginTop: 7 }}>
        <Typography variant="h6">P2P Trading</Typography>
      </Box>

      {/* Buy and Sell Toggle */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
          marginBottom: 2,
          marginTop: 3,
          gap: 1,
          width: "100%",
        }}
      >
        <Button
          onClick={() => handleScrollToSection("buy")}
          variant={activeSection === "buy" ? "contained" : "outlined"}
          sx={{
            flexGrow: { xs: 1, sm: 0 },
            width: { xs: "100%", sm: "auto" },
            maxWidth: { xs: "150px", sm: "auto" },
            minWidth: "80px",
            backgroundColor:
              activeSection === "buy" ? "rgba(0, 40, 85, 0.8)" : "transparent", // Active background with opacity
            borderColor: activeSection === "buy" ? "transparent" : "#002855", // Border color for inactive
            color: activeSection === "buy" ? "#fff" : "#002855", // Text color for inactive
            "&:hover": {
              backgroundColor:
                activeSection === "buy"
                  ? "rgba(0, 40, 85, 0.8)"
                  : "transparent", // Maintain active hover state
              borderColor: activeSection === "buy" ? "transparent" : "#002855", // Maintain border color for inactive
            },
          }}
        >
          Buy
        </Button>
        <Button
          onClick={() => handleScrollToSection("sell")}
          variant={activeSection === "sell" ? "contained" : "outlined"}
          sx={{
            flexGrow: { xs: 1, sm: 0 },
            width: { xs: "100%", sm: "auto" },
            maxWidth: { xs: "150px", sm: "auto" },
            minWidth: "80px",
            backgroundColor:
              activeSection === "sell" ? "rgba(0, 40, 85, 0.8)" : "transparent", // Active background with opacity
            borderColor: activeSection === "sell" ? "transparent" : "#002855", // Border color for inactive
            color: activeSection === "sell" ? "#fff" : "#002855", // Text color for inactive
            "&:hover": {
              backgroundColor:
                activeSection === "sell"
                  ? "rgba(0, 40, 85, 0.8)"
                  : "transparent", // Maintain active hover state
              borderColor: activeSection === "sell" ? "transparent" : "#002855", // Maintain border color for inactive
            },
          }}
        >
          Sell
        </Button>
      </Box>

      {/* <Divider sx={{ marginBottom: 2 }} /> */}

      {/* Filters and Table Headers */}
      <Box ref={filtersRef} sx={{ display: "flex", flexDirection: "column" }}>
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" }, // Stay in a row for all screens
            justifyContent: "space-between",
            flexWrap: { xs: "nowrap", sm: "nowrap" }, // Wrap on small screens if needed
            marginBottom: 2,
            alignItems: "center",
            gap: 2, // Add gap between elements
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row" },
              gap: 1,
            }}
          >
            <Select
              value="USDT"
              variant="outlined"
              size="small"
              sx={{
                width: { xs: "100px", sm: "120px" }, // Smaller width on small screens
                borderRadius: "4px 0 0 4px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc",
                },
              }}
            >
              <MenuItem value="USDT">USDT</MenuItem>
            </Select>

            <Select
              value={selectedPaymentMethod}
              onChange={handlePaymentMethodChange}
              variant="outlined"
              size="small"
              sx={{
                width: { xs: "120px", sm: "200px" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc",
                },
              }}
            >
              <MenuItem value="All payment methods">
                All payment methods
              </MenuItem>
              <MenuItem value="I&M Bank">I&M Bank</MenuItem>
              <MenuItem value="M-Pesa Paybill">M-Pesa Paybill</MenuItem>
              <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
              <MenuItem value="PayPal">PayPal</MenuItem>
              <MenuItem value="Credit Card">Credit Card</MenuItem>
            </Select>

            <IconButton
              sx={{ border: "1px solid #ccc", borderRadius: 1 }}
              onClick={handleFilterClick}
            >
              <FilterAltOutlinedIcon />
            </IconButton>

            <Popover
              open={isFilterOpen}
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ padding: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.verifiedMerchantsOnly}
                        onChange={handleFilterChange}
                        name="verifiedMerchantsOnly"
                      />
                    }
                    label="Verified Merchants only"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.makersYouFollow}
                        onChange={handleFilterChange}
                        name="makersYouFollow"
                      />
                    }
                    label="Makers you follow"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.makersYouTradedWith}
                        onChange={handleFilterChange}
                        name="makersYouTradedWith"
                      />
                    }
                    label="Makers you've traded with"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.eligibleAds}
                        onChange={handleFilterChange}
                        name="eligibleAds"
                      />
                    }
                    label="Eligible ads"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.verificationNotRequired}
                        onChange={handleFilterChange}
                        name="verificationNotRequired"
                      />
                    }
                    label="Verification not required"
                  />
                </FormGroup>
                <Typography
                  variant="body2"
                  sx={{ color: "grey", marginTop: 1 }}
                >
                  You don’t need to provide additional verification to trade
                </Typography>
              </Box>
            </Popover>
          </Box>

          {/* Sort by Dropdown */}
          <Select
            value={selectedSort}
            onChange={handleSortChange}
            variant="outlined"
            size="small"
            sx={{
              width: { xs: "100px", sm: "200px" }, // Adjust width for small screens
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc",
              },
            }}
          >
            <MenuItem value="Sort by">Sort by</MenuItem>
            <MenuItem value="Price: lowest to highest">
              Price: lowest to highest
            </MenuItem>
            <MenuItem value="Completed Order number">
              Completed Order number
            </MenuItem>
            <MenuItem value="Completed rates">Completed rates</MenuItem>
          </Select>
        </Box>

        {/* Table Headers */}
        <Grid
          container
          sx={{
            paddingY: 1,
            fontWeight: "bold",
            backgroundColor: "white",
            display: { xs: "none", sm: "flex" }, // Hide on small screens, show on medium and up
          }}
        >
          <Grid item xs={3}>
            <Typography sx={{ fontSize: "13px" ,textAlign: "left",  color: "#757575" }}>Advertisers</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "13px" ,textAlign: "left",  color: "#757575" }}>Unit price</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography  sx={{ fontSize: "13px" ,textAlign: "left",  color: "#757575" }}>Available/Order limit</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography  sx={{ fontSize: "13px" ,textAlign: "left",  color: "#757575" }}>Payment methods</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "13px" ,  color: "#757575" }}>
              {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        {/* Ads List */}
        {/* Ads List */}
        <Box>
          {isLoading ? (
            <Typography>Loading ads data...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : filteredAdsData.length === 0 ? (
            <Typography>No ads available for {activeSection}</Typography>
          ) : (
            filteredAdsData.map((ad, index) => (
              <Grid
              container
              key={index}
              sx={{
                borderBottom: "1px solid #ccc",
                paddingY: 1,
                marginBottom: 2,
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid item xs={3}>
                <Typography sx={{ textAlign: "left", padding: 1 }}>{ad.user_id}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ textAlign: "left",  fontSize:"21px", fontWeight:"bold",color: "rgba(0, 0, 0, 0.6)"}}>{ad.unit_price} KES</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ textAlign: "left", padding: 1 }}>
                  {ad.min_order_limit} - {ad.max_order_limit} KES
                </Typography>
                <Typography sx={{ textAlign: "left", padding: 1 }}>{ad.available_quantity}</Typography>
              </Grid>
              <Grid item xs={2}>
              {ad.payment_methods.map((method, idx) => (
            <Typography key={idx}>{method.provider_name}</Typography>
          ))}
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  sx={{
                    width: { xs: "100%", sm: "100px" }, // Consistent width for both sections
                    backgroundColor: activeSection === "buy" ? "#4CAF50" : "#F44336", // Green for buy, red for sell
                    color: "#fff", // White text color for better contrast
                    "&:hover": {
                      backgroundColor: activeSection === "buy" ? "#45a049" : "#e53935", // Darker shade on hover
                    },
                  }}
                  onClick={() =>
                    activeSection === "buy" ? handleOpenBuyDialog(ad) : handleOpenSellDialog(ad)
                  }
                >
                  {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}
                </Button>
              </Grid>
            </Grid>
            ))
          )}
        </Box>
      </Box>

      {/* Add BuyDialog and SellDialog components here */}
      <BuyDialog
        open={openBuyDialog}
        handleClose={handleCloseBuyDialog}
        adDetails={selectedAd} 
      />
      <SellDialog
        open={openSellDialog}
        handleClose={handleCloseSellDialog}
        adDetails={selectedAd} 
      />

      {/* Help Icon positioned absolutely */}
      <IconButton
        sx={{
          position: "fixed",
          bottom: 100,
          right: 50,
          backgroundColor: "orange",
          color: "white",
          zIndex: 1500, // Ensures the icon is visible above the dialog backdrop
          "&:hover": {
            backgroundColor: "orange",
          },
          "@media (max-width: 600px)": {
            bottom: "13%",
            right: "5%",
          },
        }}
        onClick={handleToggle}

      >
        <ChatIcon />
      </IconButton>
       {/* Render the FAQDialog and pass open state and handleClose */}
       <FAQDialog open={openFAQ} handleClose={handleCloseFAQ} />
    </Box>
  );
};

export default P2PTrading;
