import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { keyframes } from '@mui/system';
import PostandAd from "./PostandAd";
import axios from 'axios';

const flicker = keyframes`
  0%, 50% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const WaitingVerification = () => {
  const [status, setStatus] = useState('pending');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem('userId'); // or however you store the user ID

    const fetchStatus = async () => {
      try {
        const response = await axios.get(`/application/${userId}`);
        setStatus(response.data.status);
      } catch (error) {
        console.error('Error fetching application status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();

    const intervalId = setInterval(fetchStatus, 10000); // Fetch status every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  return (
    <Grid>
      {status === 'pending' ? (
        <>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Application Submitted Successfully
          </Typography>

          <Typography variant="body2" sx={{ marginBottom: "20px", color: "black" }}>
            Your application is subjected to approval based on your past trading
            performance. Our customer support will review your submission.
          </Typography>

          <Typography
            variant="h6"
            sx={{
              color: "#002855",
              marginTop: 2,
              marginLeft: 0.4,
              display: "flex",
              gap: 1,
              animation: `${flicker} 1s infinite`,
            }}
          >
            <ErrorIcon
              sx={{
                animation: `${flicker} 1s infinite`,
              }}
            /> 
            Wait for verification...
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            sx={{
              color: "green",
              marginTop: 2,
              marginLeft: 0.4,
              display: "flex",
              gap: 1,
              marginBottom: 2,
            }}
          >
            <CheckCircleIcon /> Successfully verified as a Merchant!
          </Typography>

          <Typography
            sx={{
              marginBottom:"10px"
            }}
          >
            To begin your journey as a Merchant you need to be able to
            sell or buy coins from users in the moconnect pay P2P platform:
          </Typography>

          <PostandAd />
        </>
      )}
    </Grid>
  );
};

export default WaitingVerification;
