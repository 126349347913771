import React from "react";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Divider,
  IconButton,
  useMediaQuery,
  Link,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme } from "@mui/material/styles";

// Sample data
const notifications = [
  {
    date: "Aug 23, 2024",
    title: "New login detected",
    message: `You're logging in using Chrome 127.0.0.0(Linux) in Nairobi County, Kenya. Not you? Go to security center to change your password, remove the device or freeze your account.`,
   
  },
  {
    date: "Jul 10, 2024",
    title: "Your rebate has been credited",
    message: `Your rebate of 4 USDT has been credited to your account. It's valid for 7 days. Complete a trade within the validity period to receive a 100% refund on your trading fee.`,
    
  },
  {
    date: "Jun 20, 2024",
    title: "Special invite just for you!",
    message: `Join our OKX Community Chat Group for exclusive tips and guidance, support, and community. Tap to join now!`,
   
  },
  {
    date: "Jun 17, 2024",
    title: "One last step to complete verification!",
    message: `You're one step away from unlocking more features. Complete identity verification now and take your experience to the next level!`,
    
  },
];

// Notification Item Component
const NotificationItem = ({ date, title, message }) => (
  <Box mb={2}>
    <Grid container spacing={2} alignItems="center">
      {/* Avatar */}
      <Grid item>
        <Avatar />
      </Grid>

      {/* Title and Date on the same line */}
      <Grid item xs>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" color="textPrimary">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {date}
            </Typography>
          </Grid>
          
        </Grid>

        {/* Message below the title and date */}
        <Typography variant="body2" color="textSecondary" style={{ marginTop: 4 }}>
          {message}
        </Typography>
         {/* Optional: Display New badge */}
      
      </Grid>

     
    </Grid>

    <Divider style={{ marginTop: 16 }} />
  </Box>
);

const NotificationDetails = () => {
  const [tabIndex, setTabIndex] = React.useState(0); // Manages the main "Notifications" and "Messages" tabs
  const [subTabIndex, setSubTabIndex] = React.useState(0); // Manages the sub-tabs under "Notifications"

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabIndex(newValue);
  };

  // Filter notifications based on sub-tab selection
  const filteredNotifications = notifications.filter((notification) => {
    if (subTabIndex === 1) return notification.category === "Transactions";
    if (subTabIndex === 2) return notification.category === "Price Alerts";
    if (subTabIndex === 3) return notification.category === "General updates";
    return true; // Show all notifications by default
  });

  return (
    <Box
      p={2}
      bgcolor="background.default"
      minHeight="100vh"
      color="text.primary"
      sx={{ marginTop: 1.5 }}
    >
      {/* Header Section */}
      <Grid
        container
        direction="column"
        spacing={1}
        style={{ marginBottom: 16 }}
      >
        {/* First row: Back button */}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={2}>
            <Link href="/Wallet">
              <IconButton>
                <ArrowBackIosIcon sx={{ marginLeft: 0.2 }} />
              </IconButton>
            </Link>
          </Grid>

          {/* Icons */}
          <Grid item>
            <IconButton>
              <SettingsIcon />
            </IconButton>
          </Grid>
        </Grid>

        {/* Second row: Tabs */}
        <Grid container justifyContent="center">
          <Grid item xs={isSmallScreen ? 12 : 6}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="none"
              textColor="primary"
              variant={isSmallScreen ? "scrollable" : "standard"}
              scrollButtons={isSmallScreen ? "auto" : "off"}
              centered={!isSmallScreen}
            >
              <Tab label="Notifications" />
              <Tab label="Messages" />
            </Tabs>
          </Grid>
        </Grid>
      </Grid>

      {/* Conditionally Render Notifications or Messages */}
      {tabIndex === 0 ? (
        <>
          {/* Sub-tabs for Notifications */}
          <Grid
            container
            justifyContent={isSmallScreen ? "center" : "space-around"}
            spacing={1}
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12}>
              <Tabs
                value={subTabIndex}
                onChange={handleSubTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant={isSmallScreen ? "scrollable" : "fullWidth"} // Adjust for small screens
                scrollButtons={isSmallScreen ? "auto" : "off"} // Show scroll buttons for small screens
                centered={!isSmallScreen}
              >
                <Tab label="All" sx={{ minWidth: 80 }} />
                <Tab label="Transactions" />
                <Tab label="Price Alerts" />
                <Tab label="General Updates" />
              </Tabs>
            </Grid>
          </Grid>

          {/* Notifications Section */}
          <Typography variant="h6" style={{ marginTop: 16 }}>
            {subTabIndex === 0
              ? ""
              : subTabIndex === 1
              ? "Transactions"
              : subTabIndex === 2
              ? "Price Alerts"
              : "General Updates"}
          </Typography>

          {/* Render filtered notifications */}
          {filteredNotifications.length > 0 ? (
            filteredNotifications.map((notification, index) => (
              <NotificationItem key={index} {...notification} />
            ))
          ) : (
            <Typography>No notifications in this category.</Typography>
          )}
        </>
      ) : (
        <>
          {/* Messages Section */}
          <Typography variant="h6" style={{ marginTop: 16 }}>
            Messages Section
          </Typography>
          <Typography variant="body1" style={{ marginTop: 16 }}>
            This is where your messages will appear. You can customize this
            section with your message-related content.
          </Typography>
        </>
      )}

      
    </Box>
  );
};

export default NotificationDetails;
