import React, { useState } from "react";
import {
  Divider,
  Button,
  Typography,
  IconButton,
  Box,
  Badge,
  Link,
} from "@mui/material";
import MetaMaskLogo from "../images/metamask.jpeg";
import CoinbaseLogo from "../images/coinbase.jpeg";
import WalletConnectLogo from "../images/walletconnect.jpeg";
import TopBar from "../TobBar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Link as RouterLink } from "react-router-dom";
import NotificationDropdown from "../Notification Area/NotificationPage";
import { useMediaQuery } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import WalletPopup from "../Wallet Screen/WalletPopup";
import FAQDialog from "../HelpForm";
import ChatIcon from '@mui/icons-material/Chat';

const PopupWallet = ({ open, onClose }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [walletPopupOpen, setWalletPopupOpen] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenWalletPopup = () => {
    setWalletPopupOpen(true);
  };

  const handleCloseWalletPopup = () => {
    setWalletPopupOpen(false);
  };


  const [openFAQ, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseFAQ = () => {
    setOpen(false);
  };

  return (
    <div>
      <TopBar onWalletClick={handleOpenWalletPopup} hideIcons={isSmallScreen} />
      

      {/* Parent Box to center content */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
          padding: isSmallScreen ? 2 : 0,
          boxSizing: "border-box",

          // Add media query for iPhone SE
          "@media (max-width: 375px)": {
            marginTop: "18px", // Adjust this value as needed
            height: "100vh",
            marginBottom: "30px",
          },

          // Adjust for iPhone XR (414x896 pixels)
          "@media (min-width: 414px) and (max-height: 896px)": {
            mt: 2, // Reduce top margin
            mb: 5, // Reduce bottom margin
            height: "100vh", // Ensure content is not stretched to full height
          },

          

          // Adjust for Nest Hub (targeting based on height or a specific width)
          "@media (min-width: 600px) and (max-height: 1024px)": {
            marginTop: "50px", // Adjust this value as needed
          },
        }}
      >
        <Box
          sx={{
            maxWidth: { sm: 400 },
            width: "100%",
            position: "relative", // For positioning the IconButton
            borderRadius: 2,
            overflow: "hidden",
            backgroundColor: "#002855",
            padding: { xs: 2, sm: 3 },
            height: "fit-content", // Ensures height is based on content
          }}
        >
          <Typography
            sx={{
              m: 0,
              p: 2,
              backgroundColor: "#002855",
              color: "white",
              position: "relative",
            }}
            variant="h6"
          >
            Connect to Moconnect
           
          </Typography>

          <Box sx={{ backgroundColor: "#002855", p: { xs: 2, sm: 3 } }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography sx={{ color: "white", textAlign: "center" }}>
                You need a wallet to use the escrow system for easy, secure, and
                fast transactions.
              </Typography>

              <Button
                variant="outlined"
                fullWidth
                startIcon={
                  <img
                    src={MetaMaskLogo}
                    alt="MetaMask"
                    style={{ width: 24, height: 24 }}
                  />
                }
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  borderColor: "white",
                  "&:hover": {
                    borderColor: "#FFA500",
                    backgroundColor: "#002855",
                    color: "white",
                  },
                }}
              >
                MetaMask
              </Button>

              <Button
                variant="outlined"
                fullWidth
                startIcon={
                  <img
                    src={CoinbaseLogo}
                    alt="Coinbase Wallet"
                    style={{ width: 24, height: 24 }}
                  />
                }
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  borderColor: "white",
                  "&:hover": {
                    borderColor: "#FFA500",
                    backgroundColor: "#002855",
                    color: "white",
                  },
                }}
              >
                Coinbase Wallet
              </Button>

              <Button
                variant="outlined"
                fullWidth
                startIcon={
                  <img
                    src={WalletConnectLogo}
                    alt="WalletConnect"
                    style={{ width: 24, height: 24 }}
                  />
                }
                sx={{
                  justifyContent: "flex-start",
                  color: "white",
                  borderColor: "white",
                  "&:hover": {
                    borderColor: "#FFA500",
                    backgroundColor: "#002855",
                    color: "white",
                  },
                }}
              >
                WalletConnect
              </Button>

              <Typography
                variant="body2"
                sx={{ mt: 2, color: "#FFA500", textAlign: "center" }}
              >
                How Moconnect Escrow Works?
              </Typography>

              <Divider
                sx={{ width: "100%", my: 2, backgroundColor: "white" }}
              />

              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center" }}
              >
                OR
              </Typography>

              <Button
                variant="outlined"
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  color: "#FFA500",
                  borderColor: "white",
                  "&:hover": {
                    borderColor: "#FFA500",
                    backgroundColor: "#002855",
                    color: "white",
                  },
                }}
              >
                Don't have a wallet?
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Footer for small screens */}
      {isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            position: "fixed",
            bottom: 0,
            width: "100%",
            bgcolor: "white",
            padding: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
              "&:hover": {
                "& .icon": { color: "#4169E1" },
                "& .text": { color: "#4169E1" },
              },
            }}
          >
            <Link href="/PopupWallet">
              <IconButton color="inherit">
                <AccountBalanceWalletIcon sx={{ color: "black" }} />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ color: "black", marginTop: 0.1 }}
              >
                Wallet
              </Typography>
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
              "&:hover": {
                "& .icon": { color: "#4169E1" },
                "& .text": { color: "#4169E1" },
              },
            }}
          >
            <IconButton color="inherit" component={RouterLink} to="/OrdersP2P">
              <SwapHorizIcon className="icon" sx={{ color: "black" }} />
            </IconButton>
            <Typography
              variant="body2"
              className="text"
              sx={{ color: "black", marginTop: 0.1 }}
            >
              My Orders
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "&:hover": {
                "& .icon": { color: "#4169E1" },
                "& .text": { color: "#4169E1" },
              },
            }}
          >
             <Link href="/FinalNotification">
            <IconButton color="inherit" onClick={handleOpen}>
              <Badge badgeContent={4} color="primary">
                <NotificationsIcon className="icon" sx={{ color: "black" }} />
              </Badge>
            </IconButton>
            </Link>
            <Typography
              variant="body2"
              className="text"
              sx={{ color: "black", marginTop: 0.1 }}
            >
              Notifications
            </Typography>
            <NotificationDropdown
              anchorEl={anchorEl}
              handleClose={handleClose}
            />
          </Box>
        </Box>
      )}

       {/* Help Icon positioned absolutely */}
       <IconButton
        sx={{
          position: "fixed",
          bottom: 100,
          right: 50,
          backgroundColor: "orange",
          color: "white",
          zIndex: 1500, // Ensures the icon is visible above the dialog backdrop
          "&:hover": {
            backgroundColor: "orange",
          },
          "@media (max-width: 600px)": {
            bottom: "13%",
            right: "5%",
          },
        }}
        onClick={handleToggle}

      >
        <ChatIcon />
      </IconButton>
       {/* Render the FAQDialog and pass open state and handleClose */}
       <FAQDialog open={openFAQ} handleClose={handleCloseFAQ} />

      <WalletPopup open={walletPopupOpen} onClose={handleCloseWalletPopup} />
    </div>
  );
};

export default PopupWallet;
