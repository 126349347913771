import React, { useState, useEffect } from "react";
import {
  Avatar,
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/FileCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Logout } from "@mui/icons-material";
import { FaSearch } from "react-icons/fa"; // Import magnifying glass icon
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";


//Function to hash a string and convert it to a hex color
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += (`00${value.toString(16)}`).slice(-2);
  }
  return color;
};
const AccountInfo = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  // State to store user data (firstName, lastName, etc.)
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  // State to store the selected image
  const [selectedImage, setSelectedImage] = useState(null);

  // State for the Dialog (Popup)
  const [openDialog, setOpenDialog] = useState(false);

  // State for the email change dialog
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  const [openPhoneDialog, setOpenPhoneDialog] = useState(false);

  // State to manage email change
  const [email, setEmail] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [loading, setLoading] = useState(false)

  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");

  // Handle phone dialog open/close
  const handlePhoneDialogOpen = () => setOpenPhoneDialog(true);
  const handlePhoneDialogClose = () => setOpenPhoneDialog(false);

  const handlePhoneChange = (event) => setPhone(event.target.value);
  const handlePhoneCodeChange = (event) => setPhoneCode(event.target.value);

  // Handle email dialog open/close
  const handleEmailDialogOpen = () => {
    setOpenEmailDialog(true);
  };

  const handleEmailDialogClose = () => {
    setOpenEmailDialog(false);
  };

  // Handle email change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Handle email code change
  const handleEmailCodeChange = (event) => {
    setEmailCode(event.target.value);
  };

  // State to manage the nickname and edit mode
  const [nickname, setNickname] = useState("marywam");
  const [isEditingNickname, setIsEditingNickname] = useState(false);


  // Handle the file upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the image
      setSelectedImage(imageUrl); // Update the state with the new image URL
    }
  };

  // Handle nickname change
  const handleNicknameChange = (event) => {
    setNickname(event.target.value); // Update the nickname state as the user types
  };

  // Handle Dialog open/close
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // Handle save nickname (when user presses Enter or clicks outside)
  const handleNicknameSave = () => {
    setIsEditingNickname(false); // Exit editing mode and save the nickname
  };



  const [bgColor, setBgColor] = useState("#FFFFFF"); 

  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted
    const fetchUserData = async () => {
      setLoading(true); // Set loading state
      try {
        const accessToken = localStorage.getItem("accessToken");
        console.log("Token being sent:", accessToken);

        if (!accessToken) {
          throw new Error("Token is missing. Please log in again.");
        }

        const response = await axios.get(`${apiUrl}/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        if (isMounted) {
          setUserData(response.data); // Update state if component is still mounted

          // Generate a color based on the user's first name
          const color = stringToColor(response.data.first_name);
          setBgColor(color); // Set the background color
        }
      } catch (error) {
        if (error.response) {
          console.error("Error fetching user data:", error.response.data);
        } else {
          console.error("Error fetching user data:", error.message);
        }
        toast.error("Failed to fetch user data.");
      } finally {
        if (isMounted) {
          setLoading(false); // Set loading to false if component is still mounted
        }
      }
    };

    fetchUserData();

    return () => {
      isMounted = false; // Cleanup function to prevent memory leaks
    };
  }, [apiUrl]);
  

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        toast.success("Logout successful! Redirecting to login...");
        // Redirect to Login screen after successful logout
        setTimeout(() => {
          navigate("/login");
        }, 5000); // Adjust delay if needed
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || "Logout failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      toast.error(
        `Error: ${error.message || "There was an issue logging out."}`
      );
    }
  };

  return (
    <Box sx={{ padding: "24px" }}>
      <ToastContainer />
      

      <Grid container spacing={3}>
        
       

        {/* Account Info */}
        <Grid item xs={12} sm={9}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", ml: 0, mt: 1 }}
          >
            Account Info
          </Typography>
          <Grid item xs={12} sm={3}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        {/* Circle with first letter */}
        <Box
          sx={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            backgroundColor: bgColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "40px", // Adjust font size as needed
            color: "white", // Text color
          }}
        >
          {userData.first_name ? userData.first_name.charAt(0).toUpperCase() : "?"}
        </Box>

        {/* Hidden input for file upload */}
        <input
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          id="upload-photo"
          onChange={handleImageUpload} // Handle the image upload
        />

        {/* Edit Icon inside the Avatar, positioned at the lower-right corner */}
        <label htmlFor="upload-photo">
          <IconButton
            component="span"
            sx={{
              position: "absolute",
              top: 70,
              left: 50,
              backgroundColor: "white",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
              transform: "translate(25%, 25%)",
            }}
          >
            <EditIcon sx={{ fontSize: 20, color: "red" }} />
          </IconButton>
        </label>
      </Box>
    </Grid>
          {/* Personal Info */}
          <Box mt={3}>
            {/* <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Personal info
            </Typography> */}

            {/* Nickname */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography ant="body1" sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize:"12px"}}>
                Full Name
              </Typography>
            </Grid>

            <Typography variant="subtitle1" gutterBottom>
            {userData.first_name} {userData.last_name}
            </Typography>

            
            {/* Divider */}
            <Box sx={{ borderBottom: "1px solid #e0e0e0", my: 2 }}></Box>
          </Box>

          {/* Verification Info */}
          <Box mt={3}>
            <Typography
              variant="h6"
              sx={{fontWeight:"bold", mb: 2 }}
              gutterBottom
            >
              Verification Info
            </Typography>

            {/* Identity Verification */}
<Grid container justifyContent="space-between" alignItems="center">
    <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize:"12px", mb: 2 }}>
        Identity Verification
    </Typography>
</Grid>
<Typography variant="subtitle1" gutterBottom>
    <Box display="flex" alignItems="center">
        {userData.is_verified ? (
            <>
                <CheckCircleIcon sx={{color:"#002855"}}/>
                <Box ml={1} mr={1}>
                    Verified
                </Box>
            </>
        ) : (
            <>
                <CancelIcon color="error" />
                <Box ml={1} mr={1}>
                    Not Verified
                </Box>
            </>
        )}
    </Box>
</Typography>

            {/* Divider */}
            <Box sx={{ borderBottom: "1px solid #e0e0e0", my: 2 }}></Box>

            {/* Country/Region */}

          </Box>
          {/* Divider */}
          
          {/* Account Details */}
          <Box mt={3}>
            <Typography
              variant="h6"
              sx={{fontWeight:"bold", mb: 2 }}
              gutterBottom
            >
              Account Details
            </Typography>

            {/* Email */}
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="body1" sx={{ fcolor: "rgba(0, 0, 0, 0.7)", fontSize:"12px", mb: 2 }}>
                Email
              </Typography>
              <Button size="small" onClick={handleEmailDialogOpen}>
                Edit
              </Button>
            </Grid>
            <Typography variant="subtitle1" gutterBottom>
            {userData.email}
            </Typography>

            {/* Dialog for changing email */}
            <Dialog open={openEmailDialog} onClose={handleEmailDialogClose}>
              <DialogTitle>
                Change email
                <IconButton
                  aria-label="close"
                  onClick={handleEmailDialogClose}
                  sx={{ position: "absolute", right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="New email address"
                  value={email}
                  onChange={handleEmailChange}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    label="email authentication"
                    value={emailCode}
                    onChange={handleEmailCodeChange}
                    sx={{
                      width: 450,
                    }}
                  />
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{
                      color: "black",
                      borderColor: "yellow",
                      backgroundColor: "yellow",
                      textTransform: "none", // Keep the text case as is
                      marginTop: 1.5,
                      width: 80,
                      padding: "10px 10px", // Adjust padding for small size
                      fontSize: "0.70rem", // Smaller font size
                      "&:hover": {
                        backgroundColor: "yellow", // Keep yellow background on hover
                        borderColor: "yellow",
                      },
                    }}
                  >
                    Send code
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEmailDialogClose} color="primary">
                  Cancel
                </Button>
                <Button color="primary">Confirm</Button>
              </DialogActions>
            </Dialog>
            {/* Divider */}
            <Box sx={{ borderBottom: "1px solid #e0e0e0", my: 2 }}></Box>
            {/* Phone */}
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize:"12px", mb: 2 }}>
                Phone
              </Typography>
              <Button size="small" onClick={handlePhoneDialogOpen}>
                Edit
              </Button>
            </Grid>
            <Typography variant="subtitle1" gutterBottom>
            {userData.phone}
            </Typography>

            {/* Dialog for changing phone */}
            <Dialog open={openPhoneDialog} onClose={handlePhoneDialogClose}>
              <DialogTitle>
                Change phone number
                <IconButton
                  aria-label="close"
                  onClick={handlePhoneDialogClose}
                  sx={{ position: "absolute", right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="New phone number"
                  value={phone}
                  onChange={handlePhoneChange}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    label="Phone authentication"
                    value={phoneCode}
                    onChange={handlePhoneCodeChange}
                    sx={{ width: 450 }}
                  />
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{
                      color: "black",
                      borderColor: "yellow",
                      backgroundColor: "yellow",
                      textTransform: "none",
                      marginTop: 1.5,
                      width: 80,
                      padding: "10px 10px",
                      fontSize: "0.70rem",
                      "&:hover": {
                        backgroundColor: "yellow",
                        borderColor: "yellow",
                      },
                    }}
                  >
                    Send code
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handlePhoneDialogClose} color="primary">
                  Cancel
                </Button>
                <Button color="primary">Confirm</Button>
              </DialogActions>
            </Dialog>

           

            {/* Divider */}
            <Box sx={{ borderBottom: "1px solid #e0e0e0", my: 2 }}></Box>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mb: 2, color: "red" }}
              >
                Logout
              </Typography>
              <Button size="small" onClick={handleLogout} sx={{ color: "red" }}>
                <Logout />
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountInfo;
