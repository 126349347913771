import React from "react";
import { Box, Typography, Link, Grid, IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const FooterHome = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#002855",
        color: "#fff",
        padding: "10px 8px",
      }}
    >
      <Grid container spacing={0}>
        {/* MoConnect Section */}
        <Grid item xs={12} sm={4} sx={{ textAlign: "left" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              ml: 20,
              "@media (max-width: 600px)": {
                ml: 1,
              },
            }}
          >
            Moconnect Pay
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: "10px",
              ml: 20,
              "@media (max-width: 600px)": {
                ml: 1,
              },
            }}
          >
            Moconnect Pay empowers companies to manage their finances efficiently while providing a safe environment for all types of transactions. Experience the convenience and security of Moconnect Pay as you navigate the digital economy with confidence.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginTop: "15px",
              ml: 20,
              "@media (max-width: 600px)": {
                ml: 1,
              },
            }}
          >
            <IconButton sx={{ color: "#fff" }}>
              <InstagramIcon />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <TwitterIcon />
            </IconButton>
           
              <IconButton sx={{ color: "#fff" }}>
                <LinkedInIcon />
              </IconButton>
            
          </Box>
        </Grid>

        {/* Links Section */}
        <Grid item xs={12} sm={4} sx={{ textAlign: "left" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              ml: 20,
              "@media (max-width: 600px)": {
                ml: 1,
                mt: 2,
              },
            }}
          >
            LINKS
          </Typography>
          <Box
            sx={{
              marginTop: "10px",
              ml: 20,
              "@media (max-width: 600px)": {
                ml: 1,
              },
            }}
          >
            <Link
              href="#solutions"
              underline="none"
              sx={{ display: "block", color: "#fff", marginTop: "8px" }}
            >
              Solutions
            </Link>
            <Link
              href="#community"
              underline="none"
              sx={{ display: "block", color: "#fff", marginTop: "8px" }}
            >
              Community
            </Link>
            <Link
              href="#partners"
              underline="none"
              sx={{ display: "block", color: "#fff", marginTop: "8px" }}
            >
              Partners
            </Link>
            <Link
              href="#developers"
              underline="none"
              sx={{ display: "block", color: "#fff", marginTop: "8px" }}
            >
              Developers
            </Link>
          </Box>
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} sm={4} sx={{ textAlign: "left" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              "@media (max-width: 600px)": {
                ml: 1,
                mt: 4,
              },
            }}
          >
            CONTACT
          </Typography>
          <Box
            sx={{
              marginTop: "10px",
              "@media (max-width: 600px)": {
                ml: 1,
              },
            }}
          >
            <Typography variant="body2">
              19, King Serenity,Rongai Nairobi-Kenya
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "8px" }}>
              +254 798592832
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "8px" }}>
              moconnect@gmail.com
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Footer Bottom */}
      <Box
        sx={{
          textAlign: "center",
          marginTop: "30px",
          borderTop: "1px solid #fff",
          paddingTop: "20px",
        }}
      >
        <Typography variant="body2" sx={{ color: "#fff" }}>
          © 2024 Moconnect Pay. All Rights Reserved.{" "}
          <Link href="#" underline="always" sx={{ color: "#fff" }}>
            Terms & Conditions
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterHome;
