import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import customer1 from "../images/customer1.png";
import customer2 from "../images/customer_talk.mp4";
import customer6 from "../images/customer6.png";

function Community(){

    return(
      <Box sx={{mt: 20 , px: { xs: 2, md: 0 }}}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textAlign: "center", mb: 3 ,
            "@media (max-width: 600px)": {
          textAlign:"left",
          ml: 2
          
          
        },
          }}
        >
          Empowering a Diverse Network of Satisfied Users
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, ml: 10, mr: 10, 
            mx: { xs: 2, sm: 6, md: 20 }, // Use mx for both left and right margins
            textAlign: { xs: "left", md: "left" }, // Center text on small screens

        }}>
          Our platform brings together a diverse community of individuals,
          freelancers, and businesses, all united by the need for secure,
          trustworthy transactions. We’re more than just a service—we’re a
          network of people who value peace of mind, reliability, and support.
          Together, we’re building a space where everyone can feel confident and
          empowered to transact safely, no matter their needs.
        </Typography>
        {/* Image Grid Section */}
        <Grid container spacing={2} justifyContent="center">
        <Grid item xs={3} sm={3} md={2}>
            <img src={customer1} alt="User 1" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <video controls style={{ width: "100%", borderRadius: "10px" }}>
              <source src={customer2} type="video/mp4" playsInline />{" "}
            </video>
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <img src={customer1} alt="User 3" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <img src={customer6} alt="User 4" style={{ width: "100%" }} />
          </Grid>

         
        </Grid>
        
      </Box>
    )
}

export default Community;