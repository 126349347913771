import React from 'react';

const PaymentDetails = ({ details }) => {
  return (
    <div>
      {details.map((detail, index) => (
        <div key={index}>{detail}</div>
      ))}
    </div>
  );
};

export default PaymentDetails;