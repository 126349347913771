import {
    Box,
    Typography,
    Grid,
    
  } from "@mui/material";
  import React, {useState} from "react";
  import {
    Lock,
    Gavel,
    Handshake,
    Autorenew,
    Groups,
    Payments,
  } from "@mui/icons-material";


  function UserCentric(){
   
    return(
<Box sx={{mt: 10,  px: { xs: 2, md: 0 }}}>
        {/* User-Centric Section */}
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4, textAlign:"center" }}>
          Make every step user-centric
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 , textAlign:"center"}}>
          We are here for you. Our goal is to make every interaction with our platform a positive one.
        </Typography>

        {/* First Row */}
        <Grid
          container
          spacing={2} // Adds space between items
          justifyContent="center"
          sx={{ position: "relative", maxWidth: "1000px", margin: "0 auto" }} // Limit width for centering
        >
          {/* Payment Security */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderRight: { xs: "none", md: "1px solid #e0e0e0" },
              borderBottom: "1px solid #e0e0e0",
              py: 5, // Reduced padding
            }}
          >
            <Lock sx={{ fontSize: 50, color: "#FFB300" }} />
            <Typography variant="h6" sx={{ fontWeight: "bold", my: 2 }}>
              Payment Security
            </Typography>
            <Typography variant="body2">
            Instills confidence and peace of mind in users, knowing their transactions are
             safeguarded through reliable mechanisms and transparent processes.
            </Typography>
          </Grid>
          {/* Dispute Resolution */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderRight: { xs: "none", md: "1px solid #e0e0e0" },
              borderBottom: "1px solid #e0e0e0",
              py: 5,
            }}
          >
            <Gavel sx={{ fontSize: 50, color: "#FFB300" }} />
            <Typography variant="h6" sx={{ fontWeight: "bold", my: 2 }}>
              Dispute Resolution
            </Typography>
            <Typography variant="body2">
              Provides a structured process for resolving disputes.
            </Typography>
          </Grid>
          {/* Trust Building */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderBottom: "1px solid #e0e0e0",
              py: 5,
            }}
          >
            <Handshake sx={{ fontSize: 50, color: "#FFB300" }} />
            <Typography variant="h6" sx={{ fontWeight: "bold", my: 2 }}>
              Trust Building
            </Typography>
            <Typography variant="body2">
            By utilizing escrow payments to ensure funds are securely held until both parties fulfill their obligations, thereby fostering confidence 
            in wallet transactions and enhancing the reliability of P2P trading (USDT). 
            </Typography>
          </Grid>
        </Grid>

        {/* Second Row */}
        <Grid
          container
          spacing={2} // Adds space between items
          justifyContent="center"
          sx={{ position: "relative", maxWidth: "1000px", margin: "0 auto" }}
        >
          {/* Automated Workflows */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderRight: { xs: "none", md: "1px solid #e0e0e0" },
              py: 5, // Reduced padding
            }}
          >
            <Autorenew sx={{ fontSize: 50, color: "#FFB300" }} />
            <Typography variant="h6" sx={{ fontWeight: "bold", my: 2 }}>
              Automated Workflows
            </Typography>
            <Typography variant="body2">
            Users feel empowered and secure, trusting that escrow payments are processed accurately through smart contracts. This reliability boosts confidence in wallet transactions and P2P USDT trading, as the platform prioritizes transparency and reduces errors, supported by automated workflows.
            </Typography>
            
          </Grid>

          {/* Multi-Party Transactions */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderRight: { xs: "none", md: "1px solid #e0e0e0" },
              py: 5,
            }}
          >
            <Groups sx={{ fontSize: 50, color: "#FFB300" }} />
            <Typography variant="h6" sx={{ fontWeight: "bold", my: 2 }}>
              Multi-Party Transactions
            </Typography>
            <Typography variant="body2">
            By leveraging escrow payments, ensuring that funds are securely held until all 
            involved parties fulfill their obligations.
            </Typography>
          </Grid>
          {/* Flexibility in Payments */}
          <Grid item xs={12} md={4} sx={{ py: 2 }}>
            <Payments sx={{ fontSize: 50, color: "#FFB300" }} />
            <Typography variant="h6" sx={{ fontWeight: "bold", my: 2 }}>
              Flexibility in Payments
            </Typography>
            <Typography  variant="body2" sx={{marginTop:2}}>
            Moconnect enhances payment flexibility by offering a range of options, including secure escrow payments, direct bank transfers, credit and debit card payments, and digital wallets. Users can easily manage their funds through wallet transactions for quick access and transfers, while P2P USDT trading empowers individuals to buy and sell cryptocurrencies according to their needs.
            </Typography>
            
          </Grid>
        </Grid>
      </Box>
    )
  }


  export default UserCentric;