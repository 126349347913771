import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
  createTheme,
  ThemeProvider
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

// Create a theme to include primary and secondary colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // Primary color
    },
    secondary: {
      main: '#f50057', // Secondary color
    },
  },
});

const OrderDetailsSellPopup = ({ open, handleClose }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle the confirm button click
  const handleConfirmClick = () => {
    // Close the dialog
    handleClose();
    // Redirect to the payment page
    navigate('/ConfirmPaymentSell');
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogContent>
          <Typography variant="h6" align="center">
            Order details
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <Typography>Unit price</Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography>129.59 KES (28s)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Quantity</Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography>1.54 USDT</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Order amount</Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography>200.00 KES</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Payment method</Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography>M-PESA Safaricom</Typography>
            </Grid>
          </Grid>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
          <Button onClick={handleConfirmClick} color="primary"> {/* Updated to handleConfirmClick */}
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default OrderDetailsSellPopup;
