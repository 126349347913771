import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Button,
  MenuItem,
  Grid,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import OrderDetailsPopup from "./OrderDetails";
import { useNavigate } from 'react-router-dom';


const BuyDialog = ({ open, handleClose, adDetails }) => {
  const [isFocusedPay, setIsFocusedPay] = useState(false);
  const [valuePay, setValuePay] = useState("");
  const [isFocusedReceive, setIsFocusedReceive] = useState(false);
  const [valueReceive, setValueReceive] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false); // State to control the popup

  // Extract min and max order limits
  const minOrderLimit = adDetails?.min_order_limit || 0; // Default to 0 if not available
  const maxOrderLimit = adDetails?.max_order_limit || Infinity; // Default to Infinity if not available

  useEffect(() => {
    if (valuePay && adDetails?.unit_price) {
      // Check if valuePay and unit_price are available
      const conversionRate = parseFloat(adDetails.unit_price); // Fetch the conversion rate from adDetails
      const convertedValue = (parseFloat(valuePay) / conversionRate).toFixed(2); // Perform the conversion
      setValueReceive(convertedValue); // Set the converted value
    } else {
      setValueReceive(""); // Reset if no valuePay or unit_price
    }
  }, [valuePay, adDetails]); // Add adDetails to dependencies to trigger effect on change

  const handleFocusPay = () => setIsFocusedPay(true);
  const handleBlurPay = () => setIsFocusedPay(false);

  const handleFocusReceive = () => setIsFocusedReceive(true);
  const handleBlurReceive = () => setIsFocusedReceive(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!valuePay || !valueReceive) {
      setError("Please enter a valid amount in KES to convert to USDT.");
      return;
    }

    if (!paymentMethod) {
      setError("Please select a payment method before proceeding.");
      return;
    }

    setError("");
    navigate('/ConfirmPayment');
    // setShowPopup(true); // Show the popup when the button is clicked
  };

  const handlePopupClose = () => setShowPopup(false);

  useEffect(() => {
    console.log("adDetails:", adDetails);
    console.log("Payment methods:", adDetails?.payment_methods);
  }, [adDetails]);

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
    console.log("Selected payment method:", event.target.value);
  };

  const handleValuePayChange = (e) => {
    const value = e.target.value;
    // Ensure the value is a valid number
    if (!isNaN(value) && value !== "") {
      const numericValue = parseFloat(value);
      // Only update if within the order limits
      if (numericValue >= minOrderLimit && numericValue <= maxOrderLimit) {
        setValuePay(value);
        setError(""); // Clear error if valid
      } else {
        setError(
          `Please enter an amount between ${minOrderLimit} KES and ${maxOrderLimit} KES.`
        );
      }
    } else {
      setValuePay(value);
    }
  };

  const getProviderName = (methodId) => {
    const method = adDetails.payment_methods.find((m) => m.id === methodId);
    return method ? method.provider_name : `Unknown Method (${methodId})`;
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h6" align="center">
            Trading Information
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="row" p={2}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <PersonIcon style={{ fontSize: 50, color: "#3f51b5" }} />
              <Typography variant="body1">bri***@gmail.com</Typography>
              <Box display="flex" alignItems="center">
                <CheckCircleIcon style={{ fontSize: 16, color: "#002855" }} />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginLeft: 4 }}
                >
                  ID verified
                </Typography>
              </Box>

              <Typography variant="body2" mt={2}>
                Completed orders: 22
              </Typography>
              <Typography variant="body2">Completion rate: 100%</Typography>
              <Typography variant="body2">Payment window: 15 mins</Typography>
              <Typography variant="body2">Name: K**</Typography>
              <Typography
                variant="body2"
                mt={2}
                sx={{ color: "#002855", fontSize: "11px" }}
              >
                Ensure your payment account name matches your Moconnect Pay name
              </Typography>
            </Box>
            <Box flex={1} display="flex" flexDirection="column" p={2}>
              <Typography variant="body2" mb={1}>
                Unit price:{" "}
                <strong>
                  {adDetails ? adDetails.unit_price : "N/A"} KES (38s)
                </strong>
              </Typography>
              <Grid container spacing={2} mt={0.6}>
                <Grid item xs={16}>
                  <TextField
                    label={isFocusedPay ? "" : "You pay"}
                    variant="outlined"
                    fullWidth
                    placeholder={isFocusedPay ? "0.00" : ""}
                    value={valuePay}
                    onFocus={handleFocusPay}
                    onBlur={handleBlurPay}
                    onChange={handleValuePayChange} // Use the new handler
                    InputProps={{
                      endAdornment: isFocusedPay ? (
                        <InputAdornment position="end"></InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <Typography>KES</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error && (
                    <Typography variant="body2" color="error">
                      {error}
                    </Typography>
                  )}
                </Grid>
                <Typography
                  variant="body2"
                  sx={{
                    color: "grey",
                    ml: 3,
                    mt: 1,
                  }}
                >
                  Order limit: {minOrderLimit} - {maxOrderLimit} KES
                </Typography>
                <Grid item xs={16}>
                  <TextField
                    label={isFocusedReceive ? "" : "You receive"}
                    placeholder={isFocusedReceive ? "0.00" : ""}
                    variant="outlined"
                    fullWidth
                    value={valueReceive}
                    onFocus={handleFocusReceive}
                    onBlur={handleBlurReceive}
                    InputProps={{
                      readOnly: true,
                      endAdornment: isFocusedReceive ? (
                        <InputAdornment position="end"></InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <Typography>USDT</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                select
                label="Select payment method"
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                fullWidth
                variant="outlined"
                sx={{ mt: 2 }}
              >
                {Array.isArray(adDetails?.payment_methods) &&
                adDetails.payment_methods.length > 0 ? (
                  adDetails.payment_methods.map((method, idx) => (
                    <MenuItem key={idx} value={method}>
                      Payment Method {method}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No payment methods available</MenuItem>
                )}
              </TextField>

              {error && (
                <Typography color="error" variant="body2" mt={1}>
                  {error}
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                sx={{
                  mt: 2,
                  backgroundColor: "rgba(0, 40, 85, 0.8)",
                  "&:hover": {
                    color: "#FFA500",
                    backgroundColor: "#002855",
                    transform: "translateY(-2px)", // Slight lift effect on hover
                    transition: "0.2s",
                  },
                }}
              >
                Buy USDT with 0 fees
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Order Details Popup */}
      <OrderDetailsPopup open={showPopup} handleClose={handlePopupClose} />
    </>
  );
};

export default BuyDialog;
