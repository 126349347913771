import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import React from "react";

const logos = [
    {
      id: 1,
      name: "Mastercard",
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg",
    },
    {
      id: 2,
      name: "Safaricom",
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg",
    },
    {
      id: 3,
      name: "BasiGo",
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg",
    },
    {
      id: 4,
      name: "Mastercard",
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg",
    },
    {
      id: 5,
      name: "Mastercard",
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg",
    },
    {
      id: 6,
      name: "Mastercard",
      imgSrc:
        "https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg",
    },
  ];

function Partners (){

    return(
        <Box sx={{ py: 3, mb: 8 }}>
        {/* Title */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", mb: 7, mt: 3 }}
        >
          Be Part Of Us
        </Typography>

        {/* Horizontal Scroll Container */}
        <Box
          sx={{
            display: "flex",
            overflowX: "hidden",
            ml: 10,
            px: 2,
            "@media (max-width: 600px)": {
              ml: 2,
              overflowX:"scroll"
            },
          }}
        >
          {/* Logo Cards */}
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexWrap: "nowrap" }}
          >
            {logos.map((logo) => (
              <Grid item key={logo.id} sx={{ minWidth: "200px" }}>
                <Card sx={{ textAlign: "center", padding: "10px" }}>
                  <img
                    src={logo.imgSrc}
                    alt={logo.name}
                    style={{ width: "80px", margin: "auto" }}
                  />
                  <CardContent>
                    <Typography variant="body1">{logo.name}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    )
}

export default Partners;