import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Link,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink } from "react-router-dom";
const Dispute = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [description, setDescription] = useState(""); // To store the description field
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false); // To handle the success dialog popup

  // File upload handling
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Add new files to the state
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles); // Remove the file at the selected index
  };

  // Form submission handling
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Check if the description field is empty or no files have been selected
    if (!description || selectedFiles.length === 0) {
      setErrorMessage("Please fill all the fields, including uploading at least one file.");
      return;
    }
  
    // If both description and files are filled, show success dialog
    setErrorMessage(""); // Clear any error message
    setOpen(true); // Open the success dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    
    <Container
      maxWidth="sm"
     sx={{
      marginBottom:"80px"
     }}
    >
      <Box sx={{ display: "flex", mb: 1, mt: 11, ml:1 }}>
        <ArrowBackIcon sx={{ ml: 0, mr: 1, fontSize:"1.2rem", mt:0.5,  color:"#002855" }} />
        <Link
          component={RouterLink}
          to="/OrdersP2P"
          underline="none" // Start with no underline
          sx={{
            cursor: "pointer",
            fontSize: "1.2rem",
            color: "#002855",
            textDecoration: "none",
            "&:hover": {
              color: "#002855", // Change text color to blue on hover
              textDecoration: "underline", // Underline text on hover
            },
          }}
        >
          Back
        </Link>
      </Box>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        {/* Title */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          Raise a Dispute
        </Typography>

       

        {/* Form */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                marginTop: "10px",
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Description of dispute
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: "4px", color: "black" }}
            >
              Typically initiated by one party (either the buyer or seller) when
              they believe the other party has not fulfilled the transaction
              terms.
            </Typography>
            <TextField
              fullWidth
              required
              autoFocus
              multiline
              rows={4}
              variant="outlined"
              value={description} // Bind the value to state
              onChange={(e) => setDescription(e.target.value)} // Update the state on change
              sx={{
                borderRadius: "10px",
                marginTop: "10px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mt: 2, mb: 4 }}>
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                Supporting evidence (images, videos, documents)
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "4px", color: "black" }}>
                Upload supporting evidence for the dispute.
              </Typography>
              <Box
                sx={{
                  border: "1px dashed grey",
                  borderRadius: "8px",
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  mt: 2,
                  mb: 2,
                }}
              >
                <input
                  accept="image/*,video/*,.pdf,.doc,.docx"
                  id="file-upload"
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <label htmlFor="file-upload">
                  <IconButton component="span">
                    <AddCircleOutline sx={{ fontSize: 40, color: "grey" }} />
                  </IconButton>
                </label>
              </Box>

              <Grid container spacing={2}>
                {selectedFiles.map((file, index) => (
                  <Grid item key={index}>
                    <Box
                      sx={{
                        border: "1px dashed grey",
                        borderRadius: "8px",
                        position: "relative",
                        padding: "8px",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {file.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "4px",
                          }}
                        />
                      ) : (
                        <Typography variant="caption">{file.name}</Typography>
                      )}
                      <Button
                        size="small"
                        onClick={() => handleRemoveFile(index)}
                        sx={{
                          position: "absolute",
                          top: "-20px",
                          right: "-15px",
                          backgroundColor: "white",
                          color: "#002855",
                          borderRadius: "50%",
                          border: "1px solid grey",
                          padding: "5px",
                          minWidth: "30px",
                          marginTop: "10px",
                        }}
                      >
                        X
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Error Message */}
        {errorMessage && (
          <Alert severity="error" sx={{ marginBottom: "10px" }}>
            {errorMessage}
          </Alert>
        )}

            {/* Submit Button */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                backgroundColor: "#002855",
                color: "white",
                "&:hover": {
                  backgroundColor: "#002855",
                  color: "#FFA500",
                },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Success Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Submission Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your dispute has been submitted successfully. We will get back to you shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Dispute;
