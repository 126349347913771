import React, { useEffect, useState, useRef } from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import { motion } from "framer-motion";
import image from "../images/cover11.png"; // Example image path
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Animation variants for Framer Motion
const image1Variants = {
  hidden: { opacity: 0, scale: 0.8 }, // Initial hidden state (scaled down)
  visible: { opacity: 1, scale: 1 }, // Fully visible state (normal size)
};

function TopBlack() {
  const [isImage1Visible, setIsImage1Visible] = useState(false);
  const image1Ref = useRef(null); // Reference for the image

  // Intersection Observer to detect visibility of the image
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsImage1Visible(true); // Trigger animation when image is visible
          } else {
            setIsImage1Visible(false); // Optional: reset animation when out of view
          }
        });
      },
      { threshold: 0.2 } // Trigger when 20% of the image is visible
    );

    if (image1Ref.current) {
      observer.observe(image1Ref.current);
    }

    // Cleanup observer
    return () => {
      if (image1Ref.current) {
        observer.unobserve(image1Ref.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover", // Ensures the image covers the entire box
        backgroundPosition: "center", // Centers the background image
        backgroundRepeat: "no-repeat", // Prevents the background image from repeating
        backgroundColor: "#002855", // Fallback color
        color: "#FFFFFF",
        py: 7,
        position: "relative",
        mt: 5,
        height: "70vh", // Use height for large screens
        overflowX: "hidden", // Prevent horizontal scrolling or extra white space
        width: "100vw", // Ensure it spans the entire viewport width
        "@media (max-width: 600px)": {
          height: "auto", // Adjust height for small screens
        },
      }}
    >
      {/* Top Black Section */}
      <Grid
        container
        spacing={0}
        sx={{
          width: "100%", // Ensure the grid spans 100% of the viewport
          margin: 0, // Avoid extra margins
          padding: 0, // Remove any unintended padding
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "70%", // Full height on large screens
            "@media (max-width: 600px)": {
              height: "auto", // Adjust height for small screens
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              pl: 2,
              ml: 4,
              marginTop: "10%",
            }}
          >
            Experience Peace of Mind with Every Transaction
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              pl: 2,
              ml: 4,
              mt: 4,
            }}
          >
           Moconnect Pay provides secure escrow payments, seamless wallet transactions, 
           and efficient P2P trading of USDT, powered by smart contracts for transparent business dealings.
            Our platform ensures peace of mind by simplifying digital transactions and fostering trust between businesses.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgba(0, 40, 85, 0.8)", // Match the button background to the darker part of the gradient
              color: "#FFFFFF", // White text for better contrast
              fontWeight: 800,
              fontSize: { xs: "0.8rem", sm: "0.9rem" }, // Adjust font size for mobile responsiveness
              boxShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
              "&:hover": {
                color: "#FFA500",
                backgroundColor: "#002855",
                transform: "translateY(-2px)", // Slight lift effect on hover
                transition: "0.2s",
              },
              mb: 4,
              ml: 6,
              width: { xs: "60%", sm: "60%", md: "30%" }, // Responsive width
              height: { xs: "40px", sm: "50px", md: "60px" }, // Responsive height
              marginTop: "2%",
              "@media (max-width: 600px)": {
                // Additional styles for small screens, if needed
              },
            }}
            endIcon={<ArrowForwardIcon />}
          >
            Transact Now
          </Button>
        </Grid>
        {/* Animated Circular Image */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 4,
            mt: 4,
          }}
        >
          <motion.div
            ref={image1Ref} // Attach reference for observing visibility
            variants={image1Variants} // Animation states
            initial="hidden" // Initial state
            animate={isImage1Visible ? "visible" : "hidden"} // Animate based on visibility
            transition={{ duration: 0.6, ease: "easeOut" }} // Control animation speed
          >
            {/* Keeping image size as original */}
            {/* <img src={image} width="100%" height="100%" alt="Company Logo"
            sx={{
              "@media (max-width: 600px)": {
                
                width: "60%",
                height:"60%"
              },
            }}
            /> */}
          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TopBlack;
