import React, { useState } from 'react';
import { useMediaQuery, Modal, Backdrop, Fade, TextField, Button } from '@mui/material';
import TopBar from '../src/TobBar';
import Walletbalance from '../src/Wallet Screen/WalletBalance';
import Footer from '../src/Bottombar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import '../src/send.css'

const Send = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className={modalOpen ? 'blur-background' : ''}>
            <TopBar hideIcons={isSmallScreen} />
            <Walletbalance />
            {!isSmallScreen && <Footer />}
            {isSmallScreen && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        bgcolor: '#002855',
                        padding: 1,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 3 }}>
                        <IconButton color="inherit">
                            <AccountBalanceWalletIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'white', marginTop: 0.1 }}>
                            Wallet
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 3 }}>
                        <IconButton color="inherit">
                            <CompareArrowsIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'white', marginTop: 0.1 }}>
                            Transactions
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <IconButton color="inherit" onClick={handleOpenModal}>
                            <NotificationsIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'white', marginTop: 0.1 }}>
                            Notifications
                        </Typography>
                    </Box>
                </Box>
            )}

            {/* Modal for Input and Next Button */}
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isSmallScreen ? 300 : 500,
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: 24,
                            p: 4,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter the recipient's wallet address"
                            sx={{ marginRight: 2 }}
                        />
                        <Button variant="contained" color="warning" onClick={handleCloseModal}>
                            Next
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default Send;
