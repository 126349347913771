import React from "react";
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const notifications = [
  {
    id: 1,
    title: "Moconnect",
    status: "Payment - Completed",
    amount: "Ksh 500",
  },
  {
    id: 2,
    title: "Moconnect",
    status: "Payment - Received",
    amount: "Ksh 500",
  },
  {
    id: 3,
    title: "Moconnect",
    status: "Payment - Released",
    amount: "Ksh 500",
  },
  { id: 4, title: "Peter", status: "Payment - In dispute", amount: "Ksh 500" },
  { id: 5, title: "System", status: "Service update", amount: null },
  { id: 6, title: "Peter", status: "Payment - Refunded", amount: "Ksh 500" },
];

function NotificationDropdown({ anchorEl, handleClose }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 300,
          width: "300px",
        },
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 10px",
        }}
      >
        <Typography variant="h6">Your Notifications</Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />

      {/* Notification Items */}
      {notifications.map((notification) => (
        <MenuItem key={notification.id} onClick={handleClose}>
          <ListItemAvatar>
            <Avatar>{notification.title[0]}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${notification.title} - ${notification.amount}`}
            secondary={notification.status}
          />
        </MenuItem>
      ))}

      {/* Footer Section */}
      <Divider />

      <Link to="/FinalNotification" style={{ textDecoration: "none" }}>
        <Typography variant="h6" color="primary" ml={3}>
          View All
        </Typography>
      </Link>
    </Menu>
  );
}

export default NotificationDropdown;
