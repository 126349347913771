import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { keyframes } from "@emotion/react";
import WaitingVerification from "./WaitingVerification";
import axios from "axios";
import PostandAd from "./PostandAd"; // Import your PostAndAd component

import { toast, ToastContainer } from "react-toastify";

// Define the animation using keyframes
const tickAnimation = keyframes`
  0% { opacity: 0; transform: scale(0); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const AnimatedTick = {
  fontSize: "4rem",
  color: "green",
  animation: `${tickAnimation} 0.5s ease-in-out`,
  display: "block",
  margin: "0 auto",
};

const BecomeMember = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  // State to store application status
  const [applicationStatus, setApplicationStatus] = useState(null);
  // Checkbox Message
  const [checked, setChecked] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const [username, setUsername] = useState(""); // State to store the username
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  // Upload the image
  const [image, setImage] = useState(null); // State to store the uploaded image

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file) {
      setImage(file); // Store the file in state
    }
  };

  const handleDeleteImage = () => {
    setImage(null); // Clear the uploaded image
  };

  // Upload the video
  const [video, setVideo] = useState(null); // State to store the uploaded video URL
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages

  const handleVideoChange = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file) {
      const fileType = file.type;

      // Check if the uploaded file is a video (MP4)
      if (fileType === "video/mp4") {
        setVideo(file); // Store the video file in state
        setErrorMessage(""); // Clear any previous error message
      } else {
        // If the file is an image or other invalid type, show an alert message
        window.alert("Invalid file type. Please upload an MP4 video.");
        setVideo(null); // Clear the video if an incorrect file is uploaded
      }
    }
  };

  const handleDeleteVideo = () => {
    setVideo(null); // Clear the uploaded video
  };

  // Form submission
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State to handle dialog open/close

  const handleSubmit = async () => {
    if (!video || !checked || !username) {
      setErrorMessage(
        "Please complete the form by uploading all required files, agreeing to the terms, and providing a username."
      );
      return;
    }

    // Check if userData and user_id are available
    if (!userData || !userData.user_id) {
      setErrorMessage("User data is not available. Please log in again.");
      return;
    }

    const formData = new FormData();
    formData.append("user_id", userData.user_id);
    formData.append("phone_number", userData.phone || "");
    formData.append("email", userData.email || "");
    formData.append("merchant_username", username);
    if (image) {
      formData.append("proof_of_maker", image);
    }
    formData.append("video_proof", video);

    try {
      const response = await axios.post(`${apiUrl}/apply`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        console.log("Application submitted:", response.data);
        setFormSubmitted(true);
        setOpenDialog(true);
      } else {
        setErrorMessage(
          response.data.message || "Failed to submit application"
        );
      }
    } catch (error) {
      console.error("Error submitting application:", error);
      setErrorMessage("An error occurred while submitting the application");
    }
  };
  // Other parts of your component remain unchanged

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog when user clicks OK
  };

  // Fetch application status
  const fetchApplicationStatus = async (userId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/application/${userId}/status`
      );
      if (response.status === 200) {
        setApplicationStatus(response.data.status); // Set application status from API response
      }
    } catch (error) {
      console.error("Error fetching application status:", error);
    }
  };

  // Fetch user data and application status
  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted
    const fetchUserData = async () => {
      setLoading(true); // Set loading state
      try {
        const accessToken = localStorage.getItem("accessToken");
        console.log("Token being sent:", accessToken);

        if (!accessToken) {
          throw new Error("Token is missing. Please log in again.");
        }

        // Fetch user data
        const response = await axios.get(`${apiUrl}/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        if (isMounted) {
          setUserData(response.data); // Update state if component is still mounted

          // Now fetch the application status
          const applicationResponse = await axios.get(
            `${apiUrl}/application/${response.data.user_id}/status`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );

          // Set the application status
          if (applicationResponse.status === 200) {
            setApplicationStatus(applicationResponse.data.status); // Store the application status
          } else {
            setApplicationStatus(null); // No application found
          }
        }
      } catch (error) {
        if (error.response) {
          console.error("Error fetching user data:", error.response.data);
        } else {
          console.error("Error fetching user data:", error.message);
        }
        toast.error("Failed to fetch user data.");
      } finally {
        if (isMounted) {
          setLoading(false); // Set loading to false if component is still mounted
        }
      }
    };

    fetchUserData();

    return () => {
      isMounted = false; // Cleanup function to prevent memory leaks
    };
  }, [apiUrl]);

  // Render the component based on application status
  if (loading) {
    return <CircularProgress />;
  }

  // Show WaitingVerification if application status is pending
  if (applicationStatus === "pending") {
    return <WaitingVerification />;
  }

  // If applicationStatus is approved, show the PostAndAd component
  if (applicationStatus === "approved") {
    return <PostandAd />; // Make sure to import PostAndAd at the top of your file
  }
  // If applicationStatus is null or undefined, show the form
  if (applicationStatus === null || applicationStatus === undefined) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          padding: "20px",
          marginTop: 2,
          filter: openDialog ? "blur(4px)" : "none",
        }}
      >
        <ToastContainer />
        {formSubmitted ? (
          <WaitingVerification />
        ) : (
          <>
            {/* Title */}
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Apply To Become a Merchant
            </Typography>

            {/* Subtitle */}
            <Typography
              variant="body2"
              sx={{ marginBottom: "20px", color: "black" }}
            >
              Your application is subjected to approval based on your past
              trading performance. Our customer support will review your
              submission within one business day.
            </Typography>

            {/* Form */}
            <Grid container spacing={2}>
              {/* UserName */}

              {loading ? (
                <Grid item xs={12}>
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {/* Phone Number */}
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        marginBottom: "4px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      fullWidth
                      value={userData.phone || ""}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          backgroundColor: "rgba(211, 211, 211, 0.5)",
                          backdropFilter: "blur(5px)",
                        },
                      }}
                    />
                  </Grid>

                  {/* Email */}
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        marginBottom: "4px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Email
                    </Typography>
                    <TextField
                      fullWidth
                      value={userData.email || ""}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          backgroundColor: "rgba(211, 211, 211, 0.5)",
                          backdropFilter: "blur(5px)",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: "4px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  User Name
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter your user name"
                  variant="outlined"
                  value={username}
                  onChange={handleUsernameChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              {/* Proof of Maker Upload Section */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginBottom: "4px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Upload a proof of being a Maker on other crypto trading
                  platforms (Optional)
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ marginBottom: "20px", color: "black" }}
                >
                  Upload screenshots from other crypto platforms that you use.
                  Specifically, your Moconnect Pay trading transactions,
                  confirmation email or SMS of the merchant status, or a link to
                  your public profile on other crypto trading platforms.
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ marginBottom: "4px", color: "black" }}
                >
                  Supported file types: PNG, JPG, JPEG. Max size: 5MB.
                </Typography>

                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                    height: 200,
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                    position: "relative",
                  }}
                >
                  {image ? (
                    <>
                      {/* Uploaded Image */}
                      <img
                        src={image}
                        alt="Uploaded Proof"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                      {/* Delete Button */}
                      <Button
                        variant="contained"
                        onClick={handleDeleteImage}
                        sx={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          backgroundColor: "#f44336",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#d32f2f",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* Initial Upload Content */}
                      <CloudUploadIcon sx={{ fontSize: 50, color: "#ccc" }} />
                      <Typography variant="body2" color="textSecondary">
                        Click to upload
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          backgroundColor: "#002855", // Button background color
                          color: "white", // Initial text color
                          "&:hover": {
                            backgroundColor: "#002855", // Keep the background color unchanged on hover
                            color: "#ffa500", // Change text color to orange on hover
                          },
                        }}
                      >
                        Upload File
                        <input type="file" hidden onChange={handleFileChange} />
                      </Button>
                    </>
                  )}
                </Paper>
              </Grid>

              {/* Proof of Video Section */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginBottom: "4px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Upload a Video Proof of Trading Experience (Required)
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ marginBottom: "15px", color: "black" }}
                >
                  Record a video of yourself holding a passport or the document
                  you provided during identity verification. Make sure the page
                  containing your personal information is clearly visible. Face
                  the camera and read the following
                </Typography>

                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    fontStyle: "italic",
                  }}
                >
                  "I [fullname] confirm that I'm applying for the Moconnect Pay
                  Merchant Program. I confirm that I am using my own account,
                  and I'm the only one who manages this account. I also confirm
                  that I use my own legal funds to buy cryptocurrency "
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ marginBottom: "4px", color: "black" }}
                >
                  Supported file type: MP4. Max size: 50MB.
                </Typography>
                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                    height: 200,
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                    position: "relative",
                  }}
                >
                  {video ? (
                    <>
                      {/* Uploaded Video */}
                      <video
                        controls
                        src={video}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                      {/* Delete Button */}
                      <Button
                        variant="contained"
                        onClick={handleDeleteVideo}
                        sx={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          backgroundColor: "#f44336",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#d32f2f",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* Initial Upload Content */}
                      <CloudUploadIcon sx={{ fontSize: 50, color: "#ccc" }} />
                      <Typography variant="body2" color="textSecondary">
                        Click to upload
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          backgroundColor: "#002855", // Button background color
                          color: "white", // Initial text color
                          "&:hover": {
                            backgroundColor: "#002855", // Keep the background color unchanged on hover
                            color: "#ffa500", // Change text color to orange on hover
                          },
                        }}
                      >
                        Upload Video
                        <input
                          type="file"
                          hidden
                          onChange={handleVideoChange}
                        />
                      </Button>
                    </>
                  )}
                </Paper>
              </Grid>

              {/* Checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ color: "black" }}>
                      I understand that this application does not guarantee my
                      acceptance as a Merchant.
                    </Typography>
                  }
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    width: "100%",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    textTransform: "none",
                    backgroundColor: "#002855",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#002855",
                      color: "#FFA500",
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>

              {errorMessage && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}

        {/* Dialog */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Application Submitted</DialogTitle>
          <DialogContent>
            <CheckCircleIcon style={AnimatedTick} />
            <DialogContentText>
              Your application has been submitted successfully.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return <div>Application Status : {applicationStatus}</div>;
};

export default BecomeMember;
