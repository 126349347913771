import {
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

import { motion } from "framer-motion";
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import image1 from "../images/works.mp4";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Animation variants for Framer Motion
const image1Variants = {
  hidden: { opacity: 0, scale: 0.8 }, // Initial hidden state (scaled down)
  visible: { opacity: 1, scale: 1 }, // Fully visible state (normal size)
};

// Animation variants for Framer Motion
const cardVariants = {
  hidden: { opacity: 0, x: -100 }, // Start offscreen to the left
  visible: { opacity: 1, x: 0 }, // Slide into place
};

function Solutions() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null); // To track the card section

  const [isEscrowImageVisible, setIsEscrowImageVisible] = useState(false);
  const escrowImageRef = useRef(null); // Reference for the "How Escrow Works" image

  // Intersection Observer to detect visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true); // Trigger animation when element is visible
          } else {
            setIsVisible(false); // Reset animation when it goes out of view (optional)
          }
        });
      },
      { threshold: 0.2 } // Trigger when 20% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Cleanup observer
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Intersection Observer for the "How Escrow Works" image
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsEscrowImageVisible(true); // Trigger animation when escrow image is visible
          } else {
            setIsEscrowImageVisible(false);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (escrowImageRef.current) {
      observer.observe(escrowImageRef.current);
    }

    return () => {
      if (escrowImageRef.current) {
        observer.unobserve(escrowImageRef.current);
      }
    };
  }, []);

 
  return (
    <Box>
      <Box sx={{ position: "relative", top: -30 }} ref={sectionRef}>
        <Grid container spacing={4} justifyContent="center">
          {/* Card 1 */}
          <Grid item xs={12} md={3}>
            <motion.div
              variants={cardVariants}
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              <Card
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
                  minHeight: "320px",
                  width: "320px",
                  padding: "6px", // Increase padding
                  "@media (max-width: 600px)": {
                    marginLeft: 2, // Adjust height for small screens
                  },
                }}
              >
                <CardContent>
                  <img src={icon1} alt="User 3" style={{ width: "30%" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
                    For Freelancers and Small Businesses
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Face payment delays,
                    trust issues, and currency exchange challenges. Moconnect
                    Pay addresses these with secure escrow payments, wallet
                    transactions for easy finance management, and P2P USDT
                    trading for simple currency exchanges. Smart contracts
                    automate agreements ensuring transparency.
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} sm={6} md={3}>
            <motion.div
              variants={cardVariants}
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <Card
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
                  minHeight: "320px",
                  width: "320px",
                  padding: "6px", // Increase padding
                  "@media (max-width: 600px)": {
                    marginLeft: 2, // Adjust height for small screens
                  },
                }}
              >
                <CardContent>
                  <img src={icon2} alt="User 4" style={{ width: "30%" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
                    For Medium to Large Enterprises
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Face cash flow issues, delayed
                    payments, high transaction costs, and fraud risks. Moconnect
                    Pay solves these with secure escrow payments, efficient
                    wallet management, low-cost P2P USDT trading, and automated
                    smart contracts. It simplifies financial tracking, reduces
                    fraud, and streamlines operations for growth.
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} sm={6} md={3}>
            <motion.div
              variants={cardVariants}
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              <Card
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
                  minHeight: "320px",
                  width: "320px",
                  padding: "6px", // Increase padding
                  "@media (max-width: 600px)": {
                    marginLeft: 2, // Adjust height for small screens
                  },
                }}
              >
                <CardContent>
                  <img src={icon3} alt="User 5" style={{ width: "30%" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Individuals Seeking Secure Transactions
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Face trust issues,
                    high fees, and lengthy verifications. Moconnect Pay solves this
                    with escrow payments that hold funds until obligations are
                    met, wallet transactions for easy fund management, and P2P
                    USDT trading for simple crypto exchanges. Smart contracts
                    automate agreements, reducing disputes. Moconnect Pay provides a
                    safe, low-cost platform for confident peer-to-peer
                    transactions.
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      {/* How it Works Section */}
      <Box sx={{ my: 10 }}>
        <Grid container spacing={4}>
          {" "}
          {/* Add alignItems="center" */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              order: { xs: 2, md: 1 }, // Image comes second on small screens, first on large screens
            }}
          >
            <motion.div
              ref={escrowImageRef} // Attach the new reference
              variants={image1Variants} // Use the same animation variants
              initial="hidden" // Initial hidden state
              animate={isEscrowImageVisible ? "visible" : "hidden"} // Trigger animation based on visibility
              transition={{ duration: 0.6, ease: "easeOut" }} // Smooth transition
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <video
                  src={image1}
                  alt="How Escrow Works"
                  style={{ width: "70%", borderRadius: "10px" }} // Adjust the width as needed
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </motion.div>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              order: { xs: 1, md: 2 }, // Text comes first on small screens, second on large screens
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                mb: 1,
                ml: 9,
                mt: 19,
                "@media (max-width: 500px)": {
                  mt: 2,
                  ml: 1, // Reduced margin-left for small screens
                  mr: 3, // Reduced margin-right for small screens
                },
              }}
            >
              How Escrow Works
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 1,
                ml: 9,
                "@media (max-width: 600px)": {
                  ml: 3, // Reduced margin-left for small screens
                  mr: 3, // Reduced margin-right for small screens
                },
              }}
            >
              Have Confidence in your Transactions
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
                ml: 9,
                "@media (max-width: 600px)": {
                  ml: 3, // Reduced margin-left for small screens
                  mr: 3, // Reduced margin-right for small screens
                },
              }}
            >
              Escrow payments securely hold funds until obligations are met,
              ensuring you have confidence in your transactions while leveraging
              wallet transactions, P2P trading (USDT), and smart contracts for a
              streamlined B2B experience.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                ml: 9,
                backgroundColor: "rgba(255, 165, 0, 0.8)",
                color: "white",
                fontWeight: 500,

                boxShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  backgroundColor: "#FFA500",
                  transform: "translateX(2px)",
                  transition: "0.2s",
                },
                "@media (max-width: 600px)": {
                  ml: 3, // Reduced margin-left for small screens
                  mr: 3, // Reduced margin-right for small screens
                },
              }}
              endIcon={<ArrowForwardIcon />}
            >
              Register For Free
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Solutions;
