import React, { useState } from "react";

import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChatIcon from "@mui/icons-material/Chat";

import { useNavigate } from "react-router-dom";
import { formatEther } from "ethers";
import FAQDialog from "../HelpForm";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SendIcon from '@mui/icons-material/Send';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SouthWestIcon from '@mui/icons-material/SouthWest';

const Walletbalance = ({ defaultAccount, userBalance }) => {
  const navigate = useNavigate();
  const publicAddress = defaultAccount;

  // const [userBalance, setUserBalance] = useState(initialBalance); // Initialize state with initialBalance
 
  // Function to fetch and update the wallet balance
  // const updateWalletBalance = async () => {
  //   try {
  //     const provider = new formatEther.providers.Web3Provider(window.ethereum);
  //     const balance = await provider.getBalance(publicAddress);
  //     setUserBalance(formatEther.utils.formatEther(balance)); // Update balance in state
  //   } catch (error) {
  //     console.error("Failed to fetch balance:", error);
  //   }
  // };

  // useEffect(() => {
  //   updateWalletBalance(); // Fetch balance on component mount
  // }, [publicAddress]); // Re-fetch balance if publicAddress changes



  const handleSend = () => {
    navigate('/Transaction');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(publicAddress);
    alert("Public address copied to clipboard!");
  };

  const handleP2PTradingClick = () => {
    navigate("/P2P");
  };

  const handleDepositClick = () => {
    navigate("/deposit");
  };

  const handleWithdrawClick = () => {
    navigate("/withdraw");
  };

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isBalanceVisible, setIsBalanceVisible] = useState(false); // State to toggle visibility

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible); // Toggle visibility
  };

  const [isBalanceVisible1, setIsBalanceVisible1] = useState(false); // State to toggle visibility

  const toggleBalanceVisibility1 = () => {
    setIsBalanceVisible1(!isBalanceVisible1); // Toggle visibility
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",

        display: "flex",
        flexDirection: "column",
        padding: 2,
        overflowY: "auto", // Allow scrolling
        marginTop: "50px",
      }}
    >
      {/* Wallet Text */}
      <Typography
        sx={{
          marginBottom: 1,
          marginLeft: 3,
          fontSize: "25px",
          fontWeight: "500",
          marginTop: "50px",
          "@media (max-width: 600px)": {
            fontSize: "20px",
            marginTop: "60px",
          },
          "@media (min-width: 768px) and (max-width: 1024px)": {
            // Adjust for iPad mini
            fontSize: "22px",
            marginTop: "50px",
          },

          "@media (max-width: 1024px)": {
            fontSize: "22px",
            marginTop: "50px",
          },
        }}
      >
        My Wallet
      </Typography>
      <Divider sx={{ marginBottom: 4, marginLeft: 3, marginRight: 3 }} />

      <Grid container spacing={4}>
        {/* Balance Section */}
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "69vh", // Limit the height to 60% of the viewport height
            }}
          >
            <CardContent
              sx={{
                backgroundColor: "#002855",
                color: "white",
                borderRadius: 1,

                "@media (max-width: 1024px)": {
                  padding: "10",

                  height: "100%",
                },

                "@media (min-width: 768px)": {
                  height: "420px",
                },

                // Adjust for iPad mini
              }}
            >
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <Typography variant="h6">Main Balance</Typography>
               <IconButton onClick={toggleBalanceVisibility} aria-label="toggle balance visibility">
          {isBalanceVisible ? <VisibilityOffIcon sx={{color:"white"}} /> : <VisibilityIcon sx={{color:"white"}} />}
        </IconButton>
               </Box>
              
             
        <Typography variant="h4" sx={{ marginRight: 2 }}>
          {isBalanceVisible ? `${userBalance} ETH` : '*****'} {/* Show balance or stars */}
        </Typography>
       
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop:"10px"}}>
        <Typography variant="h6">Escrow Balance</Typography>
        <IconButton onClick={toggleBalanceVisibility1} aria-label="toggle escrow balance visibility">
          {isBalanceVisible1 ? <VisibilityOffIcon sx={{color:"white"}} /> : <VisibilityIcon sx={{color:"white"}} />}
        </IconButton>
        </Box>
             

        <Typography variant="h4" sx={{ marginRight: 2 }}>
          {isBalanceVisible1 ? 'KSH 0.00' : '*****'} {/* Show balance or stars */}
        </Typography>
        
      
              {/* Send and Request Buttons */}
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: 4,
                  justifyContent: "center",
                  // "@media (max-width: 1024px)": {
                  //   // Nest Hub screen width
                  //   marginTop: 0.3,
                  //   padding: 1,
                  // },
                }}
              >
                <Grid item xs={12} sm={10}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      padding: 4,
                      width: "70%",
                      borderRadius: 2,
                      border: "2px solid #1b4b8b",
                      display: "flex",

                      // Responsive width and padding for small screens
                      "@media (max-width: 600px)": {
                        padding: "10",
                        spacing: "3",

                        width: "75%",

                        height: "100px",
                      },

                      // "@media (max-width: 1024px)": {
                      //   padding: 3.7,
                      //   width: "300px", // Adjust width for Nest Hub
                      // },
                      "@media (min-width: 768px) and (max-width: 1024px)": {
                        width: "87%", // Adjust width to fit iPad mini
                      },
                    }}
                  >
                    {/* Send Section */}
                    <Grid item xs={6} sx={{ textAlign: "center", spacing: 1 }}>
                      <Box
                        sx={{ position: "relative", display: "inline-block" }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#002855",
                            border: "2px solid white",
                            padding: "8px",
                            borderRadius: "8px",
                            display: "inline-block",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleSend}
                            sx={{
                              backgroundColor: "white",
                              color: "black",
                              border: "2px solid white",
                              borderRadius: "50%",
                              padding: "16px",
                              minWidth: "64px",
                              minHeight: "64px",
                              position: "relative",

                              "@media (max-width: 600px)": {
                                minWidth: "10px",
                                minHeight: "10px",
                              },

                              "@media (max-width: 1024px)": {
                                minWidth: "50px",
                                minHeight: "50px",
                              },

                              "&:hover": {
                                
                                backgroundColor: "white",
                                
                              },
                            }}
                          >
                            <SendIcon
                              sx={{
                                color: "#002855",

                                "&:hover": {
                                  color: "#ffa500",
                                  
                                  
                                },
                              }}
                            />
                          </Button>
                        </Box>
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            marginTop: "8px",
                            color: "#002855",
                            fontWeight: "bold",
                          }}
                        >
                          Send
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Withdraw Section */}
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                      <Box
                        sx={{ position: "relative", display: "inline-block" }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#002855",
                            border: "2px solid white",
                            padding: "8px",
                            borderRadius: "8px",
                            display: "inline-block",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleP2PTradingClick} // Define handleWithdrawClick function
                            sx={{
                              backgroundColor: "white",
                              color: "black",
                              border: "2px solid white",
                              borderRadius: "50%",
                              padding: "16px",
                              minWidth: "64px",
                              minHeight: "64px",
                              position: "relative",

                              "@media (max-width: 600px)": {
                                minWidth: "5px",
                                minHeight: "5px",
                              },
                              "@media (max-width: 1024px)": {
                                minWidth: "50px",
                                minHeight: "50px",
                              },
                              "&:hover": {
                                
                                backgroundColor: "white",
                                
                              },
                            }}
                          >
                            <ArrowOutwardIcon
                              sx={{
                                color: "green",
                                "&:hover": {
                                  color: "#ffa500",
                                  
                                  
                                },
                              }}
                            />
                          </Button>
                        </Box>
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            marginTop: "8px",
                            color: "#002855",
                            fontWeight: "bold",

                            "@media (max-width: 600px)": {
                              minWidth: "5px",
                              minHeight: "5px",
                            },
                            "&:hover": {
                                
                              backgroundColor: "white",
                              
                            },
                          }}
                        >
                          Withdraw
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Deposit Section */}
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                      <Box
                        sx={{ position: "relative", display: "inline-block" }}
                      >
                        <Box
                          sx={{
                            border: "2px solid white",
                            padding: "8px",
                            borderRadius: "8px",
                            display: "inline-block",
                            backgroundColor: "#002855",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleP2PTradingClick}
                            sx={{
                              backgroundColor: "white",
                              color: "black",
                              border: "2px solid white",
                              borderRadius: "50%",
                              padding: "16px",
                              minWidth: "64px",
                              minHeight: "64px",
                              position: "relative",
                              "@media (max-width: 600px)": {
                                minWidth: "5px",
                                minHeight: "5px",
                              },

                              "@media (max-width: 1024px)": {
                                minWidth: "50px",
                                minHeight: "50px",
                              },
                              "&:hover": {
                                
                              backgroundColor: "white",
                              
                            },
                            }}
                          >
                            <SouthWestIcon
                              sx={{
                                color: " #D35400",
                                "&:hover": {
                                  color: "#ffa500",
                                  
                                  
                                },
                              }}
                            />
                          </Button>
                        </Box>
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            marginTop: "8px",
                            color: "#002855",
                            fontWeight: "bold",
                          }}
                        >
                          Deposit
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* QR Code Section */}
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              padding: 3,
              boxShadow: 1,
              display: "flex",
              flexDirection: "column",
              maxHeight: "60vh", // Limit the height to 60% of the viewport height
              overflowY: "auto", // Allow scrolling if content overflows
              marginBottom: "5rem", // Ensure space above the footer
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 0.4 }}>
              My Wallet Address
            </Typography>
            <Typography
              sx={{ fontSize: "13px", color: "#333333", marginBottom: 2 }}
            >
              A wallet address is your crypto "account number" to receive funds
              securely. Share it safely!
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f0f0f0",
                padding: 2,
                borderRadius: 1,
                marginBottom: 4,
              }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {defaultAccount}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopy}
                sx={{
                  minWidth: "auto",
                  padding: "6px",
                  backgroundColor: "#002855",

                  "&:hover": {
                    backgroundColor: "#002855",
                  },
                }}
              >
                <ContentCopyIcon
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "#FFA500",
                    },
                  }}
                />
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
              }}
            >
              <Box sx={{ flex: 1, height: 1.2, bgcolor: "grey" }} />
              <Typography
                variant="h6"
                align="center"
                sx={{ mx: 2, fontWeight: "bold", color: "grey.500" }}
              >
                or
              </Typography>
              <Box sx={{ flex: 1, height: 1.2, bgcolor: "grey" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <QRCodeSVG value={publicAddress} size={220} />{" "}
              {/* Reduced size */}
            </Box>
          </Card>
        </Grid>
      </Grid>

      {/* Help Icon positioned absolutely */}
      <IconButton
        sx={{
          position: "fixed",
          bottom: 100,
          right: 50,
          backgroundColor: "orange",
          color: "white",
          zIndex: 1500, // Ensures the icon is visible above the dialog backdrop
          "&:hover": {
            backgroundColor: "orange",
          },
          "@media (max-width: 600px)": {
            bottom: "13%",
            right: "5%",
          },
          "@media (min-width: 768px) and (max-width: 1024px)": {
            // Adjust for iPad mini
            bottom: "8%",
            right: "3%",
          },

          "@media (max-width: 1024px)": {
            bottom: "13%",
            right: "3%",
          },
        }}
        onClick={handleToggle}
      >
        <ChatIcon />
      </IconButton>
      {/* Render the FAQDialog and pass open state and handleClose */}
      <FAQDialog open={open} handleClose={handleClose} />

      
    </Box>
  );
};

export default Walletbalance;
