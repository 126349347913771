import React, { useState, useRef, useEffect } from "react";
import { Box, TextField, Button, Typography, IconButton } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moconnectlogo from "../images/Moconnectblock_pay.jpeg";
import { useUser } from "../LoginPage/UserContext";
import { useNavigate } from "react-router-dom";

const steps = [
  "Personal Details",
  "National ID Credentials",
  "Capture Photo",
  "OTP"
];

const SignUpForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { checkUser } = useUser(); // Get checkUser from UserProvider

  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const captureInputRef = useRef(null);

  const handleCaptureFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [currentFileType, setCurrentFileType] = useState(null);

  const fileInputRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleFileInput = (type) => {
    setCurrentFileType(type);
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (currentFileType === "front") {
          setFrontImage(reader.result);
        } else {
          setBackImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dateOfBirth: "",
    password: "",
    confirmPassword: "",
    profession: "",
    salaryEarning: "",
    sourceIncome: "",
    otp: ["", "", "", "", "", ""],
  });
  const [errors, setErrors] = useState({});
  const [userExists, setUserExists] = useState(false);

  // Effect to check user existence on step change
  useEffect(() => {
    if (step === 0) {
      checkUser(['/signup', '/home']);
    }
  }, [step, checkUser]);

  const handleNextStep = async () => {
    let validationErrors = {};

    if (step === 0) {
      validationErrors = validateStep1();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      const exists = await checkUserExists();
      if (exists) {
        return;
      }
    }

    if (step === 1) {
      if (!frontImage || !backImage) {
        setErrors({ nationalId: "Both front and back National ID images are required." });
        return;
      }
    }

    if (step === 2) {
      if (!uploadedPhoto) {
        setErrors({ capturePhoto: "Photo capture is required." });
        return;
      }

      await handleSignUp();
    }
    if (step === 3) {
      // Validate OTP
      const otpString = formValues.otp.join("");
      if (otpString.length !== 6) {
        setErrors({ otp: "A valid 6-digit OTP is required." });
        return;
      }

      // Complete the signup process or proceed further
      // handleCompleteSignup(); // Call the final signup handler if needed
      setStep(step + 1);
      return;
    }

    // Proceed to the next step if no errors
    setErrors({}); // Clear errors before moving to the next step
    setStep(step + 1);
  };



  
  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSignUp = async () => {
    try {
      const response = await fetch(`${apiUrl}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          phone: formValues.phone,
          email: formValues.email,
          dateOfBirth: formValues.dateOfBirth,
          password: formValues.password,
          confirmPassword: formValues.confirmPassword,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Signup failed");
      }
  
      const result = await response.json();
  
      if (result.otpSent) {
        setOtpSent(true); // Set the OTP sent flag to true
        toast.success("Signup successful! A verification code has been sent to your phone number.");
      }
  
      setStep((prevStep) => prevStep + 1); // Move to the OTP step
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error("Signup failed. Please try again.");
    }
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const updatedOtp = [...formValues.otp];

    if (/^\d?$/.test(value)) {
      updatedOtp[index] = value;
      setFormValues({ ...formValues, otp: updatedOtp });

      if (value && index < formValues.otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      } else if (!value && index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleOTPVerify = async () => {
    const otpString = formValues.otp.join("");
    try {
      const response = await fetch(`${apiUrl}/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: formValues.phone,
          otp: otpString,
        }),
        credentials: "include",
      });
  
      if (response.ok) {
        const data = await response.json();
        // Show success toast
        toast.success("Phone number verification successful! Redirecting...");
  
        // Redirect to the Wallet after a short delay
        setTimeout(() => {
          navigate("/Wallet", { replace: true }); // Redirect to Wallet
        }, 2000); // Adjust the delay as needed
  
      } else {
        const errorData = await response.json();
        // Show error toast for incorrect OTP
        toast.error(errorData.error || "Incorrect OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error(`Error: ${error.message || "There was an issue verifying the OTP."}`);
    }
  };
  

  const handleResendOTP = async () => {
    try {
      const response = await fetch(`${apiUrl}/resend_otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: formValues.phone }),
        credentials: "include",
      });
      if (response.status === 200) {
        alert("OTP resent successfully!");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("Failed to resend OTP. Please try again.");
    }
  };

  const checkUserExists = async () => {
    try {
      const response = await axios.post(`${apiUrl}/check_user_exists`, {
        email: formValues.email,
        phone: formValues.phone,
      });

      if (response.data.exists) {
        setUserExists(true);
        toast.error("User already exists. Please log in.");
        return true;
      } else {
        setUserExists(false);
        return false;
      }
    } catch (error) {
      console.error("Error checking user existence:", error);
      return false;
    }
  };

  const validateStep1 = () => {
    const newErrors = {};
    if (!formValues.firstName) newErrors.firstName = "First name is required";
    if (!formValues.lastName) newErrors.lastName = "Last name is required";
    if (!formValues.phone) newErrors.phone = "Phone number is required";
    if (!formValues.email) newErrors.email = "Email is required";
    if (!formValues.dateOfBirth) newErrors.dateOfBirth = "Date of birth is required";
    if (!formValues.password) newErrors.password = "Password is required";
    if (formValues.password !== formValues.confirmPassword) newErrors.confirmPassword = "Passwords do not match";
    return newErrors;
  };

  //phone
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ color: "black", marginBottom: 1 }}
      >
        Sign up
      </Typography>
      <img
        src={Moconnectlogo}
        alt="logo"
        style={{
          height: 130,
          width: 130,
          marginRight: "8px",
          borderRadius: "50%",

          // Responsive styles for logo
          "@media (max-width: 600px)": {
            height: 80,
            width: 80,
          },
        }}
      />
      <Typography
        sx={{ fontStyle: "italic", marginBottom: 3, fontWeight: "bold" }}
      >
        Connect and Discover Us
      </Typography>

      {/* Stepper Component */}
      <Stepper activeStep={step} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step 1: Personal Details */}
      {step === 0 && (
        <Box
          sx={{
            backgroundColor: "#022b46",
            padding: "2rem",
            borderRadius: "8px",
            border: "1px solid white",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Box component="form" noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              autoFocus
              variant="filled"
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
              value={formValues.firstName}
              onChange={handleChange}
              // error={!!formErrors.firstName}
              // helperText={formErrors.firstName}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastName"
              variant="filled"
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
              value={formValues.lastName}
              onChange={handleChange}
              // error={!!formErrors.lastName}
              // helperText={formErrors.lastName}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone Number"
              name="phone" // This should match the key in formValues
              autoComplete="phone"
              variant="filled"
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
              value={formValues.phone}
              onChange={handleChange}
              // error={!!formErrors.phone}
              // helperText={formErrors.phone}
            />

            

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email" // This should match the key in formValues
              autoComplete="email"
              variant="filled"
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
              value={formValues.email}
              onChange={handleChange}
              // error={!!formErrors.email}
              // helperText={formErrors.email}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="dateOfBirth"
              label="Date of Birth"
              name="dateOfBirth"
              autoComplete="dateOfBirth"
              variant="filled"
              type="date"
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
              value={formValues.dateOfBirth}
              onChange={handleChange}
              // error={!!formErrors.dateOfBirth}
              // helperText={formErrors.dateOfBirth}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              variant="filled"
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
              value={formValues.password}
              onChange={handleChange}
              // error={!!formErrors.password}
              // helperText={formErrors.password}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                    sx={{ position: "absolute", right: "10px" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              autoComplete="confirm-password"
              variant="filled"
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
              value={formValues.confirmPassword}
              onChange={handleChange}
              // error={!!formErrors.confirmPassword}
              // helperText={formErrors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                    sx={{ position: "absolute", right: "10px" }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <Button
              variant="contained"
              onClick={handleNextStep}
              sx={{ mt: 3, mb: 2, bgcolor: "orange" }}
              fullWidth
            >
              Next
            </Button>
          </Box>
        </Box>
      )}

      {/* Step 2: National ID Credentials */}
      {step === 1 && (
        <Box
          sx={{
            backgroundColor: "#022b46",
            padding: "2rem",
            borderRadius: "8px",
            border: "1px solid white",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Box component="form" noValidate>
            <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
              Upload National ID (Front)
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 2,
                position: "relative",
              }}
            >
              <img
                src={frontImage || "/placeholder.png"}
                alt="Front National ID"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <IconButton
                onClick={() => handleFileInput("front")}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  backgroundColor: "white",
                }}
              >
                <CameraAltIcon />
              </IconButton>
            </Box>

            <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
              Upload National ID (Back)
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 2,
                position: "relative",
              }}
            >
              <img
                src={backImage || "/placeholder.png"}
                alt="Back National ID"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <IconButton
                onClick={() => handleFileInput("back")}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  backgroundColor: "white",
                }}
              >
                <CameraAltIcon />
              </IconButton>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleNextStep}
              sx={{ mt: 3, mb: 2 }}
              fullWidth
            >
              Next
            </Button>
            <Button
              variant="outlined"
              onClick={handlePreviousStep}
              sx={{ mt: 3, mb: 2 }}
              fullWidth
            >
              Back
            </Button>
          </Box>
        </Box>
      )}

      {/* Step 3: Capture Photo */}
      {step === 2 && (
        <Box
          sx={{
            backgroundColor: "#022b46",
            padding: "2rem",
            borderRadius: "8px",
            border: "1px solid white",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Box component="form" noValidate>
            <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
              Capture Photo
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 2,
                position: "relative",
              }}
            >
              <img
                src={uploadedPhoto || "/placeholder.png"}
                alt="Uploaded Photo"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              <input
                type="file"
                accept="image/*"
                ref={captureInputRef}
                onChange={handleCaptureFileChange}
                style={{ display: "none" }}
              />
              <IconButton
                onClick={() => captureInputRef.current.click()}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  backgroundColor: "white",
                }}
              >
                <CameraAltIcon />
              </IconButton>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleNextStep}
              sx={{ mt: 3, mb: 2 }}
              fullWidth
            >
              Next
            </Button>
            <Button
              variant="outlined"
              onClick={handlePreviousStep}
              sx={{ mt: 3, mb: 2 }}
              fullWidth
            >
              Back
            </Button>
          </Box>
        </Box>
      )}

{step === 3 && (
  <Box
    sx={{
      backgroundColor: "#022b46",
      padding: "2rem",
      borderRadius: "8px",
      border: "1px solid white",
      width: "100%",
      maxWidth: "400px",
    }}
  >
    <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
      Enter OTP
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {formValues.otp.map((digit, index) => (
        <TextField
          key={index}
          id={`otp-input-${index}`}
          type="text"
          value={digit}
          onChange={(e) => handleOtpChange(e, index)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: 'center' },
          }}
          sx={{ bgcolor: "white", borderRadius: "4px", width: "40px", marginLeft:"3px" }}
        />
      ))}
    </Box>
    {errors.otp && <Typography sx={{ color: "red" }}>{errors.otp}</Typography>}
    <Button
      variant="contained"
      onClick={handleOTPVerify}
      sx={{ mt: 3, mb: 2, bgcolor: "orange" }}
      fullWidth
    >
      Verify OTP
    </Button>
    <Button
      variant="text"
      onClick={handleResendOTP}
      sx={{ mb: 2, color:"#FFA500" }}
      fullWidth
    >
      Resend OTP
    </Button>
  </Box>
)}
    </Box>
  );
};

export default SignUpForm;
