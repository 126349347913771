import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import PostAd from "./PostAD";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PostandAd = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          color: "green",
          marginTop: 2,
          marginLeft: 0.4,
          display: "flex",
          gap: 1,
          marginBottom: 2,
        }}
      >
        <CheckCircleIcon /> Successfully verified as a Merchant!
      </Typography>

      <Typography
        sx={{
          marginBottom: "10px",
        }}
      >
        To begin your journey as a Merchant you need to be able to sell or buy
        coins from users in the moconnect pay P2P platform:
      </Typography>

      <Box
        sx={{
          width: "98%",
          bgcolor: "white",
          borderRadius: "8px",
          marginTop: "40px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Tabs Navigation */}

        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              minWidth: "50%",
              fontSize: "16px",
              fontWeight: 500,
              color: "#666",
              borderBottom: "1px solid #e0e0e0",
            },
            "& .Mui-selected": {
              color: "#002855",
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <Tab label="Post AD" />
          <Tab label="View AD" />
        </Tabs>

        {/* Conditional Content */}
        <Box sx={{ p: 3 }}>
          {value === 0 && <PostAd />}
          {value === 1 && (
            <Typography variant="body1">
              Here is the content for "I want to sell". You can place any
              components or content here.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PostandAd;
