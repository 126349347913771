import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';

const DialogCondition = ({ open, onClose }) => {

  const navigate = useNavigate();

  const handleCloseConcern = () => {
    navigate('/DisputePage');
  };
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State for the confirmation dialog
  const [allConditionsMet, setAllConditionsMet] = useState(false); // State for conditions being met

  const paymentMethods = [
    { label: "I want the dress to be short below the knee" },
    { label: "I want the dress to be red" },
    { label: "I want the dress to be long sleeve" },
    { label: "I want the dress to be v shaped on the top" },
  ];

  const handlePaymentChange = (method) => {
    setSelectedPayments((prev) =>
      prev.includes(method)
        ? prev.filter((item) => item !== method)
        : [...prev, method]
    );
  };

  const handleConfirm = () => {
    // Check if all conditions are selected
    if (selectedPayments.length === paymentMethods.length) {
      setConfirmDialogOpen(true); // Open confirmation dialog
    } else {
      setAllConditionsMet(false); // Set conditions not met
    }
  };

  const handleConfirmDialogClose = (confirmed) => {
    if (confirmed) {
      console.log("User confirmed the conditions.");
      setConfirmDialogOpen(false);
      onClose(); // Close the original dialog
    } else {
      setConfirmDialogOpen(false); // Close the confirmation dialog without action
    }
  };

  const handleCloseClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          onClose();
        }}
        maxWidth="sm"
        fullWidth
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          Have these Conditions Been Met?
          <Typography sx={{ color: "grey", fontSize: "11px" }}>
            Ensure that these conditions have been met by your seller after
            delivery.
          </Typography>
          <Typography sx={{ color: "grey", fontSize: "11px" }}>
            If these conditions are not met, please click on the raise a concern
            button so that the money can be returned to you.
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 3,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <List>
            {paymentMethods.map((method, index) => (
              <ListItem key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedPayments.includes(method.label)}
                      sx={{
                        color: "#002855",
                        "&.Mui-checked": {
                          color: "#002855",
                        },
                      }}
                      onChange={(e) => {
                        e.stopPropagation();
                        handlePaymentChange(method.label);
                      }}
                    />
                  }
                  label={<div>{method.label}</div>}
                />
              </ListItem>
            ))}
          </List>

          {/* Show warning if not all conditions are met */}
          {!allConditionsMet && (
            <Typography color="error">
              Please make sure all conditions are selected before confirming.
            </Typography>
          )}
        </DialogContent>

        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={handleCloseConcern}
            variant="contained"
            sx={{
              backgroundColor: "#002855",
              color: "white",
              width: "200px",
              "&:hover": {
                backgroundColor: "#002855",
                color: "#ffa500",
              },
            }}
          >
            Raise a Concern
          </Button>

          <Button
            onClick={handleConfirm}
            variant="contained"
            sx={{
              backgroundColor: "#002855",
              color: "white",
              width: "200px",
              "&:hover": {
                backgroundColor: "#002855",
                color: "#ffa500",
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to confirm these conditions?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDialogClose(true)}
            variant="contained"
            sx={{
              backgroundColor: "#002855",
              color: "white",
              
              "&:hover": {
                backgroundColor: "#002855",
                color: "#ffa500",
              },
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => handleConfirmDialogClose(false)}
            variant="outlined"
            sx={{
              backgroundColor: "#ffa500",
              color: "white",
              
              "&:hover": {
                backgroundColor: "#ffa500",
                color: "#002855",
              },
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCondition;
