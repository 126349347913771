import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Box from "@mui/material/Box";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"; // Icon for My Orders
import NotificationDropdown from "../src/Notification Area/NotificationPage"; // Import the NotificationDropdown component
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";

const TopOrders = ({ hideIcons, onWalletClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ bgcolor: "white", width: "100%", overflowX: "auto" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
        }}
      >
        {/* Left: Logo */}
        <Box variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <img
              src="/MoconnectBlack.png"
              alt="logo"
              style={{
                height: 50,
                width: 50,
                borderRadius: "50%",
                cursor: "pointer",
                marginRight: "8px",
              }}
            />
            <Typography
              sx={{ fontSize: "20px", textDecoration: "none", color: "black" }}
            >
              Moconnect Pay
            </Typography>
          </Link>
        </Box>

     

        {/* Right: Icons including My Orders */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* My Orders */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
            }}
          >
            <IconButton color="inherit" component={RouterLink} to="/OrdersP2P">
              <ShoppingCartIcon sx={{ color: "black" }} />
            </IconButton>
            <Typography variant="body2" sx={{ color: "black", marginTop: 0.1 }}>
              My Orders
            </Typography>
          </Box>

          {/* Profile */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: 3,
            }}
          >
            <IconButton
              color="inherit"
              component={RouterLink}
              to="/WalletProfile"
            >
              <AccountCircleIcon sx={{ color: "black" }} />
            </IconButton>
            <Typography variant="body2" sx={{ color: "black", marginTop: 0.1 }}>
              Profile
            </Typography>
          </Box>

          {/* Other Icons */}
          {!hideIcons && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Wallet */}
              <Link
                onClick={onWalletClick}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  alignItems: "center",
                  gap: 1,
                  "&:hover": {
                    "& .wallet-icon": {
                      color: "#FFFFFF",
                    },
                    "& .wallet-text": {
                      color: "#FFA500",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: 3,
                  }}
                >
                  <IconButton color="inherit">
                    <AccountBalanceWalletIcon
                      className="wallet-icon"
                      sx={{ color: "black" }}
                    />
                  </IconButton>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "black",
                      marginTop: 0.1,
                      textDecoration: "none",
                    }}
                  >
                    Wallet
                  </Typography>
                </Box>
              </Link>

              

              {/* Notifications */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <IconButton color="inherit" onClick={handleOpen}>
                  <NotificationsIcon sx={{ color: "black" }} />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{ color: "black", marginTop: 0.1 }}
                >
                  Notifications
                </Typography>
                <NotificationDropdown
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopOrders;
