import React, { useState } from "react";
import axios from "axios"; 
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  Avatar,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom"; 
import Moconnectlogo from "../images/Moconnectblock_pay.jpeg";
import { toast, ToastContainer } from "react-toastify"; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS


const LoginForm = () => {
  //backend api url
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate(); // Get the navigate function

  const handlePassword = () => {
    navigate("/ForgotPassword");
  };
  
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("+254");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    phoneNumber: "",
    password: "",
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value.startsWith("+254") && value.length <= 13) {
      setPhoneNumber(value);
    } else if (value === "" || value === "+254") {
      setPhoneNumber(value);
    }
  };

  const validate = () => {
    let valid = true;
    const newErrors = { phoneNumber: "", password: "" };

    if (!phoneNumber || phoneNumber.length !== 13) {
      newErrors.phoneNumber = "Phone number must start with +254 and be 13 digits long.";
      valid = false;
    }

    if (!password) {
      newErrors.password = "Password is required.";
      valid = false;
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      try {
        // Make the API call to your backend
        const response = await axios.post(`${apiUrl}/login`, {
          phone: phoneNumber,
          password: password,
        });
  
        if (response.status === 200) {
          const accessToken = response.data.access_token;
          localStorage.setItem("accessToken", accessToken);
          
          // Delay the toast notification by 5 seconds (5000 milliseconds)
          setTimeout(() => {
            toast.success("Login successful!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
  
            // Navigate to the wallet page upon successful login
            navigate("/Wallet");
          }, 3000); // 5000 milliseconds delay
        } else {
          setErrors({ ...errors, password: "Invalid phone number or password" });
        }
      } catch (error) {
        toast.error("Login failed. Invalid phone number or password.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error("Error during login", error);
      }
    }
  };
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 600px)": {
          padding: 7,
        },
      }}
    >
       <ToastContainer /> 
      <img
        src={Moconnectlogo}
        alt="logo"
        style={{
          height: 130,
          width: 130,
          borderRadius: "50%",
          "@media (max-width: 600px)": {
            height: 80,
            width: 80,
          },
        }}
      />
      <Typography
        sx={{
          fontSize: 24,
          fontWeight: "bold",
          marginBottom: 2,
          "@media (max-width: 600px)": {
            fontSize: 18,
          },
        }}
      >
        <span style={{ color: "#002855" }}>Moconnect</span>
        <span style={{ color: "#ffa500" }}> Pay</span>
      </Typography>

      <Box
        sx={{
          backgroundColor: "#022b46",
          padding: "2rem",
          borderRadius: "8px",
          border: "1px solid white",
          width: "150%",
          maxWidth: "400px",
          "@media (max-width: 600px)": {
            padding: "1rem",
            maxWidth: "130%",
          },
        }}
      >
        <Box display="flex" justifyContent="center" mb={2}>
          <Typography
          sx={{
            fontSize: 21.6,
            marginBottom: 1,
            color:"white",
            "@media (max-width: 600px)": {
              fontSize: 18,
            },
          }}
          >
            Welcome Back !!
          </Typography>
        </Box>

        <Box component="form" noValidate onSubmit={handleSubmit}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="phone-number"
              label="Phone Number"
              name="phone number"
              autoComplete="Phone Number"
              autoFocus
              variant="filled"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              sx={{ backgroundColor: "white", borderRadius: "4px", mb: 2 }}
              inputProps={{
                maxLength: 13,
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
          />
          <Box sx={{ position: "relative" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              variant="filled"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            />
            <IconButton
              edge="end"
              onClick={handleClickShowPassword}
              sx={{
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
                color: "gray",
                zIndex: 1,
                "@media (max-width: 600px)": {
                  right: 5,
                },
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>

          <Link
            href="#"
            variant="body2"
            sx={{ display: "block", textAlign: "right", color: "white", mb: 2 }}
            onClick={handlePassword}
          >
            Forgot your password?
          </Link>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: "orange" }}
            onClick={handleSubmit}
          >
            Log In
          </Button>
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" sx={{ color: "white" }}>
              {"Don't have an account? "}
              <Link href="/SignUp" variant="body2" sx={{ color: "white" }}>
                {"Sign up"}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
